import React, { useContext, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DrawerComp from "./Drawer";
import LanguageSelect from "./LanguageSelect";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/logo3@4x.svg";
import profile from "../../assets/LOGO inline.png";
import ProfileSelect from "./ProfileSelect";
import SupportSelect from "./SupportSelect";
import WalletSelect from "./WalletSelect";
import OrderSelect from "./OrderSelect";
import { DarkModeContext } from "../../ThemeContext";
import { FaSun, FaMoon } from 'react-icons/fa';

const Header = ({ access_token }) => {

  console.log(access_token, 'access_token')
  const location = useLocation();
  const pathname = location.pathname
  const [value, setValue] = useState(10);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const { darkMode, toggleDarkMode } = useContext(DarkModeContext)

  return (
    <React.Fragment>
      <AppBar
        style={{
          backgroundColor: darkMode ? "#282828" : "#fff",
          borderBottom: darkMode && "0.1px solid #64646466"
        }}
        sx={{
          boxShadow: darkMode ? "none" : "0px 0px 8.2px 0px rgba(0, 0, 0, 0.08)",
        }}
        color="inherit"
        className="navBarMian"
      >
        <Toolbar className="" sx={{ color: "#151515" }}>
          <Link to="/" className="navbarBrandLogo">
            <img src={logo} alt="" className="navLogo" />
          </Link>
          <Link to="/">
            <Typography
              variant="h6"
              component="h6"
              className="logoTxt"
            >
              ALT-X
            </Typography>
          </Link>
          {isMatch ? (
            <>
              <Typography
                sx={{ fontSize: "2rem", paddingLeft: "10%", color: "#0000" }}
                className="navText">
                ALT-X
              </Typography>
              <DrawerComp />
            </>
          ) : (
            <>
              <Stack
                style={{ marginLeft: 40 }}
                sx={{ marginLeft: "auto", textDecoration: "none" }}
                indicatorColor="secondary"
                textColor="inherit"
                direction="row"
                className="navUl"
              >
                <Button
                  component={Link}
                  onClick={() => setValue(0)}
                  to="/exchange"
                  style={{ fontSize: 14, color: pathname === "/exchange" ? (darkMode ? "#fff" : "#4231C8") : (darkMode ? "#999" : "#151515") }}
                  sx={{
                    // fontFamily: "Inter",
                    textTransform: "capitalize",
                    borderRadius: "0px",
                    fontSize: "14px",
                    fontWeight: 400,
                    // padding: "10px 0px 10px 0px",
                    ...(location.pathname == "/exchange" && {
                      color: "#fff",
                      // mt: "4px",
                    }),
                  }}
                >
                  Exchange
                </Button>

                {/* <Button
                  component={Link}
                  onClick={() => setValue(2)}
                  to="/swap"
                  sx={{
                    fontFamily: "Inter",
                    textTransform: "capitalize",
                    borderRadius: "0px",
                    fontSize: "14px",
                    color: "#151515",
                    fontWeight: 400,
                    padding: "10px 0px 10px 0px",
                    ...(location.pathname == "/swap" && {
                      borderBottom: "2px solid #4231C8",
                      mt: "4px",
                    }),
                  }}
                >
                  Swap
                </Button> */}

                <WalletSelect />
                <OrderSelect />
                <SupportSelect />
              </Stack>
              <div style={{ display: "flex", flexGrow: 1, justifyContent: "flex-end" }}>
                <button
                  style={{
                    fontSize: 20, width: 84, height: 32, fontFamily: "Roboto",
                    backgroundColor: darkMode ? "#282828" : "#fff",
                    color: darkMode ? "#fff" : "#4231C8",
                    border: 'none',
                  }}
                  onClick={toggleDarkMode}
                >
                  {darkMode ? <FaMoon /> : <FaSun />}
                </button>
              </div>
              {!access_token?.access_token || access_token?.access_token === null ? (
                <Button
                  component={Link}
                  to="/login"
                  // className="headerbtnText"
                  style={{ fontSize: 13, width: 84, fontFamily: "Roboto", fontWeight: 500 }}
                  sx={{
                    marginLeft: "auto",
                    marginRight: "10px",
                    borderRadius: 1,
                    textTransform: "capitalize",
                    bgcolor: "#4231C8",
                    "&:hover": { bgcolor: "#4231C8" },
                  }}
                  variant="contained"
                >
                  Sign in
                </Button>
              ) : (
                <ProfileSelect />
              )}

              <LanguageSelect className="ml-10" />
            </>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;
