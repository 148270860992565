import React, { useContext } from 'react'
import { DarkModeContext } from '../../../../../ThemeContext'

const ResetPassword = () => {
  const {darkMode } = useContext(DarkModeContext)
  return (
    <>
      <h2 style={{color : darkMode && "#e1e1e1"}} className="guideHeading">How to Reset Password</h2>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        1. Visit the Login Page:
      </h3>
      <p style={{color : darkMode && "#e1e1e1"}} className="guidePara">
        Start by visiting the login page of ALT-X Exchange.
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        2. Click on "Forgot Password":
      </h3>
      <p style={{color : darkMode && "#e1e1e1"}} className="guidePara">
        Below the login form, you'll find an option that says "Forgot
        Password." Click on this link to initiate the password reset process.
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        3. Enter Your Email Address:
      </h3>
      <p style={{color : darkMode && "#e1e1e1"}} className="guidePara">
        You'll be prompted to enter the email address associated with your
        ALT-X Exchange account. Make sure to enter the correct email
        address to receive instructions for resetting your password.
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        4. Check Your Email and Follow Instructions:
      </h3>
      <p style={{color : darkMode && "#e1e1e1"}} className="guidePara">
        After submitting your email address, check your inbox for an email
        from ALT-X Exchange. Follow the instructions provided in the email
        to proceed with resetting your password.
      </p>
    </>
  )
}

export default ResetPassword