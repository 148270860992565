import React, { useContext, useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import QrCode2SharpIcon from "@mui/icons-material/QrCode2Sharp";
import CheckIcon from "@mui/icons-material/Check";
import Divider from "@mui/material/Divider";
import bcoin from "../../../assets/bcoin.png";
import bicoin from "../../../assets/bicoin.png";
import tcoin from "../../../assets/tcoin.png";
import pcoin from "../../../assets/pcoin.png";
import dcoin from "../../../assets/dcoin.png";
import ecoin from "../../../assets/ecoin.png";
import copy from "../../../assets/copyTxt.svg";
import scan from "../../../assets/scan.svg";
import notFound from "../../../assets/notFound.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Box,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputBase,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import { DarkModeContext } from "../../../ThemeContext"
import rcoin from "../../../assets/rcoin.png"
import scoin from "../../../assets/scoin.png"
import bncoin from "../../../assets/bncoin.png"
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  color: "#4231C8",
  height: 16,
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  // "input:hover ~ &": {
  //   backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  // },
  // "input:disabled ~ &": {
  //   boxShadow: "none",
  //   background:
  //     theme.palette.mode === "dark"
  //       ? "rgba(57,75,89,.5)"
  //       : "rgba(206,217,224,.5)",
  // },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#4231C8",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
});
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    width: { xs: "90%", md: "80%" },
    marginRight: "auto",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}
const Deposit = ({setCrypto}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [selectCoin, setselectCoin] = React.useState(bcoin);
  const [fname, setfname] = React.useState("BTC");
  const [lname, setlname] = React.useState("Bitcoin");
  const [network, setNetwork] = React.useState("BTC");
  const { darkMode } = useContext(DarkModeContext)
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const [showOptions, setShowOptions] = useState(false)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (a, b, c) => {
    if (a == "bcoin") {
      setselectCoin(bcoin);
      setfname(b);
      setlname(c);
      setNetwork("BTC")
    } else if (a == "bncoin") {
      setselectCoin(bncoin);
      setfname(b);
      setlname(c);
      setNetwork("BEP20")
    } else if (a == "pcoin") {
      setselectCoin(pcoin);
      setfname(b);
      setlname(c);
      setNetwork("ERC 20")
    } else if (a == "rcoin") {
      setselectCoin(rcoin);
      setfname(b);
      setlname(c);
      setNetwork("XRP Ledger")
    } else if (a == "tcoin") {
      setselectCoin(tcoin);
      setfname(b);
      setlname(c);
      setNetwork("ERC20")
    } else if (a == "ecoin") {
      setselectCoin(ecoin);
      setfname(b);
      setlname(c);
      setNetwork("ERC 20")
    } else if (a == "scoin") {
      setselectCoin(scoin);
      setfname(b);
      setlname(c);
      setNetwork("Solana Network")
    }
    setAnchorEl(null);
  };
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      color: darkMode ? "#e1e1e1" : "",
      backgroundColor: darkMode ? "#323232" : "#fff",
      border: "1px solid #999",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      // "&:focus": {
      //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      //   borderColor: theme.palette.primary.main,
      // },
    },
  }));
useEffect(() => {
  setCrypto(fname)
}, [fname])
  return (
    <Box sx={{ marginLeft: "auto", mt: 4, mb: 5 }}>
      <Stack
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
        }}
      >
        <Box width="100%">
          {" "}
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={() => setShowOptions(!showOptions)}
            startIcon={<img src={selectCoin} alt="s" />}
            endIcon={<KeyboardArrowDownIcon />}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              textTransform: "capitalize",
              height: 47,
              width: "100%",
              bgcolor: darkMode ? "#323232" : "#fff",
              border: "1px solid #999",
              color: "#4231C8",
              "&:hover": {
                backgroundColor: darkMode ? "#323232" : "#fff",
              },
            }}
          >
            <Box
              marginRight="auto"
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Typography fontWeight={600} color={darkMode ? "#e1e1e1" : "#151515"}>{fname}</Typography>
              <Typography color="#999999" ml={1} sx={{ fontSize: "14px" }}>
                {lname}
              </Typography>
            </Box>
          </Button>
          {showOptions && (
            <div style={{ position: "relative", backgroundColor: "red", zIndex: 3 }}>
              <div
                onMouseEnter={() => setShowOptions(true)}
                onMouseLeave={() => setShowOptions(false)}
                style={{
                  borderRadius: 5,
                  display: "flex",
                  flexDirection: "column",
                  width: 200,
                  paddingTop: 4,
                  paddingBottom: 4,
                  position: "absolute",
                  right: 0,
                  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)'
                }}>

                <button
                  className="cryptoOptionsItem"
                  onClick={() => { handleClose("bcoin", "BTC", "Bitcoin"); setShowOptions(false) }}
                >
                  <div style={{ display: "flex", gap: 4 }} className="menuItem">
                    <img src={bcoin} alt="icon" className="profileDropIcons" style={{ width: 20, height: 20 }} />
                    <div>BTC</div>
                    <div style={{ color: "gray", fontSize : 12, marginLeft : 4 }}>(Bitcoin)</div>
                  </div>
                </button>
                <button
                  className="cryptoOptionsItem"
                  onClick={() => { handleClose("ecoin", "ETH", "Ethereum"); setShowOptions(false) }}
                >
                  <div style={{ display: "flex", gap: 4 }} className="menuItem">
                    <img src={ecoin} alt="icon" className="profileDropIcons" style={{ width: 20, height: 20 }} />
                    <div>ETH</div>
                    <div style={{ color: "gray", fontSize : 12, marginLeft : 4 }}>(Ethereum)</div>
                  </div>
                </button>
                <button
                  className="cryptoOptionsItem"
                  onClick={() => { handleClose("tcoin", "USDT", "Tether"); setShowOptions(false) }}
                >
                  <div style={{ display: "flex", gap: 4 }} className="menuItem">
                    <img src={tcoin} alt="icon" className="profileDropIcons" style={{ width: 20, height: 20 }} />
                    <div>USDT</div>
                    <div style={{ color: "gray", fontSize : 12, marginLeft : 4 }}>(Tether)</div>
                  </div>
                </button>

                <button
                  className="cryptoOptionsItem"
                  onClick={() => { handleClose("bncoin", "BNB", "Binance"); setShowOptions(false) }}
                >
                  <div style={{ display: "flex", gap: 4 }} className="menuItem">
                    <img src={bncoin} alt="icon" className="profileDropIcons" style={{ width: 20, height: 20 }} />
                    <div>BNB</div>
                    <div style={{ color: "gray", fontSize : 12, marginLeft : 4 }}>(Binance)</div>
                  </div>
                </button>

                <button
                  className="cryptoOptionsItem"
                  onClick={() => { handleClose("scoin", "SOL", "Solana"); setShowOptions(false) }}
                >
                  <div style={{ display: "flex", gap: 4 }} className="menuItem">
                    <img src={scoin} alt="icon" className="profileDropIcons" style={{ width: 20, height: 20 }} />
                    <div>SOL</div>
                    <div style={{ color: "gray", fontSize : 12, marginLeft : 4 }}>(Solana)</div>
                  </div>
                </button>

                <button
                  className="cryptoOptionsItem"
                  onClick={() => { handleClose("rcoin", "XRP", "Ripple"); setShowOptions(false) }}
                >
                  <div style={{ display: "flex", gap: 4 }} className="menuItem">
                    <img src={rcoin} alt="icon" className="profileDropIcons" style={{ width: 20, height: 20 }} />
                    <div>XRP</div>
                    <div style={{ color: "gray", fontSize : 12, marginLeft : 4 }}>(Ripple)</div>
                  </div>
                </button>
              </div>
            </div>
          )}
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >

            <MenuItem
              onClick={() => handleClose("bcoin", "BTC", "Bitcoin")}
              disableRipple
            >
              <img src={bcoin} alt="s" className="profileDropIcons" />
              <Typography fontWeight={800}>BTC</Typography>
              <Typography color="#999999" ml={1}>
                Bitcoin
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => handleClose("ecoin", "ETH", "Ethereum")}
              disableRipple
            >
              <img src={ecoin} alt="s" className="profileDropIcons" />
              <Typography fontWeight={800}>ETH</Typography>
              <Typography color="#999999" ml={1}>
                Ethereum
              </Typography>
            </MenuItem>

            <MenuItem
              onClick={() => handleClose("tcoin", "USDT", "Tether")}
              disableRipple
            >
              <img src={tcoin} alt="s" className="profileDropIcons" />
              <Typography fontWeight={800}>USDT </Typography>
              <Typography color="#999999" ml={1}>
                Tether
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => handleClose("bncoin", "BNB", "Binance")}
              disableRipple
            >
              <img src={bncoin} alt="s" className="profileDropIcons" />
              <Typography fontWeight={800}>BNB</Typography>
              <Typography color="#999999" ml={1}>
                Binance
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => handleClose("scoin", "SOL", "Solana")}
              disableRipple
            >
              <img src={scoin} alt="s" className="profileDropIcons" />
              <Typography fontWeight={800}>SOL </Typography>
              <Typography color="#999999" ml={1}>
                Solana
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => handleClose("rcoin", "XRP", "Ripple")}
              disableRipple
            >
              <img src={rcoin} alt="s" className="profileDropIcons" />
              <Typography fontWeight={800}>XRP </Typography>
              <Typography color="#999999" ml={1}>
                Ripple
              </Typography>
            </MenuItem>

          </StyledMenu>
          <Stack
            mt={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: darkMode && "#e1e1e1" }} variant="body2" className="totalTxt">Total balance</Typography>
            <Typography sx={{ color: darkMode && "#e1e1e1" }} variant="body2" fontWeight={600} color="#4231C8">
              0.00000
            </Typography>
          </Stack>
          <Stack
            mt={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: darkMode && "#e1e1e1" }} variant="body2" className="totalTxt">Available balance</Typography >
            <Typography sx={{ color: darkMode && "#e1e1e1" }} variant="body2" fontWeight={600} color="#4231C8">
              0.00000
            </Typography>
          </Stack>
          <FormControl fullWidth variant="standard" sx={{ mt: 4, mb: 4 }}>
            <span className="" style={{
              color: darkMode ? "#e1e1e1" : "#232323",
              fontSize: "16px",
              fontWeight: 400,
              marginBottom: "8px", // Adjust margin as needed
            }}>
              Network
            </span>
            <Box
              id="demo-customized-button"
              aria-controls={open2 ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open2 ? "true" : undefined}
              variant="contained"
              disableElevation
              startIcon={<img src={selectCoin} alt="s" />}
              endIcon={<KeyboardArrowDownIcon />}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                textTransform: "capitalize",
                height: 47,
                width: "100%",
                bgcolor: darkMode ? "#282828" : "#fff",
                border: "1px solid #999",
                color: "#4231C8",
                "&:hover": {
                  backgroundColor: "transparent", // Set background to transparent on hover
                },
              }}
            >
              <Box
                marginRight="auto"
                display="flex"
                flexDirection="row"
                alignItems="center"
                sx={{ marginLeft: 2 }}
              >
                <Typography fontWeight={600} color={darkMode ? "#e1e1e1" : "#151515"}>{network}</Typography>
                {/* <Typography color="#999999" ml={1} sx={{ fontSize: "14px" }}>
                  {lname}
                </Typography> */}
              </Box>
            </Box>
          </FormControl>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box width="85%">
              <FormControl fullWidth variant="standard" sx={{}}>
                <InputLabel
                  shrink
                  htmlFor="email"
                  sx={{
                    color: darkMode ? "#fff" : "#232323",
                    "&.Mui-focused": {
                      color: darkMode ? "#fff" : "#232323",
                    },
                  }}
                  className="depositLabel"
                >
                  Deposit address
                </InputLabel>
                <BootstrapInput defaultValue="" id="email" />
              </FormControl>
            </Box>

            <Stack
              width="15%"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                position: "relative",
                top: 10,
              }}
            >
              <img src={copy} alt="sa" />
              <img src={scan} alt="sa" />
            </Stack>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mt: 1,
              gap: 2,
            }}
          >
            <CheckIcon sx={{ fontSize: 16, fontWeight: 800 }} className="checkIcon" />{" "}
            <Typography sx={{ color: darkMode && "#e1e1e1" }} variant="body2" className="fontFamilySet">
              This address is only for Arbitrum One based {lname} ({network}).
            </Typography>
          </Stack>
        </Box>
        <Box width="100%">
          <Stack
            className="importentTxtDiv"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              background: "#9999990D",
              padding: 4

            }}
          >
            <Typography
              variant="h5"
              color="red"
              textAlign="center"
              fontWeight={600}
              sx={{ fontSize: "24px" }}
            >
              Important
            </Typography>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                mt: 4,
                gap: 2,
              }}
            >
              <CheckIcon
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "red",
                  mt: "4px",
                }}
              />{" "}
              <Typography sx={{ color: darkMode && "#e1e1e1" }} variant="body1" className="impP">
                Deposits will automatically be processed after 60 network
                confirmations. Deposit confirmation can be delayed depending on
                network conditions.
              </Typography>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                mt: 4,
                gap: 2,
              }}
            >
              <CheckIcon
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "red",
                  mt: "4px",
                }}
              />{" "}
              <Typography sx={{ color: darkMode && "#e1e1e1" }} variant="body1" className="impP">
                The <b className="addressB">deposit address</b> is only for {lname} ({fname}) deposits
              </Typography>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                mt: 4,
                gap: 2,
              }}
            >
              <CheckIcon
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "red",
                  mt: "4px",
                }}
              />{" "}
              <Typography sx={{ color: darkMode && "#e1e1e1" }} variant="body1" className="impP">
                Depositing the wrong token to the <b className="addressB">deposit address</b> may
                result in loss of your deposit.
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Stack >
      <Stack mt={3}>
        <Typography variant="h5" color={darkMode ? "#e1e1e1" : "#4231C8"} className="recentDeposit">
          Recent deposits
        </Typography>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 1,
            paddingBottom: 3,
            // borderBottom: "1px solid #4231C8",
            flexWrap: "wrap"
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <BpCheckbox />
            <Typography sx={{ color: darkMode && "#e1e1e1" }} variant="body2" className="fontFamilyLabel">
              Filter transactions in progress
            </Typography>
            <Button
              sx={{ border: darkMode ? "1px solid #e1e1e1" : "1px solid #232323" }}
              style={{ color: darkMode ? "#e1e1e1" : "#232323" }}
              href="#outlined-buttons"
              className="reloadBtn"
            >
              Reload
            </Button>
          </Stack>
          <Typography
            variant="body2"
            sx={{ color: "#999999", textDecoration: "underline" }}
          >
            View All
          </Typography>
        </Stack>
        <Container className="depositTableCont">
          <Stack className="depositTable" sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", mt: 4, gap: 2 }}>
            <Typography variant="body1" sx={{ color: '#999999' }}>
              Date (UTC+5)
            </Typography>
            <Typography variant="body1" sx={{ color: '#999999' }}>
              Type
            </Typography>
            <Typography variant="body1" sx={{ color: '#999999' }}>
              Status
            </Typography>
            <Typography variant="body1" sx={{ color: '#999999' }}>
              Coin
            </Typography>
            <Typography variant="body1" sx={{ color: '#999999' }}>
              Amount
            </Typography>
            <Typography variant="body1" sx={{ color: '#999999' }}>
              Action
            </Typography>
          </Stack>
        </Container>

        <Stack sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mt: 8 }}>
          <img src={notFound} alt="sk" />
        </Stack>
      </Stack>
    </Box >
  );
};

export default Deposit;
