import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import avatar from "../../assets/avatarWhite.png";
import { Button, Stack, Typography } from "@mui/material";
import red from "../../assets/red.png";
import blue from "../../assets/blue.png";
const ErrorCodeTable = () => {
  function createData(name, price, volume) {
    return { name, price, volume };
  }

  const rows = [
    createData(
      "LINK/USDT",
      "Address already exists in withdrawal address list.",
      400
    ),
    createData(
      "LINK/USDT",
      "Requested currency is not exist on Alt-X system.",
      400
    ),
    createData(
      "LINK/USDT",
      "Address already exists in withdrawal address list.",
      501
    ),
    createData(
      "LINK/USDT",
      "Requested currency is not exist on Alt-X system.",
      400
    ),
    createData("LINK/USDT", "lo", 501),
    createData(
      "LINK/USDT",
      "Address already exists in withdrawal address list.",
      400
    ),
    createData(
      "LINK/USDT",
      "Requested currency is not exist on Alt-X system.",
      400
    ),
    createData(
      "LINK/USDT",
      "Address already exists in withdrawal address list.",
      501
    ),
    createData(
      "LINK/USDT",
      "Requested currency is not exist on Alt-X system.",
      400
    ),
    createData(
      "LINK/USDT",
      "Address already exists in withdrawal address list.",
      400
    ),
    createData(
      "LINK/USDT",
      "Requested currency is not exist on Alt-X system.",
      501
    ),
    createData(
      "LINK/USDT",
      "Address already exists in withdrawal address list.",
      400
    ),
    createData(
      "LINK/USDT",
      "Requested currency is not exist on Alt-X system.",
      400
    ),
    createData(
      "LINK/USDT",
      "Address already exists in withdrawal address list.",
      501
    ),
    createData(
      "LINK/USDT",
      "Requested currency is not exist on Alt-X system.",
      501
    ),
  ];
  return (
    <TableContainer component={Paper} sx={{ marginTop: 6, boxShadow: "none" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ bgcolor: "#4231c8", color: "#ffffff" }}>
          <TableRow sx={{ borderBottom: "unset" }}>
            <TableCell className="tableTheadSet" sx={{ color: "#ffffff", fontWeight: 700 }}>
              Code
            </TableCell>
            <TableCell className="tableTheadSet" sx={{ color: "#ffffff", fontWeight: 700 }} align="left">
              Data
            </TableCell>
            <TableCell className="tableTheadSet" sx={{ color: "#ffffff", fontWeight: 700 }} align="left">
              HTTP Status Code
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                "&:last-child td, &:last-child th": {
                  border: "1px solid #4231c8",
                },
                borderBottom: "border: 5px solid #4231c8",
                "&:last-child": {
                  borderBottom: "0.75px solid #fff",
                },
                "&:child": {
                  border: "0.75px solid #4231c8",
                },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ border: "0.75px solid #4231c8" }}
              >
              <Typography variant="body2" className="tableText">
              DUPLICATE_ACCOUNT
              </Typography>
               
              </TableCell>
              <TableCell align="left" sx={{ border: "0.75px solid #4231c8" }}>
                {row.price == "lo" ? (
                  <>
                  <Typography variant="body2" className="tableText">Address already exists in withdrawal address list.</Typography>
                  <Typography variant="body2" className="tableText" mt={2}>Requested currency is not exist on Alt-X system.</Typography>
                    
                  </>
                ) : (
                  <Typography variant="body2" className="tableText">{row.price}</Typography>
                )}
              </TableCell>

              <TableCell sx={{ border: "0.75px solid #4231c8" }} align="center" className="tableText">
                {row.volume}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ErrorCodeTable;
