// CustomIcon.jsx
import React, { useContext } from 'react';
import { DarkModeContext } from "../../../ThemeContext"

const CustomIcon = ({ isLeft }) => {
  const rotationAngle = isLeft ? 0 : 180;
  const translateX = isLeft ? 0 : 5; // Adjust the translation as needed
  const { darkMode } = useContext(DarkModeContext)
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.25 44.5"
    >
      <g>
        <path
          style={{
            fill: darkMode ? '#282828' : "#fff",
            stroke: darkMode ? '#414141' : "#E9ECF1",
            strokeWidth: '1.1px',
            fillRule: 'evenodd',
          }}
          className="exchangeIcon"
          d="M0,.34a22,22,0,0,1,22,22,22,22,0,0,1-22,22"
          transform="translate(0 -0.09)"
        ></path>
        <polyline
          style={{
            fill: 'none',
            stroke: '#999',
            strokeMiterLimit: 10,
            strokeWidth: '1px',
            cursor: 'pointer',
          }}
          points="10.35 28.27 4.32 22.25 10.35 16.23"
          transform={`rotate(${rotationAngle} 11.125 22.25) translate(${translateX}, 0)`}
        ></polyline>
      </g>
    </svg>
  );
};

export default CustomIcon;
