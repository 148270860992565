import React, { useContext } from 'react'
import { DarkModeContext } from '../../../../../ThemeContext'

const UserGuide = () => {
    const { darkMode } = useContext(DarkModeContext)
    return (
        <div className='faqDetailsData'>
            <h2 style={{ color: darkMode && "#e1e1e1" }} className="guideHeading">ALT-X Exchange Ultimate User Guide</h2>
             <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
                Step 1: Visit our Website
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                Head over to our website at
                <a
                    style={{ textTransform: "lowercase", textDecoration: "underline", color: darkMode && "#fff" }}
                    href="//www.altexchanger.com"> www.altexchanger.com </a>
                to begin the
                registration process
            </p>
            <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
                Step 2: Click on "Sign Up"
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                Once on our homepage, locate the "Sign Up" button and click on it
                to initiate the registration process.
            </p>
             <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
                Step 3: Fill in Your Information
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                You'll be directed to the registration page where you'll need to
                provide some basic information to create your account. This
                includes your email address, a secure password, and any other
                required details.
            </p>
             <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
                Step 4: Verify Your Email
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                After filling in your information, you'll receive a verification email at
                the address you provided. Click on the verification link in the email
                to confirm your email address and activate your account.
            </p>
             <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
                Step 5: How to Complete KYC Verification
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                To unlock increased daily withdrawal limits and ensure the security
                of your account, you'll need to complete the Know Your Customer
                (KYC) verification process. This involves providing additional details
                such as your full name, date of birth, address, and a government-
                issued ID. Follow the instructions provided on the platform to submit
                the required documents and complete the verification process.
            </p>
             <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
                Step 6: Set Up Two-Factor Authentication (2FA)
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                For added security, we highly recommend setting up Two-Factor
                Authentication (2FA) for your account. This provides an extra layer
                of protection by requiring a verification code in addition to your
                password when logging in.
            </p>
             <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
                Step 7: Explore the Platform
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                Once your account is set up, verified, and secured with 2FA, you're
                ready to start exploring the Alt-X Exchange platform. Navigate
                through our user-friendly interface to discover a wide range of
                trading pairs, advanced trading tools, and real-time market data.
            </p>
             <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
                Step 8: Deposit Funds
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                Before you can start trading, you'll need to deposit funds into your
                ALT-X Exchange account. Navigate to the "Deposit" section and
                follow the instructions to deposit your desired cryptocurrency.
            </p>
             <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
                Step 9: Start Trading
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                With funds deposited into your account, you're now ready to start
                trading! Explore our diverse selection of cryptocurrencies and
                trading pairs, place your orders, and monitor your portfolio in real-
                time.
            </p>
             <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
                Step 10: How to Withdraw Your Funds
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                To withdraw your funds from your ALT-X Exchange account,
                navigate to the "Withdraw" section. Enter the amount you wish to
                withdraw and the destination wallet address where you want to
                send your funds.
                Please ensure that the wallet address is correct to prevent the loss
                of your tokens. We recommend doing a test transaction with a small
                amount first to verify the accuracy of the address. Follow the
                instructions provided to complete the withdrawal process and
                transfer your funds securely to your preferred wallet.
            </p>
        </div>
    )
}

export default UserGuide