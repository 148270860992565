import React from "react";
import Layout from "../../layouts/Layout";
import { Container, Stack, Typography } from "@mui/material";

const TermConditions = () => {
  return (
    <Layout>
    <Container className="manageContainer">
      <Stack className="termMainSection" direction="column" mt={10} mb={10} gap={2}>
        <Typography variant="h3" color="#4231C8" className="supportTitle">
        Terms & Conditions
        </Typography>

        <Typography className="acceptP" variant="h6" color="#4231C8" fontWeight={800}>
          1. ACCEPTANCE
        </Typography>
        <Typography variant="body2" color="#151515" fontWeight={400}>
          The following terms and conditions of service (herein after "Terms
          of Service") shall mean the rights, obligations, liabilities, terms
          and conditions, procedures, and other basic principles for the
          members (herein as “member”, in general individual users. For
          corporate users, you will find the provisions of Article below) to
          use cryptographic currency (herein as “Cryptocurrency”) transaction
          service provided by the digital asset transaction site such as
          www.probit.com or the mobile application provided through the mobile
          communication device and smart phone from the ProBit(herein as
          “company”) It is intended to define the rights and obligations of
          the Company and its members, responsibilities, and procedures for
          the Member's use of the Service in relation to the basic matters.
          <Typography variant="body2" color="#151515" fontWeight={400} mt={2}>
            By accessing, viewing or downloading information from the Website
            and using the Service provided by ProBit you acknowledge that you
            have read, understand, and unconditionally agree to be bound by
            these Terms. ProBit may at any time, without notice, amend or
            modify the Terms. You agree to continue to be bound by any amended
            terms and conditions and that ProBit has no obligation to notify
            you of such amendments. You acknowledge that it is your
            responsibility to check these Terms periodically for changes and
            that your continued use of the Website and Services offered by
            ProBit following the posting of any changes to the Terms indicates
            your acceptance of any such changes.
          </Typography>
        </Typography>
        <Typography variant="h6" color="#4231C8" fontWeight={800}>
          2. DEFINITION OF TERMS
        </Typography>
        <Typography variant="body2" color="#151515" fontWeight={400}>
        <Typography mb={2}>2.1 </Typography>
          TOS means the Terms and Conditions of Use herein. 
          <Typography mb={2} mt={2}>2.2 </Typography> Agreement
          means from the User agree the amendment of TOS of ProBit to sign up
          the member’s status to entire activities while Member use the
          service that ProBit provide.
          <Typography mb={2} mt={2}>2.3 </Typography> ProBit means ProBit Global Services
          Limited, a company founded in the Republic of Seychelles; or
          Webthree Technology UAB, a company founded in the Republic of
          Lithuania. <Typography mb={2} mt={2}>2.4 </Typography> User means who access this website and/or ProBit
          mobile application and get the information or use all kind if
          services that ProBit provide. Also “You” in this agreement or TOU is
          generally meaning the User. <Typography mb={2} mt={2}>2.5 </Typography> Member means any current registered
          user of ProBit. <Typography mb={2} mt={2}>2.6 </Typography> Data means the prices and other market data that
          is owned by ProBit and obtained by ProBit from exchanges and other
          sources delivered to Member via the Web Services and as described in
          this Agreement and on the Site. <Typography mb={2} mt={2}>2.7 </Typography> Service means all services made
          available (as may be changed or updated from time to time by ProBit)
          via the Website. <Typography mb={2} mt={2}>2.8 </Typography> Website means any of the images, written
          material, databases, software or other material available on any
          website owned or operated by ProBit. <Typography mb={2} mt={2}>2.9 </Typography> Intellectual Property
          Rights means any registered or unregistered design rights, patents,
          copyright, database rights, data protection rights, trademarks,
          service marks, moral rights, know-how and any other intellectual or
          industrial property rights, anywhere in the world.
          
        </Typography>
      </Stack>
    </Container>
  </Layout>
  )
}

export default TermConditions