import React, { useContext, useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Paper from "@mui/material/Paper";
import avatar from "../../assets/avatarWhite.png";
import {
  Box,
  Button,
  Container,
  InputBase,
  Stack,
  Tab,
  Tabs,
  Typography,
  alpha,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import red from "../../assets/red.png";
import blue from "../../assets/blue.png";
import { Search } from "@mui/icons-material";
import styled from "@emotion/styled";
import { Link, useLocation } from "react-router-dom";
import Deposit from "../layouts/table/Deposit";
import Withrdaw from "../layouts/table/Withrdaw";

// icons 
import BTC from "../../assets/coins/BTC.png"
import BPLC from "../../assets/coins/BPLC.png"
import SHIB from "../../assets/coins/SHIB.png"
import RWN from "../../assets/coins/RWN.png"
import ETH from "../../assets/coins/ETH.png"
import LPNT from "../../assets/coins/LPNT.png"
import DOGE from "../../assets/coins/DOGE.png"
import BNB from "../../assets/coins/BNB.png"
import BIZA from "../../assets/coins/BIZA.png"
import MINU from "../../assets/coins/MINU.png"
import { DarkModeContext } from "../../ThemeContext";
import { getUserProfileData } from "../../services/LocalStorageService";


const Wallet = () => {
  const location = useLocation()
  const [value, setValue] = React.useState(0);
  const userProfileData = getUserProfileData();
  const [crypto, setCrypto] = useState("BTC")

  useEffect(() => {
    if (location.pathname == "/wallet/withdrawal")
      setValue(2)
    else if (location.pathname == "/wallet/deposit")
      setValue(1)
    else if (location.pathname == "/wallet")
      setValue(0)

  }, [location])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "1px solid #999999",
    color: "#999999",
    // backgroundColor: alpha(theme.palette.common.white, 0.15),
    // "&:hover": {
    //   backgroundColor: alpha(theme.palette.common.white, 0.25),
    // },
    // marginRight: theme.spacing(2),
    marginRight: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: 0,
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 0, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(0.5em + ${theme.spacing(0.5)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));
  function createData(
    coin,
    name,
    available,
    balance,
    buy,
    deposite,
    withdraw,
    trade,
    icon
  ) {
    return { coin, name, available, balance, buy, deposite, withdraw, trade, icon };
  }

  const rows = [
    createData("BTC", "BITCOIN", "000000", "000000", "Buy", "Deposit", "Withdraw", "Trade", BTC),
    createData("BPLC", "BlackPearl", "000000", "000000", "Buy", "Deposit", "Withdraw", "Trade", BPLC),
    createData("RWN", "Rowan Coin", "000000", "000000", "Buy", "Deposit", "Withdraw", "Trade", RWN),
    createData("ETH", "Ethereum", "000000", "000000", "Buy", "Deposit", "Withdraw", "Trade", ETH),
    createData("DOGE", "Dogecoin", "000000", "000000", "Buy", "Deposit", "Withdraw", "Trade", DOGE),
    createData("BIZA", "BizAuto", "000000", "000000", "Buy", "Deposit", "Withdraw", "Trade", BIZA),
  ];

  const { darkMode } = useContext(DarkModeContext)
  return (
    <Layout>
      <Container className="manageContainer" sx={{ mt: 10 }}>
        <Stack>
          <Typography variant="h4" className="supportTitle" sx={{ fontWeight: 700, color: darkMode ? "#fff" : "#4231C8" }}>
            Wallet
          </Typography>
          <Typography className="exP" variant="body1" sx={{ color: "#999999", mb: 2, fontSize: "16px" }}>
          {userProfileData.email}
          </Typography>
        </Stack>
        <Stack container>
          <Stack
            sx={{
            }}
            className="tabsDiv"
          >
            <Tabs
              value={value}
              onChange={handleChange}
              centered
              style={{ display: "flex", justifyContent: "space-between", marginBottom : 20 }}
              sx={{
                "& .MuiTabs-indicator": {
                  // backgroundColor:darkMode ? "#000" : "#fff",
                  display: "none",
                },
                "&.Mui-selected": {
                  color: "#4231C8",
                  fontWeight: 700,
                },
              }}

            >
              <Tab
                label="Balance"
                style={{ flexGrow: 1, border: darkMode ? "1px solid #999" : "1px solid #4231c8" }}
                className="borderBottomNone"
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#4231C8",
                    color: "white",
                    fontWeight: 700,
                  },
                  backgroundColor: "#f5f5f5",
                  color: "#646464",
                  fontSize: "18px",
                  fontFamily: "Inter",
                  textTransform: "capitalize",
                  fontWeight: 700
                }}
              />
              <Tab
                style={{ flexGrow: 1, border: darkMode ? "1px solid #999" : "1px solid #4231c8" }}
                label="Deposit"
                className="borderBottomNone"
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#4231C8",
                    color: "white",
                    fontWeight: 700,
                  },
                  color: "#646464",
                  backgroundColor: "#f5f5f5",
                  fontSize: "18px",
                  fontFamily: "Inter",
                  textTransform: "capitalize",
                  fontWeight: 700
                }}
              />
              <Tab
                style={{ flexGrow: 1, border: darkMode ? "1px solid #999" : "1px solid #4231c8" }}
                label="Withdraw"
                className="borderBottomNone"
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#4231C8",
                    color: "white",
                    fontWeight: 700,

                  },
                  backgroundColor: "#f5f5f5",
                  color: "#646464",
                  fontSize: "18px",
                  fontFamily: "Inter",
                  textTransform: "capitalize",
                  fontWeight: 700
                }}
              />
            </Tabs>
            <Stack
              sx={{
                paddingBottom: 0,
                display: "flex",
                direction: "column",
                alignItems: { xs: "start", md: "end" },
                margin: 0,
                padding: 0,
              }}
            >
              <Stack direction="row" gap={1}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#999999",
                    // fontWeight: 700,
                    fontWeight: 400,
                    paddingBottom: 1,
                  }}
                  className="userPrice"
                >
                  In use:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#4231C8",
                    // fontWeight: 700,
                    fontWeight: 700,
                    paddingBottom: 1,
                  }}
                  className="userPrice"
                >
                  0.00 {crypto}
                </Typography>
              </Stack>
              <Stack direction="row" gap={1}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#999999",
                    // fontWeight: 700,
                    fontWeight: 400,
                    paddingBottom: 1,
                    margin: 0,
                    padding: 0,
                  }}
                  className="userPrice"
                >
                  Estimated value:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#4231C8",
                    // fontWeight: 700,
                    fontWeight: 700,
                    paddingBottom: 1,
                  }}
                  className="userPrice"
                >
                  0.00 {crypto}
                </Typography>
              </Stack>
              <Stack direction="row" gap={1}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#999999",
                    // fontWeight: 700,
                    fontWeight: 400,
                    paddingBottom: 1,
                  }}
                  className="userPrice"
                >
                  24h withdrawal limit:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#4231C8",
                    // fontWeight: 700,
                    fontWeight: 700,
                    paddingBottom: 2,
                  }}
                  className="userPrice"
                >
                  5,000.00 {crypto}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

         {/* <Box sx={{ borderBottom: "1px solid #4231C8", width: "100%" }}></Box>   */}
        </Stack>
        {value == 0 ? (
          <Box>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                position: "relative",
                top: { md: 20, xs: 10, sm: 15 },
              }}
              className="walletSerch"
            >
              <Search >
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Stack>

            <TableContainer className="tableRowBodyWalletCont" component={Paper} sx={{ boxShadow: "none", marginTop: 6 }}>
              <Table sx={{ minWidth: 650, backgroundColor: darkMode && "#323232" }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ borderBottom: "unset" }} className="tableRowBodyWallet">
                    <TableCell sx={{ color: "#999999" }}>Coin</TableCell>
                    <TableCell sx={{ color: "#999999" }}>Name</TableCell>
                    <TableCell sx={{ color: "#999999" }} align="left">
                      Available
                    </TableCell>
                    <TableCell sx={{ color: "#999999" }} align="left">
                      Total Balance
                    </TableCell>
                    <TableCell sx={{ color: "#999999" }} align="left">
                      USDT value
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        borderBottom: "border: 5px solid #ffffff",
                        "&:last-child": {
                          borderBottom: "5px solid #ffffff",
                        },
                        "&:first-child": {
                          borderTop: "0.5px solid #D9D9D9",
                        },
                      }}
                      className="tableRowBodyWallet"
                    >
                      <TableCell component="th" scope="row">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <img src={row.icon} alt="s" style={{ width: 24, height: 24, borderRadius: "50%" }} />
                          <Typography sx={{ fontWeight: 700, color: darkMode && "#e1e1e1" }}>
                            {row.coin}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography sx={{ fontWeight: 400, color: darkMode && "#e1e1e1" }}>
                            {row.name}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="body1"
                          sx={{ display: "flex", fontWeight: 500, color: darkMode && "#e1e1e1" }}
                        >
                          {row.available}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="body1"
                          sx={{ display: "flex", fontWeight: 500, color: darkMode && "#e1e1e1" }}
                        >
                          {row.balance}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography sx={{ color: "#4231C8", }}>
                          <Button sx={{
                            bgcolor: darkMode ? "#232323" : "#FAFAFA",
                            color: darkMode ? "#e1e1e1" : "#4231C8",
                            fontSize: "16px",
                            fontWeight: 500,
                            padding: "5px 25px 5px 25px",
                            textTransform: "capitalize",
                            "&:hover": { // Target hover state
                              backgroundColor: darkMode ? "#232323" : "#FAFAFA", // Set same background color for hover
                            }
                          }}
                          >
                            {row.buy}
                          </Button>
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography sx={{ color: "#4231C8", fontWeight: 700 }}>
                          <Button sx={{
                            bgcolor: darkMode ? "#232323" : "#FAFAFA",
                            color: darkMode ? "#e1e1e1" : "#4231C8",
                            fontSize: "16px",
                            fontWeight: 500,
                            padding: "5px 25px 5px 25px",
                            textTransform: "capitalize",
                            "&:hover": { // Target hover state
                              backgroundColor: darkMode ? "#232323" : "#FAFAFA", // Set same background color for hover
                            }
                          }}
                          >
                            {row.deposite}
                          </Button>


                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography sx={{ color: "#4231C8", fontWeight: 700 }}>
                          <Button sx={{
                            bgcolor: darkMode ? "#232323" : "#FAFAFA",
                            color: darkMode ? "#e1e1e1" : "#4231C8",
                            fontSize: "16px",
                            fontWeight: 500,
                            padding: "5px 25px 5px 25px",
                            textTransform: "capitalize",
                            fontFamily: "Inter",
                            "&:hover": { // Target hover state
                              backgroundColor: darkMode ? "#232323" : "#FAFAFA", // Set same background color for hover
                            }
                          }}
                          >
                            {row.withdraw}
                          </Button>
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{ fontWeight: 700, color: "#4231C8" }}
                      >
                        <Link to="/exchange">

                          <Button sx={{
                            bgcolor: darkMode ? "#232323" : "#FAFAFA",
                            color: darkMode ? "#e1e1e1" : "#4231C8",
                            fontSize: "16px",
                            fontWeight: 500,
                            padding: "5px 25px 5px 25px",
                            textTransform: "capitalize",
                            "&:hover": {
                              backgroundColor: darkMode ? "#232323" : "#FAFAFA",
                            }
                          }}
                          >
                            {row.trade}
                          </Button>
                        </Link>


                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Stack direction="row" justifyContent="center">
              <Button
                endIcon={<KeyboardArrowDownIcon />}
                component={Link}
                to="#"
                className="btnText loadMoreBtn"
                sx={{
                  width: 150,
                  height: 46,

                  borderRadius: 1,
                  textTransform: "capitalize",
                  bgcolor: "#4231C8",
                  "&:hover": { bgcolor: "#4231C8" },
                }}
                variant="contained"
              >
                Load More
              </Button>
            </Stack>
          </Box>
        ) : (
          value == 1 ?
            <Deposit setCrypto={setCrypto} /> : <Withrdaw setCrypto={setCrypto}/>
        )}
      </Container>
    </Layout>
  );
};

export default Wallet;
