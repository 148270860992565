import {
  Box,
  Button,
  Container,
  InputBase,
  Pagination,
  Stack,
  Tab,
  Tabs,
  Typography,
  alpha,
} from "@mui/material";
import React, { useContext, useState } from "react";
import Layout from "../../layouts/Layout";
import styled from "@emotion/styled";
import load from "../../../assets/load.svg";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Check } from "@mui/icons-material";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import lcontact from "../../../assets/leftContact.svg";
import rcontact from "../../../assets/rightContact.svg";
import { DarkModeContext } from "../../../ThemeContext";
const Anoucements = () => {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "0.25px solid #b6b6b6",
    color: "#646464",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    // marginRight: theme.spacing(2),
    marginRight: 0,
    width: "70%",
    [theme.breakpoints.up("sm")]: {},
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: 0,
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 0, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(1)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      //   [theme.breakpoints.up("md")]: {
      //     width: "80%",
      //   },
    },
  }));
  const { darkMode } = useContext(DarkModeContext)
  const [currentPage, setCurrentPage] = useState(1); // Initial page

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  return (
    <Layout>
      <Container className="manageContainer">
        <Stack className="feeCont" direction={{ md: "row", xs: "column" }} sx={{ mt: 10, mb: 11 }}>
          <Stack Item width={{ xs: "100%", md: "30%" }}>
            <Container className="manageConatiner">
              <Typography style={{ color: darkMode ? "#fff" : "#232323" }} className="supportTitle" pb={5}>
                Announcements
              </Typography>
              <Search style={{ backgroundColor: darkMode && "#282828" }}>
                <SearchIconWrapper className="searchIconD">
                  <SearchIcon color="#4231C8" />
                </SearchIconWrapper>
                <StyledInputBase className="searchInput" inputProps={{ "aria-label": "search" }} />
              </Search>
              <Stack
                className="navTabDiv"
                direction="column"
                sx={{ borderLeft: "0.75px solid #4231C8" }}
                mt={3}
                pl={1}

              >
                <Typography
                  sx={{
                    color: darkMode
                      ? (value === 0 ? '#fff' : '#999')
                      : (value === 0 ? '#232323' : '#323232')
                  }}
                  variant="body2"
                  onClick={() => setValue(0)}
                  className={`pointer sideBarLi ${value === 0 ? 'sidebarActive' : ''}`}
                >
                  All
                </Typography>

                <Typography
                  sx={{
                    color: darkMode
                      ? (value === 1 ? '#fff' : '#999')
                      : (value === 1 ? '#232323' : '#323232')
                  }}
                  variant="body2"
                  className={`pointer sideBarLi ${value === 1 ? 'sidebarActive' : ''}`}
                  onClick={() => setValue(1)}
                >
                  News
                </Typography>

                <Typography
                  sx={{
                    color: darkMode
                      ? (value === 2 ? '#fff' : '#999')
                      : (value === 2 ? '#232323' : '#323232')
                  }}
                  variant="body2"
                  className={`pointer sideBarLi ${value === 2 ? 'sidebarActive' : ''}`}
                  onClick={() => setValue(2)}
                >
                  Events
                </Typography>

                <Typography
                 sx={{
                    color: darkMode
                      ? (value === 3 ? '#fff' : '#999')
                      : (value === 3 ? '#232323' : '#323232')
                  }}
                  variant="body2"
                  className={`pointer sideBarLi ${value === 3 ? 'sidebarActive' : ''}`}
                  onClick={() => setValue(3)}
                >
                  Listings
                </Typography>

                <Typography
                 sx={{
                    color: darkMode
                      ? (value === 4 ? '#fff' : '#999')
                      : (value === 4 ? '#232323' : '#323232')
                  }}
                  variant="body2"
                  className={`pointer sideBarLi ${value === 4 ? 'sidebarActive' : ''}`}
                  onClick={() => setValue(4)}
                >
                  IEOs
                </Typography>

                <Typography
                 sx={{
                    color: darkMode
                      ? (value === 5 ? '#fff' : '#999')
                      : (value === 5 ? '#232323' : '#323232')
                  }}
                  variant="body2"
                  className={`pointer sideBarLi ${value === 5 ? 'sidebarActive' : ''}`}
                  onClick={() => setValue(5)}
                >
                  Rate Limits
                </Typography>

                <Typography
                 sx={{
                    color: darkMode
                      ? (value === 6 ? '#fff' : '#999')
                      : (value === 6 ? '#232323' : '#323232')
                  }}
                  variant="body2"
                  className={`pointer sideBarLi ${value === 6 ? 'sidebarActive' : ''}`}
                  onClick={() => setValue(6)}
                >
                  Maintenance
                </Typography>
              </Stack>
            </Container>

          </Stack>
          <Stack className="navtabContent" Item width={{ md: "70%", xs: "100%" }} mt={12}>
            {value == 1 && (
              <Container>
                <Stack width={{ md: "80%", xs: "100%" }} style={{ gap: 0 }}>
                  <Typography className="apiText" variant="h5" fontWeight={600} color={darkMode ? "#e1e1e1" : "#4231C8"}>
                    Beat The Markets With Trading Bots On ALT-X
                  </Typography>
                  <Typography variant="body1" color={darkMode ? "#e1e1e1" : "#151515"} fontSize={14} mt={4} mb={2}>
                    Published date: June 30, 2023 at 07:53 (UTC+5)
                  </Typography>
                  <Box
                    width="100%"
                    height="300px"
                    mt={2}
                    mb={2}
                  // bgcolor="#D9D9D9"
                  ></Box>
                  <Typography variant="body1" color={darkMode ? "#e1e1e1" : "#151515"} className="apiTextP">
                    In our drive to create a more immersive, enjoyable crypto
                    trading experience, ProBit Global is proud to offer our
                    users a range of third-party trading bots from trusted
                    industry partners. Combined with thorough market analysis,
                    informed research, and a deep level of industry knowledge,
                    bots can come in handy to help crypto traders—both new and
                    old—make optimal trading decisions.
                  </Typography>
                  <Typography
                    mt={2}
                    mb={2}
                    variant="h5"
                    fontWeight={600}
                    color={darkMode ? "#e1e1e1" : "#4231c8"}
                    className="apiTextTitle"
                  >
                    Who are our trading bot partners?
                  </Typography>
                  <Typography variant="body1" color={darkMode ? "#e1e1e1" : "#151515"} className="apiTextP">
                    Each of the trading bots below offer a variety of options
                    when it comes to customizing trading parameters. Be sure to
                    explore the endless possibilities with the various
                    strategies that each trading bot is able to provide.
                  </Typography>
                  <Button
                    startIcon={<KeyboardArrowLeftIcon />}
                    sx={{
                      bgcolor: "#4231C8",
                      color: "#ffffff",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      width: "fit-content",
                      mt: 4,
                      "&:hover": {
                        backgroundColor: "#4231C8",
                      },
                    }}
                  >
                    Return to list
                  </Button>
                </Stack>
              </Container>
            )}
            {value == 0 && (
              <Container>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  // bgcolor="#D9D9D9"
                  pl={2}
                  pt={1}
                  pb={1}
                  // borderTop="0.75px solid #4231C8"
                  onClick={handleClickOpen}
                >
                  <img src={load} alt="s" />
                  <Stack direction="column" gap={1}>
                    <Typography
                      color={darkMode ? "#E1E1E1" : "#151515"}
                      variant="body1"
                      className="markettext"
                      fontWeight={600}
                    >
                      Beat The Markets With Trading Bots On ALT-X
                    </Typography>
                    <Typography color="#747474" variant="body1" fontSize="14px" marginY={0.5}>
                      June 30, 2023 at 07:53 (UTC+5)
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  // bgcolor="#D9D9D9"
                  pl={2}
                  pt={1}
                  pb={1}
                  // borderTop="0.75px solid #4231C8"
                  onClick={handleClickOpen}
                >
                  <img src={load} alt="s" />
                  <Stack direction="column" gap={1}>
                    <Typography
                      color={darkMode ? "#E1E1E1" : "#151515"}
                      variant="body1"

                      fontWeight={600}
                      className="markettext"
                    >
                      ISKRA Token (ISK) Trading Competition
                    </Typography>
                    <Typography color="#747474" variant="body1" fontSize="14px" marginY={0.5}>
                      June 30, 2023 at 07:53 (UTC+5)
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  // bgcolor="#D9D9D9"
                  pl={2}
                  pt={1}
                  pb={1}
                  // borderTop="0.75px solid #4231C8"
                  onClick={handleClickOpen}
                >
                  <img src={load} alt="s" />
                  <Stack direction="column" gap={1}>
                    <Typography
                      color={darkMode ? "#E1E1E1" : "#151515"}
                      variant="body1"
                      className="markettext"
                      fontWeight={600}
                    >
                      TokoQRT (TQRT) Buy/Deposit Competition
                    </Typography>
                    <Typography color="#747474" variant="body1" fontSize="14px" marginY={0.5}>
                      June 30, 2023 at 07:53 (UTC+5)
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  // bgcolor="#D9D9D9"
                  pl={2}
                  pt={1}
                  pb={1}
                  // borderTop="0.75px solid #4231C8"
                  onClick={handleClickOpen}
                >
                  <img src={load} alt="s" />
                  <Stack direction="column" gap={1}>
                    <Typography
                      color={darkMode ? "#E1E1E1" : "#151515"}
                      variant="body1"
                      className="markettext"
                      fontWeight={600}
                    >
                      UBXS Token (UBXS) Trading Competition
                    </Typography>
                    <Typography color="#747474" variant="body1" fontSize="14px" marginY={0.5}>
                      June 30, 2023 at 07:53 (UTC+5)
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  // bgcolor="#D9D9D9"
                  pl={2}
                  pt={1}
                  pb={1}
                  // borderTop="0.75px solid #4231C8"
                  onClick={handleClickOpen}
                >
                  <img src={load} alt="s" />
                  <Stack direction="column" gap={1}>
                    <Typography
                      color={darkMode ? "#E1E1E1" : "#151515"}
                      variant="body1"
                      className="markettext"
                      fontWeight={600}
                    >
                      Green Universe Coin (GUC) Staking Event - 10,000,000 GUC
                      in Rewards
                    </Typography>
                    <Typography color="#747474" variant="body1" fontSize="14px" marginY={0.5}>
                      June 30, 2023 at 07:53 (UTC+5)
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  pl={2}
                  pt={1}
                  pb={1}
                  // borderTop="0.75px solid #4231C8"
                  onClick={handleClickOpen}
                >
                  <img src={load} alt="s" />
                  <Stack direction="column" gap={1}>
                    <Typography
                      color={darkMode ? "#E1E1E1" : "#151515"}
                      variant="body1"
                      className="markettext"
                      fontWeight={600}
                    >
                      ProBit Global Lists Pocket ProjecT (PPT1)
                    </Typography>
                    <Typography color="#747474" variant="body1" fontSize="14px" marginY={0.5}>
                      June 30, 2023 at 07:53 (UTC+5)
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  pl={2}
                  pt={1}
                  pb={1}
                  // borderTop="0.75px solid #4231C8"
                  onClick={handleClickOpen}
                >
                  <img src={load} alt="s" />
                  <Stack direction="column" gap={1}>
                    <Typography
                      color={darkMode ? "#E1E1E1" : "#151515"}
                      variant="body1"
                      className="markettext"
                      fontWeight={600}
                    >
                      Beat The Markets With Trading Bots On ALT-X
                    </Typography>
                    <Typography color="#747474" variant="body1" fontSize="14px" marginY={0.5}>
                      June 30, 2023 at 07:53 (UTC+5)
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  pl={2}
                  pt={1}
                  pb={1}
                  // borderTop="0.75px solid #4231C8"
                  onClick={handleClickOpen}
                >
                  <img src={load} alt="s" />
                  <Stack direction="column" gap={1}>
                    <Typography
                      color={darkMode ? "#E1E1E1" : "#151515"}
                      variant="body1"
                      className="markettext"
                      fontWeight={600}
                    >
                      Beat The Markets With Trading Bots On ALT-X
                    </Typography>
                    <Typography color="#747474" variant="body1" fontSize="14px" marginY={0.5}>
                      June 30, 2023 at 07:53 (UTC+5)
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  pl={2}
                  pt={1}
                  pb={1}
                  // borderTop="0.75px solid #4231C8"
                  onClick={handleClickOpen}
                >
                  <img src={load} alt="s" />
                  <Stack direction="column" gap={1}>
                    <Typography
                      color={darkMode ? "#E1E1E1" : "#151515"}
                      variant="body1"
                      className="markettext"
                      fontWeight={600}
                    >
                      Beat The Markets With Trading Bots On ALT-X
                    </Typography>
                    <Typography color="#747474" variant="body1" fontSize="14px" marginY={0.5}>
                      June 30, 2023 at 07:53 (UTC+5)
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  pl={2}
                  pt={1}
                  pb={1}
                  // borderTop="0.75px solid #4231C8"
                  onClick={handleClickOpen}
                >
                  <img src={load} alt="s" />
                  <Stack direction="column" gap={1}>
                    <Typography
                      color={darkMode ? "#E1E1E1" : "#151515"}
                      variant="body1"
                      className="markettext"
                      fontWeight={600}
                    >
                      Beat The Markets With Trading Bots On ALT-X
                    </Typography>
                    <Typography color="#747474" variant="body1" fontSize="14px" marginY={0.5}>
                      June 30, 2023 at 07:53 (UTC+5)
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  pl={2}
                  pt={1}
                  pb={1}
                  // borderTop="0.75px solid #4231C8"
                  onClick={handleClickOpen}
                  // borderBottom="0.75px solid #4231C8"
                >
                  <img src={load} alt="s" />
                  <Stack direction="column" gap={1}>
                    <Typography
                      color={darkMode ? "#E1E1E1" : "#151515"}
                      variant="body1"
                      className="markettext"
                      fontWeight={600}
                    >
                      Beat The Markets With Trading Bots On ALT-X
                    </Typography>
                    <Typography color="#747474" variant="body1" fontSize="14px" marginY={0.5}>
                      June 30, 2023 at 07:53 (UTC+5)
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" justifyContent="center" mt={5}>
                  {/* <Pagination
                    count={10}
                    page={currentPage}
                    onChange={handlePageChange}
                    sx={{
                      '& .MuiPaginationItem-selected': {
                        backgroundColor: "green",
                        color: darkMode && "#e1e1e1",
                      },
                      '& .MuiPaginationItem-root': { // Target all pagination items
                        fontSize: '16px', // Adjust font size
                        fontWeight: 'bold', // Make text bold
                        color: darkMode && "#e1e1e1",
                        backgroundColor: "red"
                      },
                    }}
                  /> */}
                  {darkMode && (
                    <Pagination
                      count={10}
                      page={currentPage}
                      onChange={handlePageChange}
                      sx={{
                        '& .MuiPaginationItem-selected': {
                          color: darkMode && "#e1e1e1",
                        },
                        '& .MuiPaginationItem-root': { // Target all pagination items
                          fontSize: '16px', // Adjust font size
                          fontWeight: 'bold', // Make text bold
                          color: darkMode && "#111",
                          backgroundColor: "#e1e1e1"
                        },
                      }}
                    />
                  )}
                  {!darkMode && (
                    <Pagination
                      count={10}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  )}
                </Stack>
                {/* <Box >
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    width="100%"
                    sx={{borderRadius:"30px"}}
                    
                  >
                    <DialogTitle
                      className="alerttitle"
                      id="alert-dialog-title"
                     
                    >
                      {"Contact Us"}
                    </DialogTitle>
                    <DialogContent  className="popMain">
                      <DialogContentText id="alert-dialog-description">
                        <Stack
                          sx={{display:"flex", flexDirection:{md:"row",xs:"column"}}}
                          border="1px solid #efefef"
                          
                        >
                          <Stack
                            direction="column"
                            alignItems="center"
                            gap={1}
                            // borderRight="0.75px solid #D9D9D9"
                            marginTop={3}
                            marginBottom={3}
                            
                          >
                            <Stack direction="row" alignItems="center" gap={1} mb={2}>
                              <Check sx={{ color: "#4231C8" }} />
                              <Typography
                                variant="body1"
                                color="#4231C8"
                                fontWeight={700}
                              >
                                {" "}
                                Recommended
                              </Typography>
                            </Stack>
                            <img src={lcontact} alt="s" />
                            <Stack direction="row" sx={{position:"relative"}}>
                              {" "}
                              <Typography
                                variant="h6"
                                className="popTxt"
                                sx={{
                                  fontSize: "18px",
                                  fontWeight: 700,
                                  color: "#151515",
                                }}
                              >
                                Contact via form
                              </Typography>
                              <ArrowCircleRightOutlinedIcon className="arrowIcon1" sx={{color:"#4231C8",position:"absoute",ml:3}}/>
                            </Stack>

                            <ul className="black">
                              <li>
                                We'll send a reply to your Alt-X Global
                                account's email address.
                              </li>
                              <li>
                                This is the preferred option in case you need
                                quicker responses.
                              </li>
                            </ul>
                          </Stack>
                          <Stack direction="column" alignItems="center" gap={1} marginTop={3}
                            marginBottom={3}>
                            <Stack direction="row" alignItems="" gap={1} mb={2}>
                              <Check sx={{ color: "#4231C8" }} />
                              <Typography
                                variant="body1"
                                color="#4231C8"
                                fontWeight={700}
                                
                              >
                                {" "}
                                Recommended
                              </Typography>
                            </Stack>
                            <img src={rcontact} alt="s" />
                             <Stack direction="row" sx={{position:"relative"}}>
                              {" "}
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 700,
                                  color: "#151515",
                                }}
                                className="popTxt"
                              >
                                 Contact Support
                              </Typography>
                              <ArrowCircleRightOutlinedIcon className="arrowIcon1" sx={{color:"#4231C8",position:"absoute",ml:3}}/>
                            </Stack>
                            <ul className="black">
                              <li>
                                If you're new to Alt-X or having trouble
                                accessing your account, please click here.
                              </li>
                            </ul>
                          </Stack>
                        </Stack>
                      </DialogContentText>
                    </DialogContent>
                  </Dialog>
                </Box> */}
              </Container>
            )}
          </Stack>
        </Stack>
      </Container>
    </Layout>
  );
};

export default Anoucements;
