import React, { useState } from 'react';
import profile from "../../../assets/userImg.png";
import camera from "../../../assets/camera.svg";
const ImageUpload = () => {
    
  const [selectedImage, setSelectedImage] = useState(profile);

  const handleImageChange = (e) => {
    setSelectedImage(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <div className='uploadImageMainDiv'>
      <div className='uploadedImageGroup'>
        <div className='uploadedImageDiv'>
          {selectedImage && <img src={selectedImage} alt="Selected"/>}
        </div>
        <label htmlFor="imageInput" className='imageImageLabel'>
               <img src={camera}/>
            </label>
            <input type="file" id="imageInput" accept="image/*" onChange={handleImageChange} style={{ display: 'none' }}
            />
      </div>
    </div>
  );
};

export default ImageUpload;
