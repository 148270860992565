import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Home from "./pages/Home";
import Trade from "./pages/Trade";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import Listing from "./pages/listing/Listing";

import Wallet from "./pages/Wallet";
import Swap from "./pages/Swap";
import WithdrawForm from "./layouts/table/WithdrawForm";
import TransactionHistory from "./pages/TransactionHistory";
import Fee from "./pages/Fee";
import ForgotPassword from "./pages/auth/ForgotPassword";
import VarifyCode from "./pages/auth/VarifyCode";
import Documentation from "./pages/Documentation";
import PrivacyPolicy from "./pages/footerPages/PrivacyPolicy";
import TermConditions from "./pages/footerPages/TermConditions";
import OfficialVariification from "./pages/footerPages/OfficialVariification";
import ContactUs from "./pages/footerPages/ContactUs";
import Anoucements from "./pages/footerPages/Anoucements";
import Lounchpade from "./pages/Lounchpade";
import AllOrders from "./pages/AllOrders";
import Faqs from "./pages/faq/Faqs";
import DepositWithdrawals from "./pages/DepositWithdrawals";
import Global from "./pages/faq/FaqDetailPage";
import FaqDetailPage from "./pages/faq/FaqDetailPage";
import Profile from "./pages/profile";
import Api from "./pages/api";
import Exchange from "./pages/exchange";
import ResetPassword from "./pages/auth/ResetPassword";
import Chart from "./pages/Chart";


const Routers = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot/password" element={<ForgotPassword />} />
          <Route path="/varify/code" element={<VarifyCode />} />
          <Route path="/resetpassword" element={<ResetPassword />} />


          <Route path="/exchange" element={
              <Exchange />
          } />
          <Route path="/chart/:coin" element={
              <Chart />
          } />

          <Route path="/trade" element={
            <ProtectedRoute>
              <Trade />
            </ProtectedRoute>
          } />

          <Route path="/lounchpad" element={
            <ProtectedRoute>
              <Lounchpade />
            </ProtectedRoute>
          } />

          <Route path="/lounchpad" element={
            <ProtectedRoute>
              <Lounchpade />
            </ProtectedRoute>
          } />

          <Route path="/support" element={
            <ProtectedRoute>
              <AllOrders />
            </ProtectedRoute>
          } />

          <Route path="/open/orders" element={
            <ProtectedRoute>
              <AllOrders />
            </ProtectedRoute>
          } />

          <Route path="/order/history" element={
            <ProtectedRoute>
              <AllOrders />
            </ProtectedRoute>
          } />

          <Route path="/trade/history" element={
            <ProtectedRoute>
              <AllOrders />
            </ProtectedRoute>
          } />

          <Route path="/trade/history" element={
            <ProtectedRoute>
              <AllOrders />
            </ProtectedRoute>
          } />


          <Route path="/trade/history" element={
            <ProtectedRoute>
              <AllOrders />
            </ProtectedRoute>
          } />


          <Route path="/wallet" element={
            <ProtectedRoute>
              <Wallet />
            </ProtectedRoute>
          } />


          <Route path="/wallet/withdrawal" element={
            <ProtectedRoute>
              <Wallet />
            </ProtectedRoute>
          } />


          <Route path="/wallet/deposit" element={
            <ProtectedRoute>
              <Wallet />
            </ProtectedRoute>
          } />


          <Route path="/swap" element={
            <ProtectedRoute>
              <Swap />
            </ProtectedRoute>
          } />


          {/* <Route path="exchange" element={<Exchange />} /> */}
          {/* <Route path="/trade" element={<Trade />} /> */}
          {/* <Route path="/lounchpad" element={<Lounchpade />} /> */}
          {/* <Route path="/open/orders" element={<AllOrders/>} />
          <Route path="/order/history" element={<AllOrders/>} />
          <Route path="/trade/history" element={<AllOrders/>} /> */}
          {/* <Route path="/wallet" element={<Wallet />} />
          <Route path="/wallet/withdrawal" element={<Wallet />} />
          <Route path="c" element={<Wallet />} /> */}
          {/* <Route path="/swap" element={<Swap />} /> */}


          {/* //           saeed-dev code after work saeed  */}
          {/* //           <Route path="/support" element={<AllOrders />} />
          //           <Route path="/orders" element={<AllOrders />} /> */}
          {/* sssssssssssssssssssssss
                      
          //            code from main before saeed working */}





          <Route path="/listing" element={<Listing />} />

          <Route path="/wallet/withdraw" element={<WithdrawForm />} />
          <Route path="/fee" element={<Fee />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/transaction/history" element={<TransactionHistory />} />
          {/* footer pages */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/annoucements" element={<Anoucements />} />
          <Route path="/term-conditions" element={<TermConditions />} />
          <Route
            path="/official-varification"
            element={<OfficialVariification />}
          />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/deposit-withdrawals" element={<DepositWithdrawals />} />
          {/* end of footer pages */}

          {/* faq pages  */}
          <Route path="/faq/detail" element={<FaqDetailPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/api" element={<Api />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Routers;
