import React, { useContext } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Box, Stack } from "@mui/material";
import { getToken } from "../../services/LocalStorageService";
import { useLocation } from "react-router-dom";
import { DarkModeContext } from "../../ThemeContext";

const Layout = ({ children }) => {
  const access_token = getToken()
  const { pathname } = useLocation()
  const {darkMode} = useContext(DarkModeContext)
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      sx={{ height: "100vh", backgroundColor : darkMode && "#282828"}}
    >
      <Box sx={{backgroundColor : darkMode && "#282828"}}>
        <Header access_token={access_token} />
        <Box access_token={access_token}>{children}</Box>
      </Box>
      {pathname !== "/exchange" && (
        <Box>
          <Footer />
        </Box>
      )}
    </Stack>
  );
};

export default Layout;
