
import logo from "./logo.svg";
import "./App.css";
import Header from "./components/layouts/Header";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { grey,purple } from '@mui/material/colors';
import Routers from "./components/Routers";


function App() {
  const theme = createTheme({
    components: {
      MuiButtonBase: { // This will target all button-like components
        defaultProps: {
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
    },
    palette: {
    secondary:{
      main:"#4231C8"
    },
  },
    typography: {
      fontFamily: "Roboto",
      subtitle1: { fontFamily: "Roboto" },
      subtitle2: { fontFamily: "Roboto" },
      body1: { fontFamily: "Roboto" },
      body2: { fontFamily: "Roboto" },
    },

  });
  return (
    <ThemeProvider theme={theme}>
      <Routers/>
    </ThemeProvider>
  );
}

export default App;