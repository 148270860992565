import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import mobile from "../../../assets/iPhone 14 Mockup Vol 1 02 1.svg";
import Layout from "../../layouts/Layout";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useLoginUserMutation } from "../../../services/userAuthApi";
import { storeToken, storeUserProfileData, getUserProfileData } from "../../../services/LocalStorageService";
import { DarkModeContext } from "../../../ThemeContext";
import url from "../../../config";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 6,
  width: 19,
  color: "#4231C8",
  height: 19,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px #4231C8"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#4231C8",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 19,
    height: 19,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

const Login = () => {
  const [check, setcheck] = useState(false);
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",

      border: "1px solid #4231C8",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  }));
  const [email, setemail] = useState('')
  const [password, setpassword] = useState('')
  const [loginError, setLoginError] = useState()
  const [data, { isLoading }] = useLoginUserMutation()
  const navigate = useNavigate()
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, SetLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const isValidEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  const emailHandler = (value) => {
    setemail(value)
  }

  const passwordHandler = (value) => {
    setpassword(value)
  }

  const clearLoginError = () => {
    setLoginError('');
  };

  const SignInFormHandler = async () => {

    let isValid = true;


    if (!email.trim()) {
      setEmailError('Email is required.');
      isValid = false;
    } else if (!isValidEmail(email)) {
      setEmailError('Invalid email format.');
      isValid = false;
    } else {
      setEmailError('');
    }


    if (!password.trim()) {
      setPasswordError('Password is required.');
      isValid = false;
    } else {
      setPasswordError('');
    }


    if (isValid) {

      SetLoading(true);

      try {
        const data = {
          email: email,
          password: password
        }

        console.log(data, " this is data");
        const response = await axios.post(`${url}api/v1/user-auth/login/`, data, {

        });

        if (response.status == 201 || response.status == 200) {

          try {
            const beare = response.data.access;
            console.log(beare, " this is access token")
            const res = await axios.get(`${url}api/v1/accounts/get-user-profile-data/`, {
              headers: {
                Authorization: `Bearer ${beare}`
              }
            });

              console.log(res.data, " this is user profile ")
            storeUserProfileData(res.data);
            SetLoading(false);


            // Handle the response data as needed
          } catch (error) {
            console.error('Error fetching user profile data:', error);
            SetLoading(false);

            // Handle errors as needed
          }
          console.log('Response:', response);
          storeToken(response.data);
          const userProfileData = getUserProfileData();
          SetLoading(false);

          console.log(userProfileData, " this is stored user profile");
          navigate('/profile')


        }

      } catch (error) {
        console.error('Error:', error);
        SetLoading(false);

        setLoginError("No active account found with the given credentials")
      }
    }
  };




  const { darkMode } = useContext(DarkModeContext)
  return (
    <Layout>
      <Stack sx={{ bgcolor: darkMode ? "#282828" : "#FAFAFA", pb: 2 }}>
        <Container className="manageContainer">
          <Grid container spacing={2} xs={12}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Stack
                direction="column"
                sx={{ paddingTop: { xs: 15, md: 0 }, width: "100%" }}
              >
                <Typography
                  variant="h4"
                  sx={{ color: darkMode ? "#fff" : "#4231C8", fontWeight: 800, "mt": 16, "mb": 2 }}
                >
                  Sign In
                </Typography>
                {loginError && <Typography color="error" className="text-center mb-2">{loginError}</Typography>}

                <div className="formInputDivs my-4">
                  <label htmlFor="email" className="labelTxt mb-2"
                    style={{ color: darkMode ? "#999" : "#333" }}
                  >
                    Email
                  </label>
                  <input
                    style={{ color: darkMode && "#999", backgroundColor: darkMode ? "#282828" : "", border: emailError && '1px solid red' }}
                    className="form-control textInput formInputs"
                    id="email"
                    onChange={(e) => { emailHandler(e.target.value) }}
                    value={email}
                    onBlur={clearLoginError}
                  />
                  {emailError && <Typography color="error">{emailError}</Typography>}
                </div>

                <div className="formInputDivs mb-2">
                  <label htmlFor="password" className="labelTxt mb-2"
                    style={{ color: darkMode ? "#999" : "#333" }}
                  >
                    Password
                  </label>
                  <div style={{ position: 'relative', width: 420 }}>
                    <input
                      style={{ color: darkMode && "#999", backgroundColor: darkMode ? "#282828" : "", border: passwordError && '1px solid red', paddingRight: '40px' }}
                      type={showPassword ? "text" : "password"}
                      className="form-control textInput formInputs"
                      id="password"
                      onChange={(e) => { passwordHandler(e.target.value) }}
                      value={password}
                      onBlur={clearLoginError}
                    />
                    <button onClick={togglePasswordVisibility}
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: "gray"
                      }}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </button>
                  </div>
                  {passwordError && <Typography color="error">{passwordError}</Typography>}
                </div>
                <Box
                  sx={{
                    mb: 2,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div className="mb-1 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input formCheck"
                      id="exampleCheck1"
                    />
                    <label
                      className="form-check-label checkLabel"
                      for="exampleCheck1"
                    >
                      Remember me
                    </label>
                  </div>
                </Box>
                <Button
                  sx={{
                    height: 50,
                    bgcolor: loading ? "#ccc" : "#4231C8", // Change background color based on loading state
                    "&:hover": { bgcolor: loading ? "#ccc" : "#4231C8" }, // Change hover color based on loading state
                    width: "420px"
                  }}
                  variant="contained"
                  className="formBtn"
                  onClick={loading ? null : SignInFormHandler} // Disable onClick handler when loading is true
                  disabled={loading} // Disable the button when loading is true
                >
                  {loading ? 'Loading...' : 'Sign In'} {/* Change button text based on loading state */}
                </Button>
                <Typography
                  variant="body1"

                  sx={{ mt: 1 }}
                >
                  <Link
                    to="/forgot/password"
                    style={{ color: darkMode ? "#999" : "#4231c8" }}
                    className="forgotPass">Forgot Password?
                  </Link>
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mt: 6
                  }}
                  className="signLink"
                >
                  <Typography variant="body1" sx={{ color: "#999999" }}>
                    Don’t have an account?{" "}
                  </Typography>
                  <Link to="/signup" className="signLinkA">
                    <Typography
                      variant="body1"
                      sx={{ textDecoration: "underline", color: darkMode ? "#e1e1e1" : "#4231c8" }}
                    >

                      Sign Up
                    </Typography>
                  </Link>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                direction="column"
                alignItems="end"
                justifyContent="center"
                sx={{
                  paddingTop: { md: 11, sm: 5, xs: 5 },
                  paddingRight: '40px'
                }}
              >
                {/* <img src={ecips} alt="s" className="eclips4" /> */}
                <img src={mobile} alt="s" className="mobile2" />
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Stack>
    </Layout>
  );
};

export default Login;
