import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Stack,
  Button,
  MenuItem,
  Box,
  Menu,
  FormControl,
  InputLabel,
  InputBase,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";

const CoinBtn = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [fname, setfname] = useState(props?.list[0]?.name);
  const [lname, setlname] = useState("");
  const handleClose = (a, b, c) => {
    props?.getValue(b);
    setfname(a);
    // setlname(c);
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      width: { xs: "90%", md: "80%" },
      marginRight: "auto",
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  console.log("anchorEl", anchorEl);

  return (
    <Box
      width={{
        md: "30%",
        xs: "100%",
        ...(props.name == "12" && {
          position: "relative",
          marginTop: "32px",
        }),
      }}
    >
      <InputLabel
        shrink
        htmlFor="email"
        sx={{
          color: "#1D2F6F",
          fontSize: "24px",
          fontWeight: 400,
        }}
      >
        {props.name == "12" ? " " : props.name}
      </InputLabel>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          textTransform: "capitalize",
          height: 47,
          width: "100%",
          bgcolor: "#fff",
          border: "1px solid #1D2F6F",
          color: "#1D2F6F",
          "&:hover": { bgcolor: "#fff" },
        }}
      >
        <Box
          marginRight="auto"
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Typography fontWeight={800}>{fname}</Typography>
          <Typography color="#999999" ml={1}>
            {lname}
          </Typography>
        </Box>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          ariLabelledby: "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(fname, "", "")}
      >
        {props?.list?.map((item) => {
          return (
            <MenuItem
              onClick={() => handleClose(item?.name, item?.value, "")}
              disableRipple
            >
              <Typography fontWeight={800}>{item?.name}</Typography>
            </MenuItem>
          );
        })}
        {/* <MenuItem
          onClick={() => handleClose("", "All coins", "")}
          disableRipple
        >
          <Typography fontWeight={800}>All coins</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => handleClose("bcoin", "BTC", "Bitcoin")}
          disableRipple
        >
          <Typography fontWeight={800}>BTC</Typography>
          <Typography color="#999999" ml={1}>
            Bitcoin
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => handleClose("ecoin", "ETH", "Ethereum")}
          disableRipple
        >
          <Typography fontWeight={800}>ETH</Typography>
          <Typography color="#999999" ml={1}>
            Ethereum
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={() => handleClose("pcoin", "PVT", "P20 coin")}
          disableRipple
        >
          <Typography fontWeight={800}>PVT </Typography>
          <Typography color="#999999" ml={1}>
            P20 coin
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={() => handleClose("dcoin", "USDC", "USD coin")}
          disableRipple
        >
          <Typography fontWeight={800}>USDC </Typography>
          <Typography color="#999999" ml={1}>
            USD coin
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => handleClose("tcoin", "TRX", "Tron")}
          disableRipple
        >
          <Typography fontWeight={800}>TRX </Typography>
          <Typography color="#999999" ml={1}>
            Tron
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => handleClose("bicoin", "BUSD", "Binance")}
          disableRipple
        >
          <Typography fontWeight={800}>BUSD </Typography>
          <Typography color="#999999" ml={1}>
            Binance
          </Typography>
        </MenuItem> */}
      </StyledMenu>
    </Box>
  );
};

export default CoinBtn;
