import React, { useState, useContext, useEffect } from "react";
import Layout from "../../layouts/Layout";
import { ExchangeLeftComponent } from "./LeftSide";
import CenterSide from "./CenterSide";
import RightSide from "./RightSide";
import TradeFeed from "./TradeFeed";
import LimitMarkrt from "./LimitMarkrt";
import CustomIcon from './CustomIcon';
import { getToken } from "../../../services/LocalStorageService";
import { DarkModeContext } from "../../../ThemeContext";
import { useLocation } from 'react-router-dom'
const Exchange = () => {
  const location = useLocation();
  let state = location?.state;
  const access_token = getToken()
  const [upperDataObj, setUpperDataObj] = useState({
    name: "",
    pair: "",
    change24HR: "",
    lastPrice: "",
    high: "",
    low: "",
    volume: "",
    amount: "",
    from: "",
    to: "",
    symbol: ""
  })
  const [isExchangeComponentVisible, setExchangeComponentVisibility] = useState(false);

  const handleIconClick = () => {
    setExchangeComponentVisibility(!isExchangeComponentVisible);
  };

  const { darkMode } = useContext(DarkModeContext)


  useEffect(() => {
    state = null
  }, [upperDataObj])

  return (
    <Layout>
      <div style={{ backgroundColor: darkMode ? "#1E1E1E" : "#E9ECF1" }} className="ex-change-div row flex-col flex-xxl-row">
        {/* <div style={{backgroundColor : "red"}} className="iconDiv">
          <div className={`col-lg-4 exchange-left-container  exchangeSideContent ${isExchangeComponentVisible ? 'show' : ''}`}>
            <div className="icon-container" onClick={handleIconClick}>

              {isExchangeComponentVisible ? (
                <CustomIcon isLeft={true} />
              ) : (
                <CustomIcon isLeft={false} />
              )}
            </div>
            <ExchangeLeftComponent upperDataObj={upperDataObj} setUpperDataObj={setUpperDataObj} />
          </div>
        </div> */}
        {window.innerWidth < 1600 ? (
          <div className="iconDiv">
            <div className={`col-lg-4 exchange-left-container exchangeSideContent ${isExchangeComponentVisible ? 'show' : ''}`}>
              <div className="icon-container" onClick={handleIconClick}>
                <div className="icon-container" onClick={handleIconClick}>

                  {isExchangeComponentVisible ? (
                    <CustomIcon isLeft={true} />
                  ) : (
                    <CustomIcon isLeft={false} />
                  )}
                </div>
              </div>
              <ExchangeLeftComponent upperDataObj={upperDataObj} setUpperDataObj={setUpperDataObj} />
            </div>
          </div>
        ) : (
          <div
            style={{ width: "22%" }}
            className="d-none d-xxl-block">
            <ExchangeLeftComponent upperDataObj={upperDataObj} setUpperDataObj={setUpperDataObj} />
          </div>
        )}
        <div style={{ backgroundColor: darkMode ? "#1E1E1E" : "#E9ECF1" }} className="col-lg-12 widthAdjust exchangeMainCol">
          <div marg className="row">
            <div style={{ backgroundColor: darkMode ? "#1E1E1E" : "#E9ECF1" }} className="col-lg-7">
              <CenterSide upperDataObj={upperDataObj} state={state}/>
            </div>
            <div className="col-lg-5">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 px-md-2">
                  <RightSide upperDataObj={upperDataObj} />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 px-lg-2">
                  <TradeFeed upperDataObj={upperDataObj} />
                </div>
              </div>
              <div
                style={{
                  marginTop: 8, marginLeft: -3,
                  border: darkMode ? "1px solid #4b4b4b80" : "1px solid #b6b6b680"
                }}
                className="">
                <LimitMarkrt upperDataObj={upperDataObj} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};


export default Exchange;
