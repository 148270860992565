import { useState } from "react";

export const myPages = [
  { name: "Profile", selected: true, image: require("../../assets/Group 1232.svg") },
  {
    name: "Verification",
    selected: false,
    image: require("../../assets/Group 1233.svg"),
  },
  {
    name: "Security",
    selected: false,
    image: require("../../assets/Group 1234.svg"),
  },
  {
    name: "Settings",
    selected: false,
    image: require("../../assets/Layer 2.svg"),
  },
];

export const verifySteps = [
  { name: "Step 1: Basic Information", verify: false },
  { name: "Step 2: Identity Verification", verify: true },
];
export const securitySteps = [
  {
    name: "OTP",
    desc: "Status: Not in use",
    enable: true,
    image: require("../../assets/Group 1246 (1).svg"),
  },
  {
    name: "Security Key",
    desc: "Status: Not in use",
    enable: true,
    image: require("../../assets/Group 1246.svg"),
  },
];

export const tableCol1 = ["Device", "Location", "Date (UTC+5)", "IP", ""];
export const tableCol2 = ["Date (UTC+5)", "IP", "Action", "Results"];
export const settings = [
  {
    name: "Language",
    text: "English",
    icon: require("../../assets/lang.svg"),
  },
  {
    name: "Timezone",
    text: "UTC+5 Standard",
    icon: require("../../assets/lang.svg"),
  },
];

// export const NotificationPreferences = [
//   {
//     title: "Agree to the collection and use of personal information (optional)",
//     terms: "View terms",
//     conditions: [
//       { name: "I agree", selected: true },
//       { name: "I do not agree", selected: false },
//     ],
//   },
//   {
//     title: "Email preferences setting ",
//     terms: "View terms",
//     conditions: [
//       { name: "Received", selected: true },
//       { name: "Do not receive", selected: false },
//     ],
//   },
//   {
//     title: "SMS preference setting",
//     terms: "View terms",
//     conditions: [
//       { name: "I agree", selected: true },
//       { name: "I do not agree", selected: false },
//     ],
//   },
// ];

export const ApiTabs = [
  { name: "API Management", selected: true },
  { name: "Withdrawal address management", selected: false },
];

export const addressTableColumns = [
  "Nickname",
  "Coin(platform)",
  "Withdrawal address",
  "Destination tag, memo, message ",
];

export const addressData = [
  {
    nickName: "1ZBvbm7TyT",
    platForm: "BITCOIN",
    Withdrawal: "1ZBvbm7TyTAe",
    Destination: "1ZBvbm7TyTAe1ZBvbm7TyTAe",
  },
  {
    nickName: "1ZBvbm7TyT",
    platForm: "BITCOIN",
    Withdrawal: "1ZBvbm7TyTAe",
    Destination: "1ZBvbm7TyTAe1ZBvbm7TyTAe",
  },
];




export const exchangeSymbolTabs = [
  { name: "USDT", selected: true },
  { name: "BTC", selected: false },
  { name: "ETH", selected: false },
  { name: "DeFi", selected: false },
  { name: "Meme", selected: false },
  { name: "Favorites", selected: false },
];
export const exchangeSymbolTabsHistory = [
  { name: "Open Orders", selected: true },
  { name: "Order History", selected: false },
  { name: "Trade Historry", selected: false },
];
export const exchangeSymbolTabsMarket = [
  { name: "Limit", selected: true },
  { name: "Market", selected: false },
];
export const column3 = ["Coin", "Price", "24hr", "Amount"];
export const column4 = ["Coin", "Available", "Total", "Estimated"];
export const column5 = [
  "Side",
  "Market",
  "Type",
  "Price",
  "Amount",
  "Filled",
  "Total",
];
export const column6 = ["Price", "Amount", "Total"];
export const column7 = ["Price", "Amount", "Time"];




export const orderBookData = [
  { price: "30,223.0", amount: "0.0003", total: "0.8613" },
  { price: "30,223.0", amount: "0.0003", total: "0.8613" },
  { price: "30,223.0", amount: "0.0003", total: "0.8613" },
  { price: "30,223.0", amount: "0.0003", total: "0.8613" },
  { price: "30,223.0", amount: "0.0003", total: "0.8613" },
  { price: "30,223.0", amount: "0.0003", total: "0.8613" },
  { price: "30,223.0", amount: "0.0003", total: "0.8613" },
  { price: "30,223.0", amount: "0.0003", total: "0.8613" },
  { price: "30,223.0", amount: "0.0003", total: "0.8613" },
  { price: "30,223.0", amount: "0.0003", total: "0.8613" },

  // Add more data as needed
];
export const tradeFeedData = [
  {
    type: "buy",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "sell",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "buy",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "sell",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "buy",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "sell",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "buy",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "sell",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "buy",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "sell",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "buy",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "sell",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "buy",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "sell",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "buy",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "sell",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "buy",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "sell",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "buy",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "sell",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "buy",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },
  {
    type: "sell",
    price: 0.0728,
    quantity: 150000,
    time: "10:59:46",
  },

  // Add more data as needed
];

export const despositArray = [
  { name: "All", value: "" },
  { name: "Deposit", value: "Deposit" },
  { name: "Withdrawal", value: "Withdrawal" },
];
export const coinsArray = [
  { name: "All Coins", value: "" },
  { name: "BTC", value: "BTC" },
  { name: "ETC", value: "ETC" },
];

export const transactionColumn1 = [
  "Date (UTC+5)",
  "Type",
  "Status",
  "Coin",
  "Amount",
  "Action",
];
export const transactionColumn2 = [
  {
    title : "Side",
  market : "Market",
  type : "Type",
  price : "Price",
  quant : "Quantity",
  filled :"Filled",
  total : "Total",
   date :"Date(UTC + 5)",
  }
];
