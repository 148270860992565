import React from 'react';
import { Navigate } from 'react-router-dom';
import { getToken } from '../services/LocalStorageService';

function ProtectedRoute({ children }) {
  const access_token = getToken();
    console.log(access_token,'tokens')

  if (!access_token?.access_token) {
    return <Navigate to="/login" />; // Redirect to login if not authenticated or no access
  }
  // if ( alreadyVerified ) {
  //   return <Navigate to="/account-verification" />; // Redirect to login if not authenticated or no access
  // }
//   if ( !alreadyVerified || !hasAccess || isError) {
//     return <Navigate to="/account-verification" />; // Redirect to login if not authenticated or no access
//   }

  
  return children;
}


export default ProtectedRoute;
