import React, { useContext, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import QrCode2SharpIcon from "@mui/icons-material/QrCode2Sharp";
import CheckIcon from "@mui/icons-material/Check";
import Divider from "@mui/material/Divider";
import bcoin from "../../../assets/bcoin.png";
import bicoin from "../../../assets/bicoin.png";
import tcoin from "../../../assets/tcoin.png";
import pcoin from "../../../assets/pcoin.png";
import dcoin from "../../../assets/dcoin.png";
import ecoin from "../../../assets/ecoin.png";
import copy from "../../../assets/copy.png";
import notFound from "../../../assets/notFound.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Box,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputBase,
  InputLabel,
  Radio,
  Stack,
  Typography,
  colors,
} from "@mui/material";
import { Link } from "react-router-dom";
import Layout from "../Layout";
import { DarkModeContext } from "../../../ThemeContext";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  color: "#4231C8",
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px #4231C8"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  // ".Mui-focusVisible &": {
  //   outline: "2px auto #4231C8",
  //   outlineOffset: 2,
  // },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#4231C8",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#4231C8",
  },
});

const countries = [
  { code: "us", label: "United States", icon: "🇺🇸" },
  { code: "es", label: "Spain", icon: "🇪🇸" },
  { code: "cn", label: "China", icon: "🇨🇳" },
  { code: "in", label: "India", icon: "🇮🇳" },
  { code: "sa", label: "Saudi Arabia", icon: "🇸🇦" },
  { code: "fr", label: "France", icon: "🇫🇷" },
  { code: "ru", label: "Russia", icon: "🇷🇺" },
  { code: "pt", label: "Portugal", icon: "🇵🇹" },
  { code: "bd", label: "Bangladesh", icon: "🇧🇩" },
  { code: "pk", label: "Pakistan", icon: "🇵🇰" },
  { code: "id", label: "Indonesia", icon: "🇮🇩" },
  { code: "de", label: "Germany", icon: "🇩🇪" },
  { code: "jp", label: "Japan", icon: "🇯🇵" },
  { code: "br", label: "Brazil", icon: "🇧🇷" },
  { code: "ng", label: "Nigeria", icon: "🇳🇬" },
  { code: "mx", label: "Mexico", icon: "🇲🇽" },
  { code: "kr", label: "South Korea", icon: "🇰🇷" },
  { code: "tr", label: "Turkey", icon: "🇹🇷" },
  { code: "it", label: "Italy", icon: "🇮🇹" },
  { code: "vn", label: "Vietnam", icon: "🇻🇳" },
  // Add more countries as needed
];

const WithdrawForm = () => {
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      color: darkMode && "#e1e1e1",
      border: "1px solid #999",
      fontSize: 16,

      width: "100%",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      // "&:focus": {
      //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      //   borderColor: theme.palette.primary.main,
      // },
    },
  }));
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    handleClose();
  };
  const { darkMode } = useContext(DarkModeContext)
  return (
    <Layout>
      <Container className="mainContain manageContainer" sx={{ marginLeft: "auto", mt: 10, mb: 2 }}>
        <Stack className="formDiv" sx={{ borderBottom: darkMode ? "1px solid #e1e1e1" : "1px solid #4231C8", pb: 2, mb: 2 }}>
          <Typography variant="h4" className="mainTitle" sx={{ fontWeight: 700, color: darkMode ? "#e1e1e1" : "#4231C8" }}>
            Alt-X IEO and Listing Application
          </Typography>
          <Typography variant="body2" className="wwUrl" sx={{ color: "#999999", mb: 2 }}>
            www.ALT-X.com
          </Typography>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",

            gap: 2,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              mt: 2,
              gap: 2,
            }}
          >
            <FormControl fullWidth variant="standard">
              <InputLabel
                style={{ color: darkMode && "#e1e1e1" }}
                shrink
                htmlFor="email"
                className="formLabel"
                sx={{
                  "&.Mui-focused": {
                    color: darkMode ? "#fff" : "#232323", // Maintain original color on focus
                  },
                }}
              >
                First Name
              </InputLabel>
              <BootstrapInput defaultValue="" className="formInput" id="email" />
            </FormControl>
            <FormControl fullWidth variant="standard">
              <InputLabel
                style={{ color: darkMode && "#e1e1e1" }}
                shrink
                htmlFor="email"
                className="formLabel"
                sx={{
                  "&.Mui-focused": {
                    color: darkMode ? "#fff" : "#232323", // Maintain original color on focus
                  },
                }}
              >
                Last Name
              </InputLabel>
              <BootstrapInput defaultValue="" className="formInput" id="email" />
            </FormControl>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              mt: { md: 2, xs: 0 },
              gap: 2,
            }}
          >
            <FormControl fullWidth variant="standard" sx={{}}>
              <InputLabel
                sx={{
                  "&.Mui-focused": {
                    color: darkMode ? "#fff" : "#232323", // Maintain original color on focus
                  },
                }}
                style={{ color: darkMode && "#e1e1e1" }}
                shrink
                htmlFor="email"
                className="formLabel"
              >
                Telegram ID
              </InputLabel>
              <BootstrapInput defaultValue="" className="formInput" id="email" />
            </FormControl>
            <FormControl fullWidth variant="standard">
              <InputLabel
                sx={{
                  "&.Mui-focused": {
                    color: darkMode ? "#fff" : "#232323", // Maintain original color on focus
                  },
                }}
                style={{ color: darkMode && "#e1e1e1" }}
                shrink
                htmlFor="email"
                className="formLabel"
              >
                Email
              </InputLabel>
              <BootstrapInput defaultValue="" className="formInput" id="email" />
            </FormControl>
          </Stack>
          <Stack sx={{ mt: { md: 2, xs: 0 }, }}>
            <div width="100%">
              <InputLabel
                style={{ color: darkMode && "#e1e1e1" }}
                shrink
                htmlFor="email"
                className="formLabel formLabel1"
              >
                Country
              </InputLabel>
              <Button
                aria-haspopup="true"
                style={{ justifyContent: "space-between" }}
                variant="outlined"
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                  color: darkMode ? "#e1e1e1" : "#555",
                  textTransform: "capitalize",
                  border: "1px solid #999",
                  width: "100%",
                  height: 46,
                  fontSize: '16px',
                  "&:hover": { bgcolor: darkMode && "#282828", border : "1px solid #999" },
                }}
              >
                {selectedCountry.icon} {selectedCountry.label}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {countries.map((country) => (
                  <MenuItem
                    key={country.code}
                    onClick={() => handleCountrySelect(country)}
                  >
                    {country.icon} {country.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Stack>

          <Stack
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
              mt: { md: 2, xs: 0 },
            }}
          >
            <FormControl fullWidth variant="standard">
              <InputLabel
                sx={{
                  "&.Mui-focused": {
                    color: darkMode ? "#fff" : "#232323", // Maintain original color on focus
                  },
                }}
                style={{ color: darkMode && "#e1e1e1" }}
                shrink
                htmlFor="email"
                className="formLabel"
              >
                Project Name
              </InputLabel>
              <BootstrapInput defaultValue="" className="formInput" id="email" />
            </FormControl>
            <FormControl fullWidth variant="standard">
              <InputLabel
                sx={{
                  "&.Mui-focused": {
                    color: darkMode ? "#fff" : "#232323", // Maintain original color on focus
                  },
                }}
                style={{ color: darkMode && "#e1e1e1" }}
                shrink
                htmlFor="email"
                className="formLabel"
              >
                Project Website
              </InputLabel>
              <BootstrapInput defaultValue="" className="formInput" id="email" />
            </FormControl>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
              mt: { md: 2, xs: 0 },
            }}
          >
            <FormControl fullWidth variant="standard" sx={{ mt: 0, mb: 0 }}>
              <InputLabel
                sx={{
                  "&.Mui-focused": {
                    color: darkMode ? "#fff" : "#232323", 
                  },
                }}
                style={{ color: darkMode && "#e1e1e1" }}
                shrink
                htmlFor="email"
                className="formLabel"
              >
                Project Token Ticker
              </InputLabel>
              <BootstrapInput defaultValue="" className="formInput" id="email" />
            </FormControl>
            <FormControl fullWidth variant="standard" sx={{ mt: 0, mb: 0 }}>
              <InputLabel
                sx={{
                  "&.Mui-focused": {
                    color: darkMode ? "#fff" : "#232323", 
                  },
                }}
                style={{ color: darkMode && "#e1e1e1" }}
                shrink
                htmlFor="email"
                className="formLabel"
              >
                Project Website
              </InputLabel>
              <BootstrapInput defaultValue="" className="formInput" id="email" />
            </FormControl>
          </Stack>
        </Stack>
        <Typography
          variant="h6"
          className="checkoutHeading"
          sx={{ mt: 2, mb: 1, color: darkMode ? "#e1e1e1" : "#323232" }}
        >
          IEO or Listing
        </Typography>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Checkbox
            sx={{
              color:darkMode ? "#e1e1e1" : "#4231C8",
              m: 0,
              p: 0,
              mr: 1,
              "&.Mui-checked": {
                color:darkMode ? "#e1e1e1" : "#4231C8",
              },
            }}
          />
          <Typography variant="body2" className="checkLabel">
            IEO
          </Typography>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",

            mt: "3px",
          }}
        >
          <Checkbox
            sx={{
             color:darkMode ? "#e1e1e1" : "#4231C8",
              m: 0,
              p: 0,
              mr: 1,
              "&.Mui-checked": {
               color:darkMode ? "#e1e1e1" : "#4231C8",
              },
            }}
          />
          <Typography variant="body2" className="checkLabel">
            Listing
          </Typography>
        </Stack>
        <Typography
          variant="h6"
          className="checkoutHeading"
          sx={{ mt: 2, mb: 2, color: darkMode ? "#e1e1e1" : "#323232" }}
        >
          Select your ALT X
        </Typography>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Checkbox
            sx={{
            color:darkMode ? "#e1e1e1" : "#4231C8",
              m: 0,
              p: 0,
              mr: 1,
              "&.Mui-checked": {
              color:darkMode ? "#e1e1e1" : "#4231C8",
              },
            }}
          />
          <Typography variant="body2" className="checkLabel">
            None
          </Typography>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",

            mt: "3px",
          }}
        >
          <Checkbox
            sx={{
            color:darkMode ? "#e1e1e1" : "#4231C8",
              m: 0,
              p: 0,
              mr: 1,
              "&.Mui-checked": {
              color:darkMode ? "#e1e1e1" : "#4231C8",
              },
            }}
          />
          <Typography variant="body2" className="checkLabel">
            Someusername(@someusername)
          </Typography>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",

            mt: "3px",
          }}
        >
          <Checkbox
            sx={{
            color:darkMode ? "#e1e1e1" : "#4231C8",
              m: 0,
              p: 0,
              mr: 1,
              "&.Mui-checked": {
              color:darkMode ? "#e1e1e1" : "#4231C8",
              },
            }}
          />
          <Typography variant="body2" className="checkLabel">
            Someusername(@someusername)
          </Typography>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",

            mt: "3px",
          }}
        >
          <Checkbox
            sx={{
            color:darkMode ? "#e1e1e1" : "#4231C8",
              m: 0,
              p: 0,
              mr: 1,
              "&.Mui-checked": {
              color:darkMode ? "#e1e1e1" : "#4231C8",
              },
            }}
          />
          <Typography variant="body2" className="checkLabel">
            Someusername(@someusername)
          </Typography>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",

            mt: "3px",
          }}
        >
          <Checkbox
            sx={{
            color:darkMode ? "#e1e1e1" : "#4231C8",
              m: 0,
              p: 0,
              mr: 1,
              borderRadius: '4px',
              "&.Mui-checked": {
              color:darkMode ? "#e1e1e1" : "#4231C8",
              },
            }}
          />
          <Typography variant="body2" className="checkLabel">
            Someusername(@someusername)
          </Typography>
        </Stack>
        <Typography
          variant="h6"
          className="checkoutHeading"
          sx={{ mt: 2, mb: 1, color: darkMode ? "#e1e1e1" : "#323232" }}
        >
          I understand that ALT X requires a marketing fee for IEO or
          Listing.*
        </Typography>
        <FormControlLabel
          value="female"
          control={
            <Radio

              sx={{
                boder: "1px solid #999999",
              color:darkMode ? "#e1e1e1" : "#4231C8",
                "&.Mui-checked": {
                color:darkMode ? "#e1e1e1" : "#4231C8",
                },
              }}
            />
          }
          label="I agree"
          className="checkLabel"
        />
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",

            mt: 1,
          }}
        >
          <Button
            variant="contained"
            href="#outlined-buttons"
            className="btnText"
            sx={{
              color: "#ffffff",
              textTransform: "capitalize",
              bgcolor: "#4231C8",
              width: 110,
              margin: "1rem 0",
            }}
          >
            Submit
          </Button>
        </Stack>

      </Container>
    </Layout>
  );
};

export default WithdrawForm;
