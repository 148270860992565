import React from "react";
import Layout from "../layouts/Layout";
import { Container, Stack, Typography } from "@mui/material";

const Fee = () => {
  return (
    <Layout>
      <Container className="paddingSet feeCont manageContainer" sx={{ mt: 10, mb:7, }} >
        <Stack>
          <Typography className="supportTitle">
            Fees
          </Typography>
          <Typography variant="h6" className="fontSet" sx={{ color: "#4231c8", mb: 2,mt:4 }}>
            On Regular Terms:
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#151515", mb: 2, lineHeight: "19.36px", }}
          >
            Alt-X Exchange charges 0.25% transaction fees on all trades of
            digital assets. 
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#151515", mb: 2, lineHeight: "19.36px", }}
          >
            The fees are only charged when your buy/sell
            transactions are completed, and calculated by rounding up to 2
            decimals.
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#151515", mb: 2, lineHeight: "19.36px", }}
          >
            Example: If the fee is 218.5641659, the system will charge
            218.57.
          </Typography>
        </Stack>
      </Container>
    </Layout>
  );
};

export default Fee;
