// FaqData.js
const FaqData = {
  "Getting started at ALT-X Exchange": [
    { label: "ALT-X Exchange Ultimate User Guide"},
    { label: "How to Deposit and Withdraw on ALT-X Exchange"},
    { label: "How to Trade on ALT-X Exchange"},
  ],
  "Account and security": [
    { label: "How to Complete KYC on ALT-X Exchange"},
    { label: "How to Reset Password"},
  ],
  "Wallet": [
    { label: "Missing Deposits and Incorrect Token or Address Information"},
    { label: "ALT-X Exchange's Withdrawal Fee"},
    { label: "How to Solve Withdrawal Issues"},
  ],
};
export default FaqData;