import React, { useContext } from "react";
import Layout from "../../layouts/Layout";
import {
  Box,
  Container,
  FormControl,
  InputBase,
  InputLabel,
  Paper,
  Stack,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import varifyIcon from "../../../assets/verified 1.svg";
import DoneIcon from "@mui/icons-material/Done";
import SearchIcon from "@mui/icons-material/Search";
import rec from "../../../assets/Rectangle 139.jpg";
import styled from "@emotion/styled";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import EmailIcon from "@mui/icons-material/Email";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { DarkModeContext } from "../../../ThemeContext";

const OfficialVariification = () => {
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "0.75px solid #b6b6b6",
    color: "#b6b6b6",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    // marginRight: theme.spacing(2),
    marginRight: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {},
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: 0,
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 0, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(1)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      //   [theme.breakpoints.up("md")]: {
      //     width: "80%",
      //   },
    },
  }));
  const { darkMode } = useContext(DarkModeContext)
  return (
    <Layout>
      <Container className="manageContainer">
        <Stack className="contBottomM contGap" direction="column" alignItems="center" mt={12} mb={3}>
          <Stack
            width={{ md: "60%", xs: "100%" }}
            direction="column"
            alignItems="center"
            gap={3}
          >
            <img src={varifyIcon} alt="s" className="verfiImg" />
            <Typography
              color="#4231C8"
              fontFamily="Roboto"
              textAlign="center"
              fontWeight={700}
              className="officialVerf"
            >
              Alt-X
              <span style={{ color: darkMode ? "#e1e1e1" : "#424242", marginLeft: 8, fontWeight: 600 }}>Official Verification</span>
            </Typography>
            <Box bgcolor={darkMode ? "#323232" : "#EFEFEF"} padding={3} mb={2} sx={{ maxWidth: 667 }}>
              <Stack
                direction="row"
                alignItems="start"
                justifyContent="center"
                gap={1}
                mb={2}
              >
                <DoneIcon color="#4231C8" fontSize="small" />
                <Typography className="verifP" variant="body1" color={darkMode ? "#e1e1e1" : "#151515"} fontWeight={400}>
                  To verify whether an account is officially affiliated with ALT-X Global, please enter it in the search bar.
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="start"
                justifyContent="center"
                gap={1}
              >
                <DoneIcon color={darkMode ? "#e1e1e1" : "#151515"} fontSize="small" />
                <Typography className="verifP" variant="body1" color={darkMode ? "#e1e1e1" : "#151515"} fontWeight={400}>
                  Imitation is the greatest form of flattery, and ALT-X Global wants to keep you safe from fraudsters and scammers.
                </Typography>
              </Stack>
            </Box>
            <Search style={{ maxWidth: 667 }}>
              <SearchIconWrapper>
                <SearchIcon color="#00AB2C" />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                className="verifcationSearch"
              />
            </Search>
            <Paper

              sx={{
                maxWidth: 667,
                backgroundSize: "cover",
                width: "100%",
                height: "100%",
                mb: 8,
                boxShadow: "none",
                borderBottom: "0.75px solid  #D9D9D9",
                backgroundColor: darkMode && "#282828"
              }}
            >

              <Stack direction="column" alignItems="center">
                <Box width="95%">
                  <Typography
                    style={{ color: darkMode && "#e1e1e1" }}
                    className="fontManage"
                    variant="h6"
                    fontSize="15px"
                    fontWeight={700}
                    color="#4231C8"
                    padding={2}
                    mt={3}
                  >
                    Verifiable sources
                  </Typography>
                </Box>

                <Box sx={{ width: "90%", borderBottom: "0.75px solid #D9D9D9", height: "2px", alignSelf: "center" }}></Box>

              </Stack>
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="start"
                mb={2}
                mt={3}
                className="linkDiv"
              >
                <Stack direction="column" gap={2}><Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  gap={1}
                >
                  <AccountBalanceWalletIcon sx={{color : darkMode && "#999"}} />
                  <Typography sx={{ color: darkMode && "#999" }} fontSize="12px">Wallet address</Typography>
                </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    gap={1}
                  >
                    <EmailIcon sx={{color : darkMode && "#999"}}/>
                    <Typography sx={{ color: darkMode && "#999" }} fontSize="12px">Email address</Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    gap={1}
                  >
                    <FaFacebookF color = {darkMode && "#999"} className="facebookIcon" />
                    <Typography sx={{ color: darkMode && "#999" }} fontSize="12px">Facebook</Typography>
                  </Stack>
                </Stack>
                <Stack direction="column" gap={2}><Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  gap={1}

                >
                  <FaLinkedinIn color = {darkMode && "#999"}/>
                  <Typography sx={{ color: darkMode && "#999" }} fontSize="12px">Linkdin account</Typography>
                </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    gap={1}
                  >
                    <FaTelegramPlane color = {darkMode && "#999"}/>
                    <Typography sx={{ color: darkMode && "#999" }}  fontSize="12px">Telegram account</Typography>
                  </Stack></Stack>

              </Stack>

            </Paper>

          </Stack>
        </Stack>
      </Container>
    </Layout>
  );
};

export default OfficialVariification;
