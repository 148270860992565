import React, { useContext } from 'react'
import { DarkModeContext } from '../../../../../ThemeContext'

const DepositWithdrawGuide = () => {
    const { darkMode } = useContext(DarkModeContext)
    return (
        <>
            <h2 style={{ color: darkMode && "#e1e1e1" }} className="guideHeading">How to Deposit and Withdraw on ALT-X Exchange</h2>
            <p style={{ color: darkMode && "#e1e1e1" }} className='guidePara'>
                ALT-X Exchange offers a seamless and secure process for
                depositing and withdrawing funds, allowing you to easily manage
                your digital assets. Whether you're looking to deposit funds to start
                trading or withdraw profits from your account, follow these simple
                steps:
            </p>
           <h3 style={{ color: darkMode ? "#e1e1e1" : "#4231c8", fontSize : 20, fontWeight : "600", marginBottom : 20 }} className="guideSubHeading">
                Depositing Funds:
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                <span style={{ fontWeight: "600", color: "#1155CC"}}>Navigate to Your Wallet: </span>
                Log in to your ALT-X Exchange account
                and navigate to the wallet section. Here, you'll find options to
                deposit various cryptocurrencies.
            </p>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                <span style={{ fontWeight: "600", color: "#1155CC"}}>Select the Desired Cryptocurrency:</span>
                Choose the cryptocurrency
                you wish to deposit from the list of supported assets.
            </p>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                <span style={{ fontWeight: "600", color: "#1155CC"}}>Generate Deposit Address: </span>
                Click on the "Deposit" button next to
                your chosen cryptocurrency. ALT-X Exchange will generate a
                unique deposit address for you.
            </p>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                <span style={{ fontWeight: "600", color: "#1155CC"}}>Transfer Funds: </span>
                Use the provided deposit address to transfer
                funds from your external wallet or exchange to your ALT-X
                Exchange account. You can also scan the QR code if you're using a mobile wallet.
            </p>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                <span style={{ fontWeight: "600", color: "#1155CC"}}>Confirm Deposit:</span>
                Once the transaction is complete, your funds will
                be credited to your ALT-X Exchange account. Depending on
                network congestion and confirmation times, it may take some time
                for your deposit to reflect in your account.
            </p>
           <h3 style={{ color: darkMode ? "#e1e1e1" : "#4231c8", fontSize : 20, fontWeight : "600", marginBottom : 20 }} className="guideSubHeading">
                Withdrawing Funds:
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                <span style={{ fontWeight: "600", color: "#1155CC"}}>Access Withdrawal Section:</span>
                To initiate a withdrawal, go to the
                "Withdraw" section in your ALT-X Exchange account.
            </p>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                <span style={{ fontWeight: "600", color: "#1155CC"}}>Select Cryptocurrency:</span>
                Choose the cryptocurrency you wish to
                withdraw from the list of supported assets.
            </p>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                <span style={{ fontWeight: "600", color: "#1155CC"}}>Enter Withdrawal Details:</span>
                Enter the withdrawal amount and the
                destination wallet address where you want to send your funds.
                Double-check the wallet address to ensure its accuracy.
            </p>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                <span style={{ fontWeight: "600", color: "#1155CC"}}>Review and Confirm:</span>
                Review the withdrawal details, including the
                amount and destination address, to ensure everything is correct.
                Once you're satisfied, confirm the withdrawal request.
            </p>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                <span style={{ fontWeight: "600", color: "#1155CC"}}>Verify Email or Two-Factor Authentication (2FA):</span>
                Depending on
                your account settings, you may need to verify the withdrawal
                request via email confirmation or two-factor authentication (2FA).
            </p>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                <span style={{ fontWeight: "600", color: "#1155CC"}}>Complete Withdrawal:</span>
                After confirming the withdrawal request and
                any additional security steps, your funds will be processed and sent
                to the specified wallet address. Withdrawal times may vary
                depending on network congestion and blockchain confirmations.
            </p>
           <h3 style={{ color: darkMode ? "#e1e1e1" : "#4231c8", fontSize : 20, fontWeight : "600", marginBottom : 20 }} className="guideSubHeading">
                Important Tips:
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                <span style={{ fontWeight: "600", color: "#1155CC"}}>Double-Check Addresses:</span>
                Always ensure that the wallet
                addresses you provide for deposits and withdrawals are accurate to
                avoid any loss of funds.
            </p>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                <span style={{ fontWeight: "600", color: "#1155CC"}}>Test Transactions:</span>
                Before making large withdrawals, consider
                conducting a test transaction with a small amount to verify the
                accuracy of the wallet address and the smoothness of the
                withdrawal process.
            </p>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                <span style={{ fontWeight: "600", color: "#1155CC"}}>Stay Informed:</span>
                Keep an eye on your email notifications and
                account activity for updates on deposit and withdrawal statuses.
                ALT-X Exchange provides real-time updates on transaction
                progress to keep you informed every step of the way.
            </p>

        </>
    )
}

export default DepositWithdrawGuide