import React, { useState, useEffect, useContext } from "react";
import arrowUp from "../../../assets/arrowUp0.svg";
import arrowDown from "../../../assets/down.svg";
import { column7, tradeFeedData } from "../../constants/Data";
import { DarkModeContext } from "../../../ThemeContext";

export default function TradeFeed({ upperDataObj }) {
  const [tradeData, settradeData] = useState([])

  const fetchMarketData = async () => {
    try {

      const response = await fetch(`https://exchangeic.exchange/api/v1/lib/trade-data?symbol=${upperDataObj.symbol}`, {


        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      settradeData(result?.data?.trades)

      console.log('fetchMarketData in trade date', result); // Process your result here
    } catch (error) {
      console.log('Error fetching market data:', error);
    }
  };


  useEffect(() => {
    fetchMarketData();
  }, [upperDataObj]);
  const { darkMode } = useContext(DarkModeContext)
  return (
    <>
      <div className="bitCoinPriceDiv">
        <div className="priceTxTDiv priceTxTDivLow">
          <p style={{ color: darkMode ? "" : "#232323" }}>Volume</p>
          <h6 style={{ color: darkMode ? "" : "#232323" }}>{upperDataObj.volume}</h6>
        </div>
        <div className="priceTxTDiv priceTxTDivLow">
          <p style={{ color: darkMode ? "" : "#232323" }}>Amount({upperDataObj.from})</p>
          <h6 style={{ color: darkMode ? "" : "#232323" }}>{upperDataObj.amount}</h6>
        </div>
      </div>
      <div
        style={{
          backgroundColor: darkMode ? "#282828" : "#fff",
          border: darkMode ? "1px solid #4b4b4b80" : "1px solid #b6b6b680"
        }}
        className="exchange-right-container">
        <div className="balanceTableDiv mt-0">
          <h6 style={{ color: darkMode ? "" : "#30258B", paddingTop: 8, paddingLeft: 12 }} className="balanceTxt px-2">Trade feed</h6>
          <div style={{ backgroundColor: darkMode ? "" : "#E9ECF1" }} className="row row-style mx-0">
            {column7?.map((item) => {
              return (
                <div className="col-4 d-flex flex-column align-items-center">
                  {item}
                </div>
              );
            })}
          </div>
          <div className="orderBookTr">
            {tradeData.map((order, index) => (
              <div className="row mx-0 orderBookRow" key={index}>
                <div className="col-4">
                  <div className="tradeFeedTxt">
                    {order.type === "buy" ? (
                      <img src={arrowUp} alt="" />
                    ) : (
                      <img src={arrowDown} alt="" />
                    )}
                    <p className={order.type === "buy" ? "priceP lowPrice" : "priceP highPrice"}>
                      {order.price}
                    </p>
                  </div>
                </div>
                <div className="col-4">
                  <p className="priceP" style={{ whiteSpace: "nowrap", overFlow: "hidden", textOverflow: "ellipsis" }}>{order.amount}</p>
                </div>
                <div className="col-4 p-0">
                  <p className="priceP" style={{ whiteSpace: "nowrap", overFlow: "hidden", textOverflow: "ellipsis" }}>
                    {new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }).format(new Date(order.order_time))}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
