import React, { useState } from "react";
import { ApiTabs } from "../../constants/Data";
import Layout from "../../layouts/Layout";
import { ApiManagement } from "./apiManagemnt";
import { AddressManagement } from "./addressmanagement";
import { Tabs } from "./Tabs";

const Api = () => {
  // States Define Here
  const [apiTabs, setApiTabs] = useState(ApiTabs);
  const [selectedTab, setSelectedTab] = useState(0);

  // Tab selection method define here
  const selectionMethod = (data) => (setData) => (setValue) => (item) => () => {
    let mappedOut = data?.map((_item, index) => {
      if (index === item) {
        setValue(index);
        return { ..._item, selected: true };
      }
      return { ..._item, selected: false };
    });
    setData(mappedOut);
  };

  // Render Method Here
  return (
    <Layout>
      <div className="api-container">
        <div className="mb-3">
          <span className="api-title">API</span>
        </div>
        <Tabs
        className="apiTabDiv"
          apiTabs={apiTabs}
          tabSelection={selectionMethod(apiTabs)(setApiTabs)(setSelectedTab)}
        />
        {selectedTab === 0 ? (
          <ApiManagement apiTabs={apiTabs} />
        ) : (
          <AddressManagement />
        )}
      </div>
    </Layout>
  );
};

export default Api;
