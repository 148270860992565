import React, { useContext } from 'react'
import { DarkModeContext } from '../../../../../ThemeContext'

const CompleteKYC = () => {
  const { darkMode } = useContext(DarkModeContext)
  return (
    <>
      <h2 style={{ color: darkMode && "#e1e1e1" }} className="guideHeading">How to Complete KYC on ALT-X Exchange</h2>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        What is KYC?
      </h3>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        KYC, or Know Your Customer, is a process used by businesses,
        including financial institutions and cryptocurrency exchanges like
        ALT-X, to verify the identity of their customers. The purpose of KYC
        is to prevent identity theft, fraud, money laundering, and other illicit
        activities.

        Here's a step-by-step guide to help you complete the KYC process
        smoothly:
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        1. Log In to Your Account:
      </h3>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        Start by logging in to your ALT-X Exchange account using your
        credentials. If you don't have an account yet, you'll need to sign up
        for one.
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        2. Navigate to KYC Verification:
      </h3>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        Once logged in, navigate to the "Account" and "Settings" section of
        the platform. Look for the option to complete KYC verification,
        labeled as "Verification".
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        3. Provide Personal Information:
      </h3>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        Follow the prompts to provide the required personal information for
        KYC verification.
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        4. Upload Identification Documents:
      </h3>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        Prepare clear and legible copies of your identification documents,
        such as a government-issued photo ID (e.g., passport, driver's
        license, or national ID card). Ensure that the documents meet the
        platform's requirements for format and quality.
        Upload the required identification documents through the KYC
        verification interface.
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        5. Verify Your Identity:
      </h3>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        Once you've uploaded the necessary documents, submit your
        verification request for review. The ALT-X Exchange team will
        typically review your documents within a reasonable timeframe.
        Be patient during the verification process, as it may take some time
        for your documents to be reviewed and approved.
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        6. Await Confirmation:
      </h3>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        After submitting your verification request, wait for confirmation from
        ALT-X Exchange. You may receive an email notification or see a
        status update on your account dashboard once your KYC
        verification is complete.
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        7. Check Verification Status:
      </h3>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        Periodically check the status of your KYC verification request on the
        platform. If any additional information or documents are required,
        follow the instructions provided by ALT-X Exchange to complete the
        process.
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        8. Enjoy Enhanced Features:
      </h3>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        Once your KYC verification is approved, you'll unlock access to
        enhanced features and higher account limits on ALT-X Exchange.
        This may include increased deposit and withdrawal limits, as well as
        eligibility for certain trading pairs and services.
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        9. Keep Your Information Up to Date:
      </h3>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        Ensure that your personal information remains accurate and up to
        date on ALT-X Exchange. If there are any changes to your
        information, such as a change of address or legal name, update
        your profile accordingly.
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        10. Contact Support if Needed:
      </h3>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        If you encounter any difficulties or have questions about the KYC
        verification process, don't hesitate to contact ALT-X Exchange's
        customer support team for assistance. They'll be happy to guide
        you through the process and address any concerns you may have.
        By following these steps and providing accurate information and
        documentation, you can successfully complete the KYC verification
        process on ALT-X Exchange and enjoy access to a wider range of
        features and services.
      </p>
    </>
  )
}

export default CompleteKYC