// FaqDetailPage.js
import React, { useContext } from "react";
import Layout from "../../layouts/Layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "../../../assets/searchIcon.svg";
import SearchIcon3 from "../../../assets/searchIcon3.svg";
import UserGuide from "./FaqDetails/faqDiv1/UserGuide"
import DepositWithdrawGuide from "./FaqDetails/faqDiv1/DepositWithdrawGuide";
import HowToTrade from "./FaqDetails/faqDiv1/HowToTrade";
import CompleteKYC from "./FaqDetails/faqDiv2/CompleteKYC";
import ResetPassword from "./FaqDetails/faqDiv2/ResetPassword";
import MissingDeposits from "./FaqDetails/faqDiv4/MissingDeposits";
import WithdrawalFee from "./FaqDetails/faqDiv4/WithdrawalFee";
import WithdrawalIssue from "./FaqDetails/faqDiv4/WithdrawalIssue";
import { DarkModeContext } from "../../../ThemeContext";
import {
  Container,
} from "@mui/material";


const FaqDetailPage = () => {
  const location = useLocation();
  console.log("location.state : ", location.state)
  const { name: receivedName, labelTxt: receivedLabelTxt, sidebarName } = location.state || {};

  console.log("receivedName : ", receivedName)
  console.log("receivedLabelTxt : ", receivedLabelTxt)
  // const sidebarName = location.state?.sidebarName || null;
  console.log("sidebarName : ", sidebarName)
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const sidebarHeadings = [
    "Getting started at ALT-X Exchange",
    "Account and security",
    // "OTP app & security",
    "Wallet",
    // "Buy Crypto",
    // "Alt-X Exclusive",
    // "IEO",
    // "Airdrops",
    // "Global Learn & Earn",
    // "Membership levels",
    // "API",
  ];

  let activeComponent = null;
  activeComponent = receivedName

  // Map label values to their corresponding components
  const componentMap = {
    'ALT-X Exchange Ultimate User Guide': <UserGuide />,
    'How to Deposit and Withdraw on ALT-X Exchange': <DepositWithdrawGuide />,
    'How to Trade on ALT-X Exchange': <HowToTrade />,
    'How to Complete KYC on ALT-X Exchange': <CompleteKYC />,
    'How to Reset Password': <ResetPassword />,
    'Missing Deposits and Incorrect Token or Address Information': <MissingDeposits />,
    "ALT-X Exchange's Withdrawal Fee": <WithdrawalFee />,
    'How to Solve Withdrawal Issues': <WithdrawalIssue />
  };

  // Retrieve the component based on the label
  activeComponent = componentMap[receivedLabelTxt];
  const { darkMode } = useContext(DarkModeContext)
  return (
    <Layout>
      <Container className="mainContentFaq manageContainer">
        <div className="mainContentDiv">
        <h4 style={{ color: darkMode ? "#e1e1e1" : "#4231c8" }} className="supportTitle orderTitle">FAQs</h4>
          <div className="faqMainContentSection">
            <div className="faqSideBarMainDiv faqSideBarMainDivTwo">
              <div className="searchDiv searchDivTwo">
              <input type="text" name="" id="" style={{ backgroundColor: darkMode && "#282828", borderColor: darkMode && "#646464" }} />
              <img src={darkMode ? SearchIcon3 : SearchIcon} alt="" className="searchIconImg" />
              </div>
              <div className="sidebarOptionDiv">
                {sidebarHeadings.map((heading, index) => (
                  <div
                    key={`faqDiv${index + 1}`}
                    style={{
                      color: sidebarName === ("faqDiv" + (index + 1)) ?
                        (darkMode ? "#e1e1e1" : "#4231c8")
                        :
                        (darkMode ? "#999" : "#7b7b7b")
                    }}
                    className={`sideContentLi ${sidebarName === `faqDiv${index + 1}` ? 'sideContentActiveLi' : ""
                      }`}
                      onClick={() =>
                        navigate("/faqs",{
                          state: { tab : "faqDiv" + (index + 1)},
                        })
                      }
                  >
                    <h6>{heading}</h6>
                  </div>
                ))}
              </div>
            </div>
            <div className="faqSideContent faqSideContent02">
              <div className="faqLinkDiv">
                <Link to="#" style={{ color: darkMode && "#e1e1e1" }} className="faqLink001" onClick={goBack}>
                  FAQ
                </Link>
                <p style={{ paddingLeft: 10, paddingRight: 10, color: darkMode && "#e1e1e1" }}> &gt; </p>
                {/* <Link to="#" style={{ color: darkMode && "#e1e1e1" }} className="faqLink001" onClick={goBack}>
                  {receivedName}
                </Link>
                <p style={{ color: darkMode && "#e1e1e1", paddingRight: 10, paddingLeft: 10 }}> &gt; </p> */}
                <p style={{ color: darkMode && "#e1e1e1" }}>{receivedLabelTxt}</p>
              </div>
              {activeComponent}
            </div>
          </div>
        </div>

      </Container>
    </Layout >
  );
};

export default FaqDetailPage;
