import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

import Layout from "../../layouts/Layout";
import { Link, useNavigate } from "react-router-dom";
import patrnLog from "../../../assets/patrnLog.png";

import { useRegisterUserMutation } from "../../../services/userAuthApi";
import Swal from 'sweetalert2';
import { DarkModeContext } from "../../../ThemeContext";
import axios from "axios"
import url from "../../../config";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Visibility, VisibilityOff } from "@mui/icons-material";



const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 6,
  width: 19,
  color: "#4231C8",
  height: 19,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px #4231C8"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto #4231C8",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#4231C8",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 19,
    height: 19,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}





const Signup = () => {




  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",

      border: "1px solid #4231C8",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));


  // Sign Up Form LOgic 
  const [data, { isLoading, isError }] = useRegisterUserMutation()
  const [referCode, setReferCode] = useState('btn')
  const [email, setemail] = useState('')
  const [password, setpassword] = useState('')
  const [confirmPassword, setconfirmPassword] = useState('')
  const [userReferCode, setuserReferCode] = useState('')
  const [errorText, setErrorText] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [signuperror, setSignupError] = useState();
  const [loading, SetLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const handleTogglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const [agreeTerms, setAgreeTerms] = useState(false);

  const handleCheckboxChange = () => {
    setAgreeTerms(!agreeTerms); // Toggle the state between true and false
    console.log('Checkbox clicked. Current state:', !agreeTerms);
  };
  const isValidEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }
  const navigate = useNavigate()


  const isStrongPassword = (password) => {
    // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return re.test(password);
  }
  const ReferCodeHandler = () => {
    setReferCode('input')
  }
  const emailHandler = (value) => {
    setemail(value)
  }
  const passwordHandler = (value) => {
    setpassword(value)
  }
  const confirmPasswordHandler = (value) => {
    setconfirmPassword(value)
  }
  const userReferCodeHandler = (value) => {
    setuserReferCode(value)
  }
  // Handler to clear email error on blur
  const clearEmailError = () => {
    setEmailError('');
  };

  // Handler to clear password error on blur
  const clearPasswordError = () => {
    setPasswordError('');
  };

  // Handler to clear confirm password error on blur
  const clearConfirmPasswordError = () => {
    setConfirmPasswordError('');
  };
  const handleSignupError = (errorMessage) => {
    setSignupError(errorMessage);
    setTimeout(() => {
      setSignupError('');
    }, 5000);
  };



  const signUpFormHandler = async () => {
    let isValid = true;
    if (!email.trim() || !password.trim() || !confirmPassword.trim()) {
      handleSignupError("All Fields are Required !")      // Run your desired function here
    }
    if (!email.trim()) {
      setEmailError('Email is required.');
      isValid = false;
    } else if (!isValidEmail(email)) {
      setEmailError('Invalid email format.');
      isValid = false;
    } else {
      setEmailError('');
    }

    // Password validation
    if (!password.trim()) {
      setPasswordError('Password is required.');
      isValid = false;
    } else if (!isStrongPassword(password)) {
      setPasswordError('Password is not strong enough.');
      isValid = false;
    } else {
      setPasswordError('');
    }

    // Confirm password validation
    if (!confirmPassword.trim()) {
      setConfirmPasswordError('Confirm password is required.');
      isValid = false;
    } else if (password !== confirmPassword) {
      setConfirmPasswordError('Confirm password does not match.');
      isValid = false;
    } else {
      setConfirmPasswordError('');
    }

    // Clear errors after 5 seconds
    if (!isValid) {
      setTimeout(() => {
        setEmailError('');
        setPasswordError('');
        setConfirmPasswordError('');
      }, 5000);
    }

    if (isValid) {
      SetLoading(true);

      console.log('Form Submitted');
      const formData = {
        email: email,
        password: password,
      }

      try {

        console.log(formData, "this is formData")
        const response = await axios.post(`${url}api/v1/user-auth/signup/`, formData, {

        });

        console.log(response);
        console.log(email, " this is email");
        SetLoading(false);
        if (response.status == 201 || response.status == 200) {
          navigate('/varify/code', { state: { email: email, from: "signup" } });

        }
        // Handle success
      } catch (error) {
        SetLoading(false);

        handleSignupError("Error While Signing Up !")

        console.log(error);

        //  setEmailError(error)
        // Handle error
      }
      SetLoading(false);


    }
  }

  const errorStyle = {};
  const { darkMode } = useContext(DarkModeContext)
  return (
    <Layout>
      <Stack sx={{ bgcolor: darkMode ? "#282828" : "#FAFAFA", pb: 6 }}>
        <Container className="manageContainer">
          <Grid container spacing={2} xs={12}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Stack direction="column" sx={{ paddingTop: { xs: 13, md: 0 }, width: "100%" }}>
                <Typography
                  variant="h4"
                  sx={{ color: darkMode ? "#fff" : "#4231C8", fontWeight: 800, "mt": 16, "mb": 2 }}
                >
                  Sign Up
                </Typography>
                {signuperror && <Typography color="error" className="text-center mb-9">{signuperror}</Typography>}


                <div className="formInputDivs my-4">
                  <label htmlFor="email" className="labelTxt mb-2"
                    style={{ color: darkMode ? "#999" : "#333" }}
                  >
                    Email
                  </label>
                  <input
                    className="form-control textInput formInputs"
                    id="email"
                    onChange={(e) => { emailHandler(e.target.value) }}
                    onBlur={clearEmailError}
                    value={email}
                    // style={emailError ? errorStyle : null}
                    style={{ color: darkMode && "#999", backgroundColor: darkMode && "#282828", border: emailError && '1px solid red' }}
                  />
                  {emailError && <Typography color="error">{emailError}</Typography>}
                </div>

                <div className="formInputDivs mb-4">
                  <label htmlFor="password" className="labelTxt mb-2"
                    style={{ color: darkMode ? "#999" : "#333" }}
                  >
                    Password
                  </label>
                  <div style={{ position: 'relative', width: 420 }}>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control textInput formInputs"
                    id="password"
                    onChange={(e) => { passwordHandler(e.target.value) }}
                    onBlur={clearPasswordError}
                    value={password}
                    style={{ color: darkMode && "#999", backgroundColor: darkMode && "#282828", border: emailError && '1px solid red' }}
                    />
                    <button onClick={togglePasswordVisibility}
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        backgroundColor: 'transparent',
                        border: 'none',
                        color:  "gray"
                      }}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </button>
                    </div>
                  {passwordError && <Typography color="error">{passwordError}</Typography>}
                </div>

                <div className="formInputDivs mb-4">
                  <label htmlFor="email" className="labelTxt mb-2"
                    style={{ color: darkMode ? "#999" : "#333" }}
                  >
                    Confirm Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control textInput formInputs"
                    id="email"
                    onChange={(e) => { confirmPasswordHandler(e.target.value) }}
                    onBlur={clearConfirmPasswordError}
                    onTouchMoveCapture={clearConfirmPasswordError}
                    value={confirmPassword}
                    style={{ color: darkMode && "#999", backgroundColor: darkMode && "#282828", border: emailError && '1px solid red' }}
                  />
                  {confirmPasswordError && <Typography color="error">{confirmPasswordError}</Typography>}
                </div>


                <Box sx={{ mb: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <div className="mb-2 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input formCheck"
                      id="exampleCheck1"
                      checked={agreeTerms} // Set checked based on state
                      onChange={handleCheckboxChange} // Call handleCheckboxChange when checkbox changes
                    />
                    <label
                      className="form-check-label checkLabel"
                      htmlFor="exampleCheck1"
                    >
                      I agree to the terms and conditions.
                    </label>
                  </div>
                </Box>

                <span style={{ color: "red" }}>
                  {errorText && <div>{errorText}</div>}
                </span>
                <Button
                  sx={{
                    height: 50,
                    color: "white",
                    bgcolor: loading ? "#ccc" : (agreeTerms ? "#4231C8" : "#ccc"), // Change background color based on loading and agreeTerms state
                    "&:hover": { bgcolor: loading ? "#ccc" : (agreeTerms ? "#4231C8" : "#ccc") }, // Change hover color based on loading and agreeTerms state
                    width: "420px"
                  }}
                  variant="contained"
                  className="formBtn"
                  onClick={!loading && agreeTerms ? signUpFormHandler : null} // Disable onClick handler when loading is true or agreeTerms is false
                  disabled={!agreeTerms || loading} // Disable the button until terms are agreed or loading is true
                >
                  {loading ? 'Loading...' : 'Sign Up'} {/* Change button text based on loading state */}
                </Button>


                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mt: 6

                  }}
                  className="signLink"
                >
                  <Typography variant="body1" sx={{ color: "#999999" }}>
                    Already have an account?{" "}
                  </Typography>
                  <Link to="/login" className="signLinkA">
                    <Typography
                      variant="body1"
                      sx={{ textDecoration: "underline", color: darkMode ? "#e1e1e1" : "#4231c8" }}
                    >

                      Sign In
                    </Typography>
                  </Link>
                </Box>

              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ position: "relative", paddingTop: { md: 20, sm: 5, xs: 5 } }}
              >


              </Stack>
            </Grid>
            <Stack direction="row" >
              <img src={patrnLog} alt="s" className="signupPatern" />

            </Stack>
          </Grid>
        </Container>
      </Stack>
    </Layout>
  )
}

export default Signup