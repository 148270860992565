import React, { useState } from "react";
import { CustomTable } from "../../../layouts/table/CustomTable";
import { addressData, addressTableColumns } from "../../../constants/Data";
import { AddAddress } from "./AddAddress";

export const AddressManagement = () => {
  const [showData, setShowData] = useState(false);

  const handleAddMethod = () => {
    setShowData(!showData);
  };
  return (
    <div className="address-div">
      <CustomTable
        columns={addressTableColumns}
        data={showData ? addressData : []}
        address={true}
        show={false}
      />
      {!showData ? <AddAddress onClick={handleAddMethod} /> : null}
    </div>
  );
};
