import React from "react";

export const AddAddress = ({ onClick }) => {
  return (
    <div className="add-address-div">
      <span className="add-address-placeholder">{"No withdrawal address is registered."}</span>
      <div className="add-address-btn" onClick={onClick}>
        Add
      </div>
    </div>
  );
};
