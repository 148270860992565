import React, { useContext } from 'react'
import { DarkModeContext } from '../../../../../ThemeContext'

const HowToTrade = () => {
    const { darkMode } = useContext(DarkModeContext)
    return (
        <>
            <h2 style={{ color: darkMode && "#e1e1e1" }} className="guideHeading">How to Trade on ALT-X Exchange</h2>
            <h3 style={{ color: darkMode ? "#e1e1e1" : "#1155CC" }} className="guideSubHeading">
                1. Log In and Select "Exchange":
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                Log in to your ALT-X Exchange account using your credentials.
                Once logged in, navigate to the "Exchange" section of the platform
                to access the trading interface.
            </p>
            <h3 style={{ color: darkMode ? "#e1e1e1" : "#1155CC" }} className="guideSubHeading">
                2. Fund Your Account:
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                Ensure your ALT-X Exchange account is funded with the
                cryptocurrency you wish to trade. Visit the "Wallet" section to
                deposit funds into your account if necessary.
            </p>
            <h3 style={{ color: darkMode ? "#e1e1e1" : "#1155CC" }} className="guideSubHeading">
                3. Explore Trading Pairs:
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                Within the "Exchange" tab, explore the available trading pairs listed
                on the platform. ALT-X Exchange offers a wide range of
                cryptocurrency pairs for trading.
            </p>
            <h3 style={{ color: darkMode ? "#e1e1e1" : "#1155CC" }} className="guideSubHeading">
                4. Choose a Trading Pair:
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                Select the trading pair you're interested in from the list of available
                options. For example, you may choose BTC/USD, ETH/BTC, or any
                other pairing that suits your trading strategy.
            </p>
            <h3 style={{ color: darkMode ? "#e1e1e1" : "#1155CC" }} className="guideSubHeading">
                5. Place Your Order:
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                Decide whether you want to place a buy order or a sell order based
                on your market analysis and trading strategy. Enter the amount of
                cryptocurrency you wish to buy or sell and set your desired price.
                Review your order details and confirm the order to execute the
                trade.
            </p>
            <h3 style={{ color: darkMode ? "#e1e1e1" : "#1155CC" }} className="guideSubHeading">
                6. Monitor Your Trades:
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                Keep track of your trades in real-time by monitoring the "Orders" or
                "Trade History" section of the platform. Here, you can view the
                status of your orders and track their execution.
            </p>
            <h3 style={{ color: darkMode ? "#e1e1e1" : "#1155CC" }} className="guideSubHeading">
                7. Consider Advanced Trading Options:
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                Explore advanced trading options such as limit orders, market
                orders, stop-loss orders, and take-profit orders to enhance your
                trading strategy and manage risk effectively.
            </p>
            <h3 style={{ color: darkMode ? "#e1e1e1" : "#1155CC" }} className="guideSubHeading">
                8. Stay Informed:
            </h3>
            <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
                Stay informed about market trends, news, and developments in the
                cryptocurrency space. ALT-X Exchange provides comprehensive
                market data, charts, and analysis tools to help you make informed
                trading decisions.
                Regularly review your trading strategy and adapt it based on
                evolving market conditions and your trading objectives. Continuous
                learning and adjustment are essential for success in cryptocurrency
                trading.
            </p>
        </>
    )
}

export default HowToTrade