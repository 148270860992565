import React, { useContext, useRef } from "react";
import Layout from "../layouts/Layout";

import {
  Box,
  Button,
  Container,
  Grid,
  Slide,
  Stack,
  Tabs,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
// import mobile from "../../assets/iPhone 14 Mockup Vol 1 02 1.png";
import altVideo from "../../assets/video/ALT-X VIDEO.mp4"
import ecips from "../../assets/exlipPattern.png";
import eclips2 from "../../assets/eclips2.png";
import card1 from "../../assets/Group 480954301.png";
import card2 from "../../assets/Group 1310.png";
import card3 from "../../assets/Group 48095430.png";
import group1307 from "../../assets/Group 1307.svg";

import Eclips7 from "../../assets/Ellipse 7.svg";
import Eclips8 from "../../assets/Ellipse 8.svg";
import Eclips9 from "../../assets/Ellipse 9.svg";
import Eclips10 from "../../assets/Ellipse 13.svg";
import TableLayout from "../layouts/TableLayout";
import s1 from "../../assets/Group 48095431.png";
import s2 from "../../assets/Group 48095432.png";
import lock from "../../assets/lock 2.svg";
import globeImg from "../../assets/globeImg.png";
import setting from "../../assets/admin 2.svg";
import phone from "../../assets/phone-call.png";
import admin from "../../assets/admin.png";
import wallet from "../../assets/wallet 2.svg";
import apple from "../../assets/apple.svg";
import playstore from "../../assets/playstore.png";
import img from "../../assets/img.svg";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import next from "../../assets/next.svg";
import prev from "../../assets/prev.svg";
import vector1 from "../../assets/vector1.png";
import vector2 from "../../assets/vector2.png";
import vector3 from "../../assets/vector3.png";
import vector4 from "../../assets/vector4.png";
import scan from "../../assets/scan.png";
import SortMarket from "../SortMarket";
import { DarkModeContext } from "../../ThemeContext";
import { getToken } from "../../services/LocalStorageService";
import heroImg from '../../assets/heroMockup.png'


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (

    <div
      style={{ ...style, display: "block", position: "absolute", right: 12 }}
      className={className}
      // style={{ display: "block", }}
      onClick={onClick}
    >
      <img src={next} alt="s" className="sliddd" style={{ position: "absolute", left: 0 }} />
    </div>

  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", position: "absolute", left: 12, zIndex : 20 }}
      onClick={onClick}
    >
      <img src={prev} alt="s" className="sliddd" style={{ position: "absolute", left: 0 }} />
    </div>
  );
}
const Home = () => {
  const access_token = getToken();
  console.log(access_token, 'tokens')

  const settings = {
    // arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1368,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const videoRef = useRef(null);
  const handleEnded = () => {
    videoRef.current.currentTime = 0;
    videoRef.current.play();
  };

  const { darkMode } = useContext(DarkModeContext)
  return (
    <Layout>
      <Stack direction="column" style={{ backgroundColor: darkMode ? "#1E1E1E" : "#FFF" }}>
        {/* Banner */}
        <Stack sx={{ bgcolor: darkMode ? "#161616" : "#F7F7F7", paddingBottom: 0 }}>
          <Container id="narrowContainer">
            <Grid container spacing={2} xs={12} style={{ marginTop: 40 }} id="narrowContainer">
              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: "flex", alignItems: "center", }}
              >
                <Stack
                  direction="column"
                  spacing={5}
                  sx={{ paddingTop: { xs: 15, md: 2 } }}
                  className="ps-md-5 ps-0 sectionOneItem"
                >
                  <h1 className="homeHeading01" style={{ height: "153px" }}><span style={{ color: darkMode ? "white" : "#1E1E1E" }}>Your One-stop</span> <span>Cryptocurrency </span><br />
                    <span style={{ color: darkMode ? "white" : "#1E1E1E" }}>Exchange</span>
                  </h1>
                  <Typography
                    variant="body1" className="sectionOneP"
                    sx={{ color: "#7b7b7b", width: { xs: "100%", md: "70%" } }}
                    style={{ fontWeight: 400, size: "21.33px", lineHeight: "31.15px", marginTop: "20px" }}
                  >
                    Trade, invest, and hold over 1500 cryptocurrencies <br /> securely, all in one place
                  </Typography>
                  {!access_token || access_token.access_token === null && (
                    <Button
                      component={Link}
                      to="/signup"
                      className="btnText btnMarginSet"
                      sx={{
                        width: 170,
                        height: 52,
                        borderRadius: 1,
                        textTransform: "capitalize",
                        bgcolor: "#4231C8",
                        "&:hover": { bgcolor: "#4231C8" },
                      }}
                      variant="contained"
                    >
                      Sign Up
                    </Button>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  className="homeImage"
                  sx={{ position: "relative" }}
                >
                  <img src={heroImg} alt="s" className="eclips" />
                  {/* <img src={mobileVideo} alt="s" className="mobile" /> */}
                  <div style={{ width: "100%", height: 500 }}>
                    {/* <video
                      ref={videoRef}
                      onEnded={handleEnded}
                      autoPlay loop muted
                      style={{
                        width: '100%',
                        maxWidth: '925px',
                        maxHeight: '500px'
                      }}
                    >
                      <source src={altVideo} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video> */}
                  </div>
                  {/* <video src={mobileVideo} autoplay muted loop playsinline preload="auto" className="mobile"></video> */}
                  {/* <video src=""></video> */}
                  {/* <img src={eclips2} alt="s" className="eclips2" /> */}
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Stack>

        {/* News and update */}
        <Container className="contPadding manageContainer" sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Stack container style={{ marginTop: 40, }} >
            <Typography
              variant="h5"
              className="titleHeading"
              style={{
                color: darkMode ? "#e1e1e1" : ""
              }}
            >
              News & Updates
            </Typography>
            {/* <Box sx={{ borderBottom: "1px solid #4231C8", width: "100%" }}></Box> */}
            <Box sx={{ mt: 3 }}>
              <Container className="manageContainer">
                <Slider {...settings}>
                  <Box spacing={2} ><img src={card1} className="cardImg" alt="s" /></Box >
                  <Box spacing={2} ><img src={card2} className="cardImg" alt="s" /></Box >
                  <Box spacing={2} ><img src={card3} className="cardImg" alt="s" /></Box >
                  <Box spacing={2} ><img src={card1} className="cardImg" alt="s" /></Box >
                  <Box spacing={2} ><img src={card2} className="cardImg" alt="s" /></Box >
                  <Box spacing={2} ><img src={card3} className="cardImg" alt="s" /></Box >
                </Slider>
              </Container>
            </Box>

            {/* <Stack
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              xs={12}
              sx={{
                marginTop: 8,
                bgcolor: "#FAFAFA",
                padding: 2,
                borderRadius: 3,
                height:"226px",
                marginBottom: 5,
              }}
            >
              <Stack item sx={{ position: "relative" }}>
                <Box sx={{ width: "fit-content" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      textAlign:"center",
                      color: "#4231C8",
                      fontWeight: 800,
                    }}
                  >
                    500+
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "center", color: "#999999" }}
                  >
                    Coins
                  </Typography>
                </Box>

                <img src={Group1} className="Group1Img" alt="s" />
              </Stack>

              <Stack item sx={{ position: "relative" }}>
                <Box sx={{ width: "fit-content" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      textAlign:"center",
                      color: "#4231C8",
                      fontWeight: 800,
                    }}
                  >
                    1000+
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "center", color: "#999999" }}
                  >
                    Markets
                  </Typography>
                </Box>

                <img src={Group2} className="Group1Img" alt="s" />
              </Stack>
              <Stack item sx={{ position: "relative" }}>
                <Box sx={{ width: "fit-content" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      textAlign:"center",
                      color: "#4231C8",
                      fontWeight: 800,
                    }}
                  >
                    1,000,000
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "center", color: "#999999" }}
                  >
                    Registered Users
                  </Typography>
                </Box>

                <img src={Group3} className="Group1Img" alt="s" />
              </Stack>
              <Stack item sx={{ position: "relative" }}>
                <Box sx={{ width: "fit-content" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      textAlign:"center",
                      color: "#4231C8",
                      fontWeight: 800,
                    }}
                  >
                    488,000
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "center", color: "#999999" }}
                  >
                    Weekly Visitors
                  </Typography>
                </Box>

                <img src={Group4} className="Group1Img" alt="s" />
              </Stack>
            </Stack> */}
          </Stack>


        </Container>
        <Container className="" sx={{ paddingBottom: 5 }}>
          {/* <img src={group1307} alt="j" className="group1307"></img> */}
          <div className="priceShowSection" style={{ marginTop: 60, marginBottom: 40 }}>
            <div class="marketPriceDiv">
              {/* <img src={vector1} alt="" /> */}
              <div className="pricteTxtDiv">
                <h6>1500+</h6>
                <p style={{ fontSize: 16 }}>Coins</p>
              </div>
            </div>
            <div class="marketPriceDiv">
              {/* <img src={vector2} alt="" /> */}
              <div className="pricteTxtDiv">
                <h6>2000+</h6>
                <p style={{ fontSize: 16 }}>Markets</p>
              </div>
            </div>
            <div class="marketPriceDiv">
              {/* <img src={vector3} alt="" /> */}
              <div className="pricteTxtDiv">
                <h6>3,000,000+</h6>
                <p style={{ fontSize: 16 }}>Users visits per month</p>
              </div>
            </div>
            <div class="marketPriceDiv">
              {/* <img src={vector4} alt="" /> */}
              <div className="pricteTxtDiv">
                <h6 style={{ textAlign: "center" }}>20+</h6>
                <p style={{ fontSize: 16 }}>New projects monthly</p>
              </div>
            </div>
          </div>
        </Container>
        {/* market Table */}
        <Container className=" manageContainer managePadding" id="narrowContainer">
          <Stack container>
            <SortMarket />

            {/* <Box sx={{ borderBottom: "1px solid #4231C8", width: "100%" }}></Box> */}
          </Stack>
          <TableLayout />
        </Container>
        {/* new Listing */}
        <Container className="manageContainer" sx={{ marginTop: 5, marginBottom: 5 }}>
          <Stack >
            <Stack
              direction="row"
              justifyContent="space-between"
            // sx={{ borderBottom: "1px solid #4231C8" }}
            >
              <Typography
                variant="h5"
                className="titleHeading"
                style={{
                  color: darkMode ? "#e1e1e1" : ""
                }}
              >
                New Listings
              </Typography>
            </Stack>
          </Stack>
          <Stack
            useFlexGap
            flexWrap="wrap"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              mt: 8,
            }}
            className="cardMainDiv"

          >
            <Stack
              Item
              sx={{
                minWidth: { xs: "85%", md: "22%", sm: "45%" },
                minHeight: { md: "100px", xs: "auto" },
                borderRadius: "12px",
                border: darkMode ? "1 px solid #000" : "1px solid #ECECEC",
                backgroundColor: darkMode ? "#282828" : "#FFFFFF"
              }}
              spacing={{ xs: 1, sm: 2 }}
              direction="column"
              alignItems="center"
              padding={4}
            >
              <Box>
                <img className="eclips3" src={Eclips10} alt="s" />
              </Box>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography className="mythText"
                >
                  Mythril
                </Typography>
                <Typography
                  variant="body1"
                  className="mythTextSec"
                >
                  (MYTH)
                </Typography>
              </div>
            </Stack>
            <Stack
              Item
              sx={{
                minWidth: { xs: "85%", md: "22%", sm: "45%" },
                minHeight: { md: "100px", xs: "auto" },
                borderRadius: "12px",
                border: darkMode ? "1 px solid #000" : "1px solid #ECECEC",
                backgroundColor: darkMode ? "#282828" : "#FFFFFF"
              }}
              spacing={{ xs: 1, sm: 2 }}
              direction="column"
              alignItems="center"
              padding={4}
            >
              <Box>
                <img className="eclips3" src={Eclips7} alt="s" />
              </Box>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  className="mythText"
                >
                  Frax Share
                </Typography>
                <Typography
                  variant="body1"
                  className="mythTextSec"
                >
                  (FXS)
                </Typography>
              </div>
            </Stack>
            <Stack
              Item
              sx={{
                minWidth: { xs: "85%", md: "22%", sm: "45%" },
                minHeight: { md: "100px", xs: "auto" },
                borderRadius: "12px",
                border: darkMode ? "1 px solid #000" : "1px solid #ECECEC",
                backgroundColor: darkMode ? "#282828" : "#FFFFFF"
              }}
              spacing={{ xs: 1, sm: 2 }}
              direction="column"
              alignItems="center"
              padding={4}
            >
              <Box>
                <img className="eclips3" src={Eclips8} alt="s" />
              </Box>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography className="mythText"
                >
                  Flux
                </Typography>
                <Typography
                  variant="body1"
                  className="mythTextSec"
                >
                  (FLUX)
                </Typography>
              </div>
            </Stack>
            <Stack
              Item
              sx={{
                minWidth: { xs: "85%", md: "22%", sm: "45%" },
                minHeight: { md: "100px", xs: "auto" },
                borderRadius: "12px",
                border: darkMode ? "1 px solid #000" : "1px solid #ECECEC",
                backgroundColor: darkMode ? "#282828" : "#FFFFFF"
              }}
              spacing={{ xs: 1, sm: 2 }}
              direction="column"
              alignItems="center"
              padding={4}
            >
              <Box>
                <img className="eclips3" src={Eclips9} alt="s" />
              </Box>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography className="mythText"
                >
                  Kambria
                </Typography>
                <Typography
                  variant="body1"
                  className="mythTextSec"
                >
                  (KAT)
                </Typography>
              </div>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="center" className="mt01" marginTop={7}>
            <Button
              component={Link}
              to="/listing"
              className="btnText"
              sx={{
                width: 200,
                height: 40,
                borderRadius: 1,
                textTransform: "capitalize",
                bgcolor: "#4231C8",
                "&:hover": { bgcolor: "#4231C8" },
              }}
              variant="contained"
            >
              Apply for Listings
            </Button>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            sx={{ marginTop: 3 }}
            alignItems="center"
            spacing={8}
          >
            <Stack
              item
              spacing={3}
              direction="column"
              justifyContent="flex-end"
              sx={{ width: { md: "60%", xs: "100%" } }}
              className="chartDesc"
            >
              <Typography
                variant="h5"
                className=""
                sx={{
                  width: { md: "70%", xs: "100%" },
                  fontSize: 38,
                  fontWeight: "700",
                  color: darkMode ? "#e1e1e1" : ""
                }}
              >
                Trade Anytime, Anywhere
              </Typography>
              <Typography
                variant="body1"
                className="subTitle"
                sx={{
                  width: { md: "74%", xs: "100%" },
                  fontSize: 38,
                  fontWeight: "700",
                  color: darkMode ? "#fff" : ""
                }}
              >
                With ALT-X, you're not bound by time or location. Our platform. enables you to trade cryptocurrencies 24/7, whether you're at home, in the office, or on the go. Trade with freedom and flexibility – anytime, anywhere, instantly
              </Typography>
              <Button
                component={Link}
                to="/signup"
                className="btnText btnMarginSet"

                sx={{
                  width: 150,
                  height: 40,
                  borderRadius: 1,
                  textTransform: "capitalize",
                  bgcolor: "#4231C8",
                  "&:hover": { bgcolor: "#4231C8" },
                }}
                variant="contained"
              >
                Learn More
              </Button>
            </Stack>
            <Stack
              item
              sx={{
                width: { md: "40%", xs: "100%" },
                justifyContent: { md: "center", xs: "center" },
              }}
              direction="row"
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={s1} className="s1Img" alt="s" style={{ width: "95%" }} />
              </Box>
            </Stack>
          </Stack>
          <Stack
            direction={{ xs: "column-reverse", sm: "row" }}
            sx={{ marginTop: 3 }}
            alignItems="center"
            spacing={8}
          >
            <Stack
              item
              sx={{
                width: { md: "40%", xs: "100%" },
                justifyContent: { md: "center", xs: "start" },
              }}
              direction="row"
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "start",
                }}
              >
                <img src={s2} className="s1Img" alt="s" />
              </Box>
            </Stack>
            <Stack
              item
              spacing={3}
              direction="column"
              justifyContent="center"
              sx={{ width: { md: "75%", xs: "100%" } }}
              className="ps-md-5 ps-0"
            >
              <Typography
                variant="h5"
                className=""
                sx={{
                  width: { md: "90%", xs: "100%" },
                  fontSize: 38,
                  fontWeight: "700",
                  color: darkMode ? "#e1e1e1" : ""
                }}
              >
                Trade Smarter with Automatic Trading Bots
              </Typography>
              <Typography
                variant="body1"
                className="subTitle"
                sx={{
                  width: { md: "74%", xs: "100%" },
                }}
              >
                Trade smarter and faster with ALT-X. Our platform supports a range of trading bots, each with unique advantages. Explore automated trading strategies tailored to your goals. Start optimizing your trades with ALT-X today.
              </Typography>
              <Button
                component={Link}
                to="/signup"
                className="btnText btnMarginSet"

                sx={{
                  width: 150,
                  height: 40,
                  borderRadius: 1,
                  textTransform: "capitalize",
                  bgcolor: "#4231C8",
                  "&:hover": { bgcolor: "#4231C8" },
                }}
                variant="contained"
              >
                Learn More
              </Button>
            </Stack>
          </Stack>
        </Container>
        {/* Cripto exchange */}
        <Container className="manageContainer">
          <Stack
            direction="row"
            justifyContent="center"
          >
            <Typography
              variant="h5"
              className=""
              sx={{
                paddingBottom: 3,
                textAlign: "center",
                fontSize: 34,
                fontWeight: "700",
                color: darkMode ? "#e1e1e1" : ""
              }}
            >
              Why Choose <span className="blue">ALT-X? </span>
            </Typography>
          </Stack>
          <Stack className="cardMainDiv"
            useFlexGap
            flexWrap="wrap"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
              mt: 6,
            }}
          >
            <Stack
              Item
              sx={{
                width: { xs: "85%", md: "22%", sm: "45%" },
                borderRadius: "30px",
                minHeight: { md: "330px", lg: "280px", xs: "auto" },
                border: darkMode ? "1 px solid #000" : "1px solid #ECECEC",
                backgroundColor: darkMode ? "#282828" : "#FFFFFF"
              }}
              spacing={{ xs: 1, sm: 2 }}
              paddingTop={2}
              paddingBottom={2}
              paddingLeft={2}
              paddingRight={2}
              direction="column"
              alignItems="center"
            >
              <Box>

                <img className="eclips3" src={lock} alt="s" />
              </Box>
              <Typography
                sx={{
                  color: darkMode ? "#e1e1e1" : ""
                }}
                className="storageHeading">
                Security
              </Typography>
              <Typography
                variant="body1"
                className="storageSubHeading"

              >
                At ALT-X, security is our top priority.
                Our platform employs cutting-edge encryption protocols and multi-factor authentication to
                ensure that your assets are always safe from cyber threats
              </Typography>
            </Stack>
            <Stack
              Item
              sx={{
                width: { xs: "85%", md: "22%", sm: "45%" },
                borderRadius: "30px",
                minHeight: { md: "330px", lg: "280px", xs: "auto" },
                border: darkMode ? "1 px solid #000" : "1px solid #ECECEC",
                backgroundColor: darkMode ? "#282828" : "#FFFFFF"
              }}
              spacing={{ xs: 1, sm: 2 }}
              paddingTop={2}
              paddingBottom={2}
              paddingLeft={2}
              paddingRight={2}
              direction="column"
              alignItems="center"
            >
              <Box>
                <img className="eclips3" src={admin} alt="s" />
              </Box>
              <Typography
                sx={{
                  color: darkMode ? "#e1e1e1" : ""
                }}
                className="storageHeading"
              >
                Instant Access
              </Typography>
              <Typography
                variant="body1"
                className="storageSubHeading"
              >
                Alt-X offers instant access to over 1500
                cryptocurrencies, including Bitcoin and Ethereum, you can diversify your portfolio and seize new investment opportunities, instantly
              </Typography>
            </Stack>
            <Stack
              Item
              sx={{
                width: { xs: "85%", md: "22%", sm: "45%" },
                borderRadius: "30px",
                minHeight: { md: "330px", lg: "280px", xs: "auto" },
                border: darkMode ? "1 px solid #000" : "1px solid #ECECEC",
                backgroundColor: darkMode ? "#282828" : "#FFFFFF"
              }}
              spacing={{ xs: 1, sm: 2 }}
              paddingTop={2}
              paddingBottom={2}
              paddingLeft={2}
              paddingRight={2}
              direction="column"
              alignItems="center"
            >
              <Box>
                <img className="eclips3" src={wallet} alt="s" />
              </Box>
              <Typography
                sx={{
                  color: darkMode ? "#e1e1e1" : ""
                }}
                className="storageHeading"
              >
                Cold wallet storage
              </Typography>
              <Typography
                variant="body1"
                className="storageSubHeading"
              >
                95% of ALT-X digital assets are stored in cold
                wallets to protect users from security breaches and theft
              </Typography>
            </Stack>
            <Stack
              Item
              sx={{
                width: { xs: "85%", md: "22%", sm: "45%" },
                borderRadius: "30px",
                minHeight: { md: "330px", lg: "280px", xs: "auto" },
                border: darkMode ? "1 px solid #000" : "1px solid #ECECEC",
                backgroundColor: darkMode ? "#282828" : "#FFFFFF"
              }}
              spacing={{ xs: 1, sm: 2 }}
              paddingTop={2}
              paddingBottom={2}
              paddingLeft={2}
              paddingRight={2}
              direction="column"
              alignItems="center"
            >
              <Box>
                <img className="eclips3" src={phone} alt="s" />
              </Box>
              <Typography
                sx={{
                  color: darkMode ? "#e1e1e1" : ""
                }}
                className="storageHeading"
              >
                24/7 Customer Support
              </Typography>
              <Typography
                variant="body1"
                className="storageSubHeading"
              >
                Need assistance? Our dedicated support
                team is available 24/7 to help you with any questions or concerns you may have
              </Typography>
            </Stack>
            <Stack
              style={{ marginLeft: "auto", marginRight: "auto" }}
              Item
              sx={{
                width: { xs: "85%", md: "22%", sm: "45%" },
                borderRadius: "30px",
                minHeight: { md: "330px", lg: "280px", xs: "auto" },
                border: darkMode ? "1 px solid #000" : "1px solid #ECECEC",
                backgroundColor: darkMode ? "#282828" : "#FFFFFF"
              }}
              spacing={{ xs: 1, sm: 2 }}
              paddingTop={2}
              paddingBottom={2}
              paddingLeft={2}
              paddingRight={2}
              direction="column"
              alignItems="center"
            >
              <Box>
                <img className="eclips3" src={globeImg} alt="s" />
              </Box>
              <Typography
                sx={{
                  color: darkMode ? "#e1e1e1" : ""
                }}
                className="storageHeading"
              >
                Transparent Fees
              </Typography>
              <Typography
                variant="body1"
                className="storageSubHeading"
              >
                ALT-X offers transparent pricing, so you'll always know exactly what you're paying for.
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            sx={{ marginTop: "4rem" }}
          >
            <Typography
              variant="h5"
              className=""
              sx={{
                paddingBottom: 3,
                textAlign: "center",
                fontSize: 34,
                fontWeight: "700",
                color: darkMode ? "#e1e1e1" : ""
              }}
            >
              Our <span className="blue">Mission</span>
            </Typography>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            sx={{
              marginTop: 3,
              bgcolor: darkMode ? "#282828" : "#FAFAFA"
            }}
            alignItems="center"
            spacing={8}
            className="scanDiv"
          >
            <Stack

              item
              spacing={3}
              direction="column"
              justifyContent="flex-end"
              sx={{ width: { md: "60%", xs: "100%" } }}
              className="chartDesc"
            >

              <Typography
                variant="body1"
                className="subTitle subTitleOur"
                sx={{
                  width: { md: "80%", xs: "100%" },
                }}
              >
                At ALT-X, we're on a mission to make digital asset trading accessible to everyone, without compromising on security. Whether you're a seasoned trader or just getting started, ALT-X is the platform you can trust.
              </Typography>

            </Stack>
            <Stack
              item
              sx={{
                width: { md: "40%", xs: "100%" },
                justifyContent: { md: "center", xs: "center" },
              }}
              direction="row"
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={scan} className="s1Img" alt="s" style={{ width: "100%" }} />
              </Box>
            </Stack>
          </Stack>
        </Container>
        {/* banneer img last */}
        <Container className="downloadAltTxt manageContainer" sx={{ paddingBottom: 5, paddingTop: 5 }}>
          <Stack sx={{ position: "relative" }}>
            <Box sx={{ display: "flex", margin: 0, padding: 0 }}>
              <img src={img} alt="s" className="wrpImg" />

              <div className="right-wraper"></div>
            </Box>

            <div className="bottom-wraper">
              <Box
                sx={{
                  float: "right",
                  paddingTop: { md: "7%", xs: "2%", sm: "5%" },
                  paddingRight: { md: "10%", xs: "0%", sm: "5%" },
                  paddingLeft: { md: 0, sm: 1, xs: 1 },
                  width: { md: "46%", xs: "100%", sm: "50%" },
                  borderRadius: { xs: 1 },

                  bgcolor: {
                    md: "transparent",
                    sm: "transparent",
                    xs: "#4231C82a",
                  },
                }}
              >
                <Typography className="pocketText"
                  variant="h4"
                >
                  Take Your Crypto Anywhere with ALT-X
                </Typography>
                <Typography
                  variant="body1" className="pocketTextP"
                >
                  Download the ALT-X app to stay connected with your crypto investments wherever you are. ALT-X is compatible with all major devices and platforms, ensuring seamless tracking and management of your portfolio on the go.
                </Typography>
                <Stack className="logoDiv"
                  direction="row"
                  alignItems="center"
                  sx={{
                    paddingTop: { md: 3, xs: 2, sm: 2 },
                    position: "relative",
                    right: "5px",
                  }}
                >
                  <img src={playstore} className="socialImg" />
                  <img src={apple} className="socialImg2" />
                </Stack>
              </Box>
            </div>
          </Stack>
        </Container>
        {/* bottom banner */}
        <Box sx={{ bgcolor: "#4231C8", paddingTop: 3, paddingBottom: 3, color: "#ffffff" }}>
          <Container className="manageContainer">
            <Stack
              direction={{ md: "row", xs: "column" }}
              justifyContent={{ md: "space-between", xs: "start" }}
              alignItems={{ md: "center", xs: "center" }}
              gap={2}
              style={{ height: 216 }}
            >
              <Typography variant="h4" className="safeText" sx={{ width: { md: 591, xs: "100%" } }}>
                Ready to take your digital asset trading to the next level? Sign up for ALT-X today and experience the future of secure trading.
              </Typography>
              <Button
                component={Link}
                to="/signup"
                aria-controls="language-menu"
                aria-haspopup="true"
                variant="outlined"
                className="signBtn"
                sx={{
                  color: "#4231C8",
                  fontSize: "16px",
                  borderRadius: 1,
                  width: 100,
                  height: 40,
                  textTransform: "capitalize",
                  border: "1px solid #4231C8",

                  fontWeight: 500,
                  bgcolor: "#ffffff",

                  "&:hover": { bgcolor: "#ffffff" },
                }}
              >
                Sign Up
              </Button>
            </Stack>
          </Container>
        </Box>
      </Stack>
    </Layout>
  );
};

export default Home;
