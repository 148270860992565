import React, { useState } from "react";
import { myPages } from "../../constants/Data";

export const SideMenu = ({ setPageNo = () => {} }) => {
  const [pages, setPages] = useState(myPages);
  const handleSideMenu = (data) => (setData) => (item) => (setValue) => () => {
    let mappedOut = data?.map((_item, index) => {
      if (index === item) {
        setValue(index);
        return { ..._item, selected: true };
      }
      return { ..._item, selected: false };
    });
    setData(mappedOut);
  };
  return (
    <div className="side-menu">
      <span className="side-menu-title">My Page</span>
      
      {pages?.map((item, index) => {
        return (
          <div
            className={`d-flex flex-row justify-content-start align-items-center side-menu-item ${
              item?.selected ? "side-active" : "side-inactive"
            }`}
            onClick={handleSideMenu(pages)(setPages)(index)(setPageNo)}
          >
            <img src={item?.image.default} className="me-2" />
            <span>{item?.name}</span>
          </div>
        );
      })}
    </div>
  );
};
