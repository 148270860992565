import { Grid } from "@mui/material";
import React, { useState } from "react";
import { Tabs } from "../Tabs";
import { CreateApiKey } from "./CreateApiKey";
import { ApiKeyList } from "./ApiKeyList";
import { CustomModal } from "../../../modal/Modal";

export const ApiManagement = ({ apiTabs, tabSelection }) => {
  const [show, setShow] = useState(false);
  const [apiKeyShow, setApiKeyShow] = useState(false);

  const handleModal = () => {
    setShow(!show);
  };

  const handleKey = () => {
    setApiKeyShow(!apiKeyShow);
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText("gsavbdsaba739oeouwhhdgsuy7gt");
  };

  const modalContent = () => {
    return (
      <div className="api-input-div">
        <div className={`${apiKeyShow ? "mt-0" : "mt-2"}`}>
          <span className="key-text">
            {apiKeyShow
              ? "gsavbdsaba739oeouwhhdgsuy7gt"
              : "*********************************"}
          </span>
        </div>
        <div>
          <img
            src={
              require(apiKeyShow
                ? "../../../../assets/eye.svg"
                : "../../../../assets/eyeOff.svg").default
            }
            className="cursorPointer me-3"
            onClick={handleKey}
          />
          <img
            src={require("../../../../assets/copyWhite.svg").default}
            className="cursorPointer"
            onClick={handleCopyUrl}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <CreateApiKey onClick={handleModal} />
      <ApiKeyList />
      {show && (
        <CustomModal
          show={show}
          title="Your API Key"
          children={modalContent()}
          handleMethod={handleModal}
          btnText={"Ok"}
        />
      )}
    </>
  );
};
