import React from "react";
import { settings } from "../../constants/Data";
import RadioButtons from './RadioButtons'
import {
  FormControlLabel,
  Radio,
} from "@mui/material";
import Language from "./Language";
import Timezone from "./Timezone"; // Import TimeZone component

const Settings = () => {
  const renderLanguageManagement = () => {
    return (
      <div className="security-div">

          {settings?.map((item, index) => (
            <div
              key={index}
              className={` ${index === 0 ? "bottomBorder mb-5 pb-4" : ""}`}
            >
              <div className="row mx-0 title01">
                <div className="col-lg-6 col-md-4 col-sm-6 col-12 d-flex flex-row align-items-center title01 titleCols">
                  <span className="sec-name security-sub-title">{item?.name}</span>
                </div>
                <div className="col-lg-6 col-md-8 col-sm-6 col-12 d-flex flex-column align-items-end px-0  buttonsDiv">
                {item?.name === "Language" && <Language />}
                    {item?.name === "Timezone" && <Timezone/>}
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };

  // Function to render the notification preferences section
  const renderNotificationPreferences = () => {
    return (
      <div className="security-div mt-4">
        <div className="row mx-0">
          <div className="col-lg-12 d-flex flex-column justify-content-lg-center bottomBorder notifCol pb-4">
            <span className="noti-text">Notification preferences</span>
            <span className="noti-desc">
              You can choose to receive our emails and get notifications regarding listings, market activity, affiliated services, price trackers, event information, and more.
            </span>
          </div>
        </div>
        <div className="row">
          <RadioButtons />
        </div>
      </div>
    );
  };

  return (
    <div className="profile-view">
      <p className="profile-title">Security</p>
      {renderLanguageManagement()}
      {renderNotificationPreferences()}
    </div>
  );
};

export default Settings;
