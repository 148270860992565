import React, { useContext } from "react";
import Layout from "../layouts/Layout";
import SearchIcon from "../../assets/searchIcon.svg";
import arrowDown from "../../assets/arrowDown.svg";
import arrowUp from "../../assets/arrowUp.svg";
import logo01 from "../../assets/image 1.png";
import logo02 from "../../assets/image 2.png";
import logo03 from "../../assets/image 3.png";
import logo04 from "../../assets/image 4.png";
import { DarkModeContext } from "../../ThemeContext";

// table data
const tableData = [
  {
    logo: logo01,
    code: "USDT",
    name: "Tether",
    platform: "Ethereum (ERC-20)",
    enabled: "Enabled",
    showLink: true,
    suspended: false,
  },
  {
    logo: logo02,
    code: "BTC",
    name: "Bitcoin",
    platform: "Ethereum (ERC-20)",
    enabled: "Enabled",
    showLink: true,
    suspended: false,
  },
  {
    logo: logo03,
    code: "ETH",
    name: "Ethereum",
    platform: "Ethereum (ERC-20)",
    enabled: "Enabled",
    showLink: true,
    suspended: false,
  },
  {
    logo: logo04,
    code: "AAA",
    name: "Moon Rabbit",
    platform: "Ethereum (ERC-20)",
    enabled: "Enabled",
    showLink: false,
    suspended: false,
  },
  {
    logo: logo01,
    code: "USDT",
    name: "Tether",
    platform: "Ethereum (ERC-20)",
    enabled: "Enabled",
    showLink: true,
    suspended: true,
  },
  {
    logo: logo02,
    code: "BTC",
    name: "Bitcoin",
    platform: "Ethereum (ERC-20)",
    enabled: "Enabled",
    showLink: true,
    suspended: false,
  },
  {
    logo: logo03,
    code: "ETH",
    name: "Ethereum",
    platform: "Ethereum (ERC-20)",
    enabled: "Enabled",
    showLink: false,
    suspended: false,
  },
  {
    logo: logo04,
    code: "AAA",
    name: "Moon Rabbit",
    platform: "Ethereum (ERC-20)",
    enabled: "Enabled",
    showLink: true,
    suspended: true,
  },
  {
    logo: logo01,
    code: "USDT",
    name: "Tether",
    platform: "Ethereum (ERC-20)",
    suspended02: true,
    enabled: "Enabled",
    showLink: true,
    suspended: false,
  },
  {
    logo: logo02,
    code: "BTC",
    name: "Bitcoin",
    platform: "Ethereum (ERC-20)",
    enabled: "Enabled",
    showLink: false,
    suspended: false,
  },
];
const DepositWithdrawals = () => {
  const { darkMode } = useContext(DarkModeContext)
  return (
    <Layout>
      <div className="mainContentDiv">
        <h4 style={{ color: darkMode && "#e1e1e1" }} className="supportTitle">Deposit & Withdrawal Status</h4>
        <div className="searchDiv">
          <input type="text" name="" id="" style={{backgroundColor : darkMode && "#282828"}} />
          <img src={SearchIcon} alt="" className="searchIconImg" />
        </div>
        <div className="tableMainSection">
          <div className="thead01">
            <div className="theadCol00 theadCol01 ">
              <h6>Coin</h6>
              <div className="arrowImgDiv">
                <img src={arrowUp} alt="" className="rotateIcon" />
              </div>
            </div>
            <div className="theadCol00 theadCol02">
              <h6>Name</h6>
              <div className="arrowImgDiv">
                <img src={arrowUp} alt="" />
                <img src={arrowUp} alt="" className="rotateIcon" />
              </div>
            </div>
            <div className="theadCol00 theadCol03">
              <h6>Blockchain type</h6>
            </div>
            <div className="theadCol00 theadCol04">
              <h6>Deposit</h6>
              <div className="arrowImgDiv">
                <img src={arrowUp} alt="" />
                <img src={arrowUp} alt="" className="rotateIcon" />
              </div>
            </div>
            <div className="theadCol00 theadCol05">
              <h6>Withdrawal</h6>
              <div className="arrowImgDiv">
                <img src={arrowUp} alt="" />
                <img src={arrowUp} alt="" className="rotateIcon" />
              </div>
            </div>
            <div className="theadCol00 theadCol06">
              <h6>Description</h6>
              <div className="arrowImgDiv">
                <img src={arrowUp} alt="" />
                <img src={arrowUp} alt="" className="rotateIcon" />
              </div>
            </div>
            <div className="theadCol00 theadCol07">
              <h6>Contract address</h6>
            </div>
          </div>
          {/* tbody row  */}
          {tableData.map((item, index) => (
            <div key={index} className="tbodyTr01">
              <div className="theadCol00 theadCol01 logoImgdiv">
                <img src={item.logo} alt="" className="logoImg" />
                <p style={{ fontSize: 16, fontWeight: "400", color : darkMode && "#E1E1E1" }}>{item.code}</p>
              </div>
              <div className="theadCol00 theadCol02">
                <p style={{ fontSize: 16, fontWeight: "400",color : darkMode && "#E1E1E1" }}>{item.name}</p>
              </div>
              <div className="theadCol00 theadCol03">
                <p style={{ fontSize: 16, fontWeightL: "400", color : darkMode && "#E1E1E1" }}>{item.platform}</p>
              </div>
              <div className="theadCol00 theadCol04">
                <li style={{color : darkMode && "#E1E1E1"}} className={item.suspended02 ? "suspendLi" : ""}>
                  {item.suspended02 ? "Suspended" : item.enabled}
                </li>
              </div>
              <div className="theadCol00 theadCol05">
                <li style={{color : darkMode && "#E1E1E1"}} className={item.suspended ? "suspendLi" : ""}>
                  {item.suspended ? "Suspended" : item.enabled}
                </li>
              </div>
              <div className="theadCol00 theadCol06">
                {/* <p>Description</p> */}
              </div>
              <div className="theadCol00 theadCol07">
                {item.showLink && <a style={{ fontSize: 16, color : darkMode && "#E1E1E1" }} href="#">Show</a>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default DepositWithdrawals;
