import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
const TransactioHistoryTable = ({ data, columns }) => {
  return (
    <TableContainer component={Paper} sx={{ marginTop: 6, boxShadow: "none" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ borderBottom: "unset" }}>
            {columns?.map((item) => {
              return (
                <TableCell sx={{ color: "#999999" }}>Date (UTC+5)</TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                borderBottom: "border: 0.5px solid #D9D9D9",
                "&:last-child": {
                  borderBottom: "0.5px solid #fff",
                },
                "&:first-child": {
                  borderTop: "0.5px solid #D9D9D9",
                },
              }}
            >
              <TableCell align="left">{row.time}</TableCell>
              <TableCell align="left">{row.type}</TableCell>
              <TableCell align="left">{row.status}</TableCell>
              <TableCell align="left">{row.coin}</TableCell>
              <TableCell align="left">{row.amount}</TableCell>
              <TableCell align="left" sx={{ color: "#999999" }}>
                Details
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransactioHistoryTable;
