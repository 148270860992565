import React, { useState } from "react";
import Layout from "../../layouts/Layout";
import { SideMenu } from "./SideMenu";
import UserProfile from "./Profile";
import Verification from "./Verification";
import Security from "./Security";
import Settings from "./Settings";

const Profile = () => {
  const [pageNo, setPageNo] = useState(0);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  const toggleSideMenu = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };
  return (
    <Layout>
      <div className="profile-container row">
        <i className={`bx bx-menu-alt-left menuOpenIcon`} onClick={toggleSideMenu}></i>
        <div style={{ paddingTop: 40 }} className={`col-xl-3 col-lg-4 col-md-4 col-sm-3 sideBarCol d-flex flex-row justify-content-center align-items-start ${isSideMenuOpen ? 'menu-open' : ''}`}>
          <SideMenu setPageNo={setPageNo} />
        </div>
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
          {pageNo === 0 ? (
            <UserProfile />
          ) : pageNo === 1 ? (
            <Verification />
          ) : pageNo === 2 ? (
            <Security />
          ) : (
            <Settings />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
