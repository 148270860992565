import React, { useState } from "react";
import {
  Button,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation } from "react-router-dom";
import LanguageSelect from "./LanguageSelect";
import ProfileSelect from "./ProfileSelect";
import WalletSelect from "./WalletSelect";
import OrderSelect from "./OrderSelect";
import SupportSelect from "./SupportSelect";
const pages = ["Products", "Services", "ABoutUs", "ContactUs"];
const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const open = Boolean(true);

  const location = useLocation();
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));
  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={openDrawer}
        sx={{ color: "#4231c8" }}
        onClose={() => setOpenDrawer(false)}
      >
        <Stack direction="column" alignItems="start" spacing={2}>
          <Stack direction="row" sx={{ padding: 1 }} gap={1}>
            <Button
              component={Link}
              to="/login"
              sx={{
                borderRadius: 8,
                textTransform: "capitalize",
                bgcolor: "#4231c8",
                width : '90px',
                "&:hover": { bgcolor: "#4231c8" },
              }}
              variant="contained"
            >
              Login
            </Button>

            <LanguageSelect className="ml-10" />
          </Stack>

          <Typography
            sx={{
              color: "#151515",
              width: "100%",
              textAlign: "start",
              fontSize: "16px",
              cursor: "pointer",
              pb: 2,
              pl: 1,
              textTransform: "capitalize",
            }}
          >
            <Link to="/exchange">Exchange</Link>
          </Typography>
          
          {/* <Typography
            sx={{
              color: "#151515",
              width: "100%",
              cursor: "pointer",
              textAlign: "start",
              fontSize: "16px",
             
              pl: 1,
              textTransform: "capitalize",
            }}
          >
            <Link to="/swap">Swap</Link>
          </Typography> */}
          <Stack
            direction="column"
            alignItems="start"
            width="0%"
            pl={1}
            mt={0}
            pt={0}
          >
            <WalletSelect />
            <OrderSelect />
            <SupportSelect />
          </Stack>
        </Stack>
      </Drawer>
      <IconButton
        sx={{ color: "#000000", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="#4231c8" />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
