import React, { useState, useContext } from "react";
import { exchangeSymbolTabsMarket } from "../../constants/Data";
import { Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, Menu, MenuItem, ListItemIcon } from "@mui/material";
import { DarkModeContext } from "../../../ThemeContext";
import { getToken } from "../../../services/LocalStorageService"
import { useNavigate } from "react-router-dom";

const languages = [
  { code: "en", label: "BTC" },
  { code: "es", label: "ETH" },
  // Add more languages as needed
];
const languages1 = [
  { code: "en", label: "BTC" },
  { code: "es", label: "ETH" },
  // Add more languages as needed
];
export default function LimitMarkrt() {
  const access_token = getToken();
  console.log(access_token, 'tokens')
  let notLoggedIn;
  if (!access_token?.access_token) {
    notLoggedIn = true
  } else {
    notLoggedIn = false
  }
  const [symbolTabs, setSymbolTabs] = useState(exchangeSymbolTabsMarket);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("BTC");
  const [selectedLanguage1, setSelectedLanguage1] = useState("BTC");

  const handleTabClick = (index) => {
    const updatedTabs = symbolTabs.map((tab, i) => ({
      ...tab,
      selected: i === index,
    }));

    setSymbolTabs(updatedTabs);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (languageCode) => {
    setSelectedLanguage(languageCode);
    handleClose();
    // Add your logic for changing the language here
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleLanguageSelect1 = (languageCode) => {
    setSelectedLanguage1(languageCode);
    handleClose1();
    // Add your logic for changing the language here
  };
  const { darkMode } = useContext(DarkModeContext)
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const navigate = useNavigate()
  return (
    <>
      <div
        style={{ marginTop: 0, backgroundColor: darkMode ? "" : "#fff", height: 276 }}
        className="exchange-center-container px-2 border-0">
        <div className="d-flex flex-row justify-content-start align-items-center symbol-tab mt-0 limitTab">
          {symbolTabs?.map((item, index) => (
            <div
              key={index}
              style={{
                color: item?.selected &&
                  (darkMode ? "#e1e1e1" : "#323232")
              }}
              className={`${item?.selected
                ? "active-symbol-item"
                : "symbol-item symbol-item-history"
                }`}
              onClick={() => handleTabClick(index)}
            >
              {item?.name}
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 limitCol">
            <div className="usdtBalanceDiv">
              <p style={{ color: darkMode ? "" : "#000" }}>USDT Balance</p>
              <p style={{ color: darkMode ? "" : "#000" }}>0.00001</p>
              <Button
                style={{ color: darkMode ? "#fff" : "#000", backgroundColor: darkMode ? "#282828" : "#e1e1e1" }}
                aria-controls="language-menu"
                aria-haspopup="true"
                variant="outlined"
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                className="marketDropDown"
              >
                {selectedLanguage}
              </Button>
              <Menu
                id="language-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {languages.map((language) => (
                  <MenuItem
                    key={language.label}
                    onClick={() => handleLanguageSelect(language.label)}
                    sx={{ width: 200 }}
                  >
                    <Typography variant="body1" sx={{ ml: 2 }}>
                      <b>{language.label}</b>
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </div>
            <div
              className="buyInputDiv">
              <input
                style={{
                  backgroundColor: 'transparent',
                  color: darkMode && "#e1e1e1",
                }}
                type="text" name="" id="" placeholder="0.00001" />
              <p>USDT</p>
            </div>
            <div className="buyInputDiv">
              <input
                style={{
                  backgroundColor: 'transparent',
                  color: darkMode && "#e1e1e1",
                }}
                type="text" name="" id="" placeholder="0.00001" />
              <p>BTC</p>
            </div>
            <div className="personStageDiv">
              <div className="personDiv">
                <p>25%</p>
              </div>
              <div className="personDiv">
                <p>50%</p>
              </div>
              <div className="personDiv">
                <p>75%</p>
              </div>
              <div className="personDiv border-0">
                <p>100%</p>
              </div>
            </div>
            <div className="buyInputDiv">
              <input
                style={{
                  backgroundColor: 'transparent',
                  color: darkMode && "#e1e1e1",
                }}
                type="text" name="" id="" placeholder="0.00001" />
            </div>
            <button
              onClick={() => notLoggedIn ? navigate('/login') : console.log("logged IN")}
              id="btn1"
              className={`limitButton ${isHovered1 ? 'hovered' : ''}`}
              onMouseEnter={() => setIsHovered1(true)}
              onMouseLeave={() => setIsHovered1(false)}
              style={{
                backgroundColor: notLoggedIn && isHovered1 ? "gray" : ""
              }}
            >
              {notLoggedIn ? (isHovered1 ? 'Log In' : 'Buy') : 'Buy'}
            </button>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 marketCol">
            <div className="usdtBalanceDiv">
              <p>USDT Balance</p>
              <p>0.00001</p>
              <Button
                style={{ color: darkMode ? "#fff" : "#000", backgroundColor: darkMode ? "#282828" : "#e1e1e1" }}
                aria-controls="language-menu"
                aria-haspopup="true"
                variant="outlined"
                onClick={handleClick1}
                endIcon={<KeyboardArrowDownIcon />}
                className="marketDropDown"
              >
                {selectedLanguage1}
              </Button>

              <Menu
                id="language-menu"
                anchorEl={anchorEl1}
                keepMounted
                open={Boolean(anchorEl1)}
                onClose={handleClose1}
              >
                {languages1.map((language1) => (
                  <MenuItem
                    key={language1.label}
                    onClick={() => handleLanguageSelect1(language1.label)}
                    sx={{ width: 200 }}
                  >
                    <Typography variant="body1" sx={{ ml: 2 }}>
                      <b>{language1.label}</b>
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </div>
            <div className="buyInputDiv">
              <input
                style={{
                  backgroundColor: 'transparent',
                  color: darkMode && "#e1e1e1",
                }}
                type="text" name="" id="" placeholder="0.00001" />
              <p>USDT</p>
            </div>
            <div className="buyInputDiv">
              <input
                style={{
                  backgroundColor: 'transparent',
                  color: darkMode && "#e1e1e1",
                }}
                type="text" name="" id="" placeholder="0.00001" />
              <p>BTC</p>
            </div>
            <div className="personStageDiv">
              <div className="personDiv">
                <p>25%</p>
              </div>
              <div className="personDiv">
                <p>50%</p>
              </div>
              <div className="personDiv">
                <p>75%</p>
              </div>
              <div className="personDiv border-0">
                <p>100%</p>
              </div>
            </div>
            <div className="buyInputDiv">
              <input
                style={{
                  backgroundColor: 'transparent',
                  color: darkMode && "#e1e1e1",
                }}
                type="text" name="" id="" placeholder="0.00001" />
            </div>
            <button
              onClick={() => notLoggedIn ? navigate('/login') : console.log("logged IN")}
              id="btn2"
              style={{
                backgroundColor: notLoggedIn && isHovered2 ? "gray" : ""
              }}
              className={`marketButton ${isHovered2 ? 'hovered' : ''}`}
              onMouseEnter={() => setIsHovered2(true)}
              onMouseLeave={() => setIsHovered2(false)}
            >
              {notLoggedIn ? (isHovered2 ? 'Log In' : 'Sell') : 'Sell'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
