import React from "react";
import { verifySteps } from "../../constants/Data";

const Verification = () => {
  return (
    <div className="profile-view">
      <p className="profile-title">Verification</p>
      <div className="verify-div">
      <div className="row mx-0">
        {verifySteps?.map((item, index) => {
          return (
            <div
              className={`${
                index === 0 ? "step-margins" : ""
              } d-flex title01 flex-column ${index === 0 ? "bottomBorder" : ""}`}
            >
              <div className="mb-2 manageCol">
                <span className="verify-step">{item?.name}</span>
              </div>

              <div className="d-flex flex-row justify-content-between buttonGroup">
                <span className="user-verify">
                  Status: <span>Verification required</span>
                </span>
                {!item?.verify ? (
                  <div className="verify-btn">Verify Now</div>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
      </div>
    </div>
  );
};

export default Verification;
