import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Divider from "@mui/material/Divider";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import profile from "../../assets/profile.svg";
import p1 from "../../assets/wallet1.svg";
import p2 from "../../assets/wallet2.svg";
import p3 from "../../assets/wallet3.svg";
import p4 from "../../assets/wallet4.svg";

import { Box } from "@mui/material";
import { useLocation, Link } from "react-router-dom";
import { DarkModeContext } from "../../ThemeContext";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const WalletSelect = () => {
  const location = useLocation();
  const { pathname } = location;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showMenu, setShowMenu] = React.useState(false)
  const { darkMode } = React.useContext(DarkModeContext)

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const paddedStyle = {
    paddingTop: '6px',
    paddingRight: '16px',
    paddingBottom: '6px',
    paddingLeft: '16px'
  };
  return (
    <div style={{ marginLeft: "auto", position: "relative" }} className="walletDrop">
      <div
        onMouseEnter={() => setShowMenu(true)}
        onMouseLeave={() => setShowMenu(false)}
        style={{
          display: "flex",
          gap: 4,
          background: "none",
          border: "none",
          fontSize: 14,
          cursor: "pointer",
          color: pathname === "/wallet" ? (darkMode ? "#fff" : "#4231C8") : (darkMode ? "#999" : "#151515"),
        }}
      >
        <div style={{ fontSize: 14 }}>Wallet</div>
        <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 28 28" data-testid="KeyboardArrowDownIcon"><path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6z"></path></svg>
      </div>
      {showMenu && (
        <div
          onMouseEnter={() => setShowMenu(true)}
          onMouseLeave={() => setShowMenu(false)}
          style={{
            width: 220,
            backgroundColor: "white",
            paddingTop: 4,
            paddingBottom: 4,
            position: "absolute",
            boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)'
          }}>

          <Link to="/wallet/deposit">
            <div style={{ display: "flex", gap: 4 }} className="menuItem">
              <img src={p1} alt="icon" className="profileDropIcons" />
              <div>Deposit</div>
            </div>
          </Link>
          <Link to="/wallet/withdrawal">
            <div style={{ display: "flex", gap: 4 }} className="menuItem">
              <img src={p2} alt="icon" className="profileDropIcons" />
              <div>Withdraw</div>
            </div>
          </Link>
          <Link to="#">
            <div style={{ display: "flex", gap: 4 }} className="menuItem">
              <img src={p3} alt="icon" className="profileDropIcons" />
              <div>Transaction History</div>
            </div>
          </Link>

        </div>
      )}
    </div>
  );
};



export default WalletSelect;
