import React, { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

export const NotificationPreferences = [
  {
    title: "Agree to the collection and use of personal information (optional)",
    terms: "View terms",
    conditions: [
      { name: "I agree", selected: true },
      { name: "I do not agree", selected: false },
    ],
  },
  {
    title: "Email preferences setting ",
    terms: "View terms",
    conditions: [
      { name: "Received", selected: true },
      { name: "Do not receive", selected: false },
    ],
  },
  {
    title: "SMS preference setting",
    terms: "View terms",
    conditions: [
      { name: "I agree", selected: true },
      { name: "I do not agree", selected: false },
    ],
  },
];

// ... (other imports and constants)

const RadioButtons = () => {
    const [notificationPreferences, setNotificationPreferences] = useState(NotificationPreferences);
  
    const handleRadioClick = (index, subIndex) => {
      const updatedPreferences = [...notificationPreferences];
      updatedPreferences[index].conditions[subIndex].selected = !updatedPreferences[index].conditions[subIndex].selected;
      setNotificationPreferences(updatedPreferences);
    };
  
    return (
      <div>
        {notificationPreferences?.map((item, index) => (
          <div key={index} className="col-12">
            <div className="mb-1 notify-titleTop">
              <span className="notify-title">
                {item?.title}
                <span className="terms-text cursorPointer">{item?.terms}</span>
              </span>
            </div>
            {item?.conditions?.map((subItem, subIndex) => {
              const uniqueId = `condition_${index}_${subIndex}`;
              const labelId = `label_${uniqueId}`;
  
              return (
                <div className="cursorPointer" key={uniqueId}>
                  <FormControlLabel
                  className="checkLabelset"
                    value={subItem.name}
                    control={
                      <Radio
                        id={labelId}
                        checked={subItem.selected}
                        sx={{
                          color: "#4231C8",
                          "&.Mui-checked": {
                            color: "#4231C8",
                          },
                        }}
                        onClick={() => handleRadioClick(index, subIndex)} // Move the click handling to Radio component
                      />
                    }
                    label={subItem?.name}
                    
                  />
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  };
  
  export default RadioButtons;
  