import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import ecips from "../../../assets/exlipPattern.png";
import mobile from "../../../assets/mobile.svg";
import Layout from "../../layouts/Layout";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../../ThemeContext";
import { useLocation } from 'react-router-dom';
import { NavLink, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';


import axios from "axios";
import url from "../../../config";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 6,
  width: 19,
  color: "#4231C8",
  height: 19,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px #4231C8"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto #4231C8",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#4231C8",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 19,
    height: 19,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}
const VarifyCode = () => {

  const location = useLocation();

  const email = location.state.email;
  const from = location.state.from;
  console.log(from, " this is from")
  const navigate = useNavigate();


  const { darkMode } = useContext(DarkModeContext)
  const [check, setcheck] = useState(false);
  const [codeError, setCodeError] = useState('')
  const [codeSuccess, setCodeSuccess] = useState('')
  const [resendSuccess, setResendSuccess] = useState('')

const [verificationSuccess, setVerificationSuccess] = useState("")

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",

      border: "1px solid #4231C8",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  const [otpCode, setOtpCode] = useState();

  const otpCodeHandler = (value) => {
    // Ensure the value doesn't exceed maxLength
    const maxLength = 6;
    const truncatedValue = value.slice(0, maxLength);
    setOtpCode(truncatedValue);
  };


  const resendOTP = async () => {


    const data = {
      email: email
    }
    const response = await axios.post(`${url}api/v1/user-auth/resend-otp/`, data, {
    });

    const accSussesMsg = "OTP SENT !"

    if (response.status == 200 || response.status == 201) {
      // Swal.fire({
      //   icon: 'success',
      //   title: 'Success',
      //   text: accSussesMsg, // Change to your data property
      // })
      setResendSuccess("OTP SENT !")
      setTimeout(() => {
        setResendSuccess("")
      }, 5000)
    }

  };

  const { state } = useLocation();
  const otpCodeFormHandler = async () => {
    if (otpCode) {
      console.log(otpCode, "here's the value for api Call")
    }
    if (from == "forgotpassword") {
      try {
        console.log("forgotpassword is running")

        const requestData = {
          email: email, // Replace with the actual email
          otp: otpCode // Replace with the actual OTP
        };

        const response = await axios.post(`${url}api/v1/user-auth/verify-reset-password-otp/`, requestData, {

        });
        if (response.status == 200 || response.status == 201) {

          setCodeSuccess("OTP Verification Successful !");
          setTimeout(() => {
            setCodeSuccess("")
            navigate('/resetpassword', { state: { email: email } });    
          }, 5000);


        }
        console.log('Verify OTP response:', response);
        // Handle success response as needed
      } catch (error) {
        if (error.response.status === 404 || 400) {
          setCodeError("Code is Invalid !")
          setTimeout(() => {
            setCodeError("")  
          }, 5000);
        }
      }
    } else {
      try {

        const data = {
          email: email,
          otp: otpCode
        }
        console.log(email, " this is response email");
        console.log(otpCode, " this is otpcode");
        const response = await axios.post(`${url}api/v1/user-auth/verify-email/`, data, {

        });

        if (response.status == 200 || response.status) {
          // Swal.fire({
          //   icon: 'success',
          //   title: 'Success',
          //   text: accSussesMsg, // Change to your data property
          // }).then(() => {
          //   navigate("/login")
          // })
          setVerificationSuccess("User Verification Succesful , You can now Login !")
          setTimeout(() => {
            setVerificationSuccess("")
            navigate("/login")
          }, 5000)
        }
        console.log('Verify Email Response:', response);
        // Handle successful response here, e.g., show success message to the user
      } catch (error) {
        if (error.response.status === 404 || 400) {
          setCodeError("Code is Invalid !")
        }
      }
    }

  }


  return (
    <Layout>
      <Stack sx={{ bgcolor: darkMode ? "#232323" : "#FAFAFA", pb: 12 }}>
        <Container className="manageContainer">
          <Grid container spacing={2} xs={12}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack
                direction="column"
                sx={{
                  paddingTop: { xs: 15, md: 0 },
                  width: { xs: "100%", md: "50%" },
                  mt: { md: 25, sm: 0, xm: 0 },
                  mb: 10,
                }}
              >
                <Link to="/signup" className="backDiv">
                  <Stack direction="row" alignItems="center" mb={1}>
                    <KeyboardArrowLeftIcon className="backArrow" style={{ color: darkMode && "#e1e1e1", paddingRight: 6 }} />
                    <Typography style={{ color: darkMode && "#e1e1e1" }} variant="body2" className="backBtn">Back </Typography>
                  </Stack>
                </Link>

                <Typography
                  variant="h3"
                  sx={{
                    color: darkMode ? "#e1e1e1" : "#4231C8",
                    fontWeight: 600,
                  }}
                  className="supportTitle"
                >
                  Verify code
                </Typography>
                <div className="formInputDivs mb-4" style={{ marginTop: 40 }}>
                  <label htmlFor="textInput" style={{ color: darkMode ? "#e1e1e1" : "#282828" }} className="labelTxt mb-2">
                    Enter Code
                  </label>
                  <input
                    style={{ border: codeError && '1px solid red', WebkitAppearance: 'textfield' }}
                    className="form-control textInput formInputs"
                    type="number" max={6} maxLength={6} id="referCode"
                    onChange={(e) => { otpCodeHandler(e.target.value) }}
                    value={otpCode}
                  />
                  
                  {resendSuccess && <Typography color="green">{resendSuccess}</Typography>}
                  {verificationSuccess && <Typography color="green">{verificationSuccess}</Typography>}
                  {codeSuccess && <Typography color="green">{codeSuccess}</Typography>}
                  {codeError && <Typography color="error">{codeError}</Typography>}

                </div>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    mt: 1,
                    mb: 5,

                  }}
                >
                  <Typography variant="body2" className="resendCode" color={darkMode ? "#e1e1e1" : "#282828"}>
                    Didn’t receive a code?{" "}
                  </Typography>
                  <Typography variant="body2" className="resendCode resendCode1"
                    sx={{ color: darkMode ? "#e1e1e1" : "#4231C8", paddingLeft: '4px', textDecoration: "underline" }}
                    onClick={resendOTP}

                  >
                    Resend
                  </Typography>
                </Box>
                <Button
                  sx={{
                    width: 420,
                    height: 50,
                    textTransform: "capitalize",
                    bgcolor: "#4231C8",
                    "&:hover": { bgcolor: "#4231C8" },
                  }}
                  variant="contained"
                  className="formBtn"
                  onClick={otpCodeFormHandler}
                >
                  Verify
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Stack>
    </Layout>
  );
};

export default VarifyCode;
