import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import noMtch from "../../assets/notFound.png";
import CoinBtn from "../layouts/CoinBtn";
import DatePicker from "../layouts/DatePicker";
import Layout from "../layouts/Layout";
import TransactioHistoryTable from "../layouts/TransactioHistoryTable";
import { Table, TableHead, TableRow, TableCell } from "@mui/material"; // Import the necessary components
import { coinsArray, transactionColumn2 } from "../constants/Data";
import { DarkModeContext } from "../../ThemeContext";

const AllOrders = () => {
  const location = useLocation();
  const [value, setvalue] = useState(0);
  const { darkMode } = useContext(DarkModeContext)
  useEffect(() => {
    if (location.pathname == "/trade/history") setvalue(2);
    else if (location.pathname == "/order/history") setvalue(1);
    else if (location.pathname == "/open/orders") setvalue(0);
  }, [location]);

  return (
    <Layout>
      <Container className="manageContainer" sx={{ mt: 16, mb: 14 }}>
        <Stack>
          <Typography
            variant="h3"
            className="supportTitle orderTitle"
            sx={{ fontWeight: 800, color: darkMode ? "#e1e1e1" : "#4231C8", mb: 10 }}
          >
            Orders
          </Typography>
          <Box 
          // borderBottom="1px solid #4231C8" 
          pb={2} mb={2} className="manageTable">
            <Stack
              className="tableRowHe"
              direction="row"
              alignItems="center"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                onClick={() => setvalue(0)}
                sx={{
                  color: "#646464",
                  backgroundColor: "#f5f5f5",
                  cursor: "pointer",
                  ...(value === 0 && {
                    backgroundColor: "#4231C8",
                    color: "white",
                    fontWeight: 600,
                  }),
                }}
                className="tableHeadTxt"
                style={{ flexGrow: 1, textAlign: "center", paddingTop: 12, paddingBottom: 12 }}
              >
                Open Orders
              </Typography>
              <Typography
                onClick={() => setvalue(1)}
                sx={{
                  color: "#646464",
                  backgroundColor: "#f5f5f5",
                  cursor: "pointer",
                  ...(value === 1 && {
                    backgroundColor: "#4231C8",
                    color: "white",
                    fontWeight: 600,
                  }),
                }}
                className="tableHeadTxt"
                style={{ flexGrow: 1, textAlign: "center", paddingTop: 12, paddingBottom: 12 }}
              >
                Order History
              </Typography>
              <Typography
                onClick={() => setvalue(2)}
                sx={{
                  color: "#646464",
                  backgroundColor: "#f5f5f5",
                  cursor: "pointer",
                  ...(value === 2 && {
                    backgroundColor: "#4231C8",
                    color: "white",
                    fontWeight: 600,
                  }),
                }}
                className="tableHeadTxt"
                style={{ flexGrow: 1, textAlign: "center", paddingTop: 12, paddingBottom: 12 }}
              >
                Trade History
              </Typography>
            </Stack>
          </Box>
          {/* {value != 0 && (
            <Stack
              sx={{
                display: "flex",
                flexDirection: { md: "row", xs: "column" },
                alignItems: "center",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <CoinBtn
                name="Trading pair"
                list={coinsArray}
                getValue={(value) => { }}
              /> */}
          {/* <Box width={{ md: "30%", xs: "100%" }}>
              <InputLabel
                shrink
                htmlFor="email"
                sx={{
                  color: "#4231C8",
                  fontSize: "24px",
                  fontWeight: 400,
                }}
              >
                Coin name
              </InputLabel>
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  textTransform: "capitalize",
                  height: 47,
                  width: "100%",
                  bgcolor: "#fff",
                  border: "1px solid #4231C8",
                  color: "#4231C8",
                  "&:hover": { bgcolor: "#fff" },
                }}
              >
                <Box
                  marginRight="auto"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Typography fontWeight={800}>{fname}</Typography>
                  <Typography color="#999999" ml={1}>
                    {lname}
                  </Typography>
                </Box>
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  ariLabelledby: "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => handleClose("bcoin", "BTC", "All")}
                  disableRipple
                >
                  <Typography fontWeight={800}>All</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => handleClose("bcoin", "BTC", "Bitcoin")}
                  disableRipple
                >
                  <Typography fontWeight={800}>BTC</Typography>
                  <Typography color="#999999" ml={1}>
                    Bitcoin
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => handleClose("ecoin", "ETH", "Ethereum")}
                  disableRipple
                >
                  <Typography fontWeight={800}>ETH</Typography>
                  <Typography color="#999999" ml={1}>
                    Ethereum
                  </Typography>
                </MenuItem>

                <MenuItem
                  onClick={() => handleClose("pcoin", "PVT", "P20 coin")}
                  disableRipple
                >
                  <Typography fontWeight={800}>PVT </Typography>
                  <Typography color="#999999" ml={1}>
                    P20 coin
                  </Typography>
                </MenuItem>

                <MenuItem
                  onClick={() => handleClose("dcoin", "USDC", "USD coin")}
                  disableRipple
                >
                  <Typography fontWeight={800}>USDC </Typography>
                  <Typography color="#999999" ml={1}>
                    USD coin
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => handleClose("tcoin", "TRX", "Tron")}
                  disableRipple
                >
                  <Typography fontWeight={800}>TRX </Typography>
                  <Typography color="#999999" ml={1}>
                    Tron
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => handleClose("bicoin", "BUSD", "Binance")}
                  disableRipple
                >
                  <Typography fontWeight={800}>BUSD </Typography>
                  <Typography color="#999999" ml={1}>
                    Binance
                  </Typography>
                </MenuItem>
              </StyledMenu>
            </Box> */}

          {/* <Typography
                sx={{ position: "relative", mt: "24px", fontSize: "26px" }}
              >
                /
              </Typography>

              <CoinBtn name="12" list={coinsArray} getValue={(value) => { }} />

              <Box width={{ md: "30%", xs: "100%" }}>
                <DatePicker name="pad" getValue={() => { }} />
              </Box> */}

          {/* <Box width={{ md: "30%", xs: "100%" }} mt={{ md: 0, xs: 1 }}>
              <InputLabel
                shrink
                htmlFor="email"
                sx={{
                  color: "#4231C8",
                  fontSize: "24px",
                  fontWeight: 400,
                }}
              >
                Deposit/Withdrawal
              </InputLabel>
              <Button
                id="demo-customized-button1"
                aria-controls={open2 ? "demo-customized-menu1" : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick1}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  textTransform: "capitalize",
                  height: 47,
                  width: "100%",
                  bgcolor: "#fff",
                  border: "1px solid #4231C8",
                  color: "#4231C8",
                  "&:hover": { bgcolor: "#fff" },
                }}
              >
                <Box
                  marginRight="auto"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Typography fontWeight={800} color="#000000">
                    All
                  </Typography>
                </Box>
              </Button>
              <StyledMenu
                id="demo-customized-menu1"
                MenuListProps={{
                  ariLabelledby: "demo-customized-button1",
                }}
                anchorE2={anchorE2}
                open={open2}
                onClose={handleClose1}
              >
                <MenuItem
                  onClick={() => handleClose1("a", "b", "All")}
                  disableRipple
                >
                  <Typography fontWeight={800}>All</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => handleClose1("a", "b", "Deposit")}
                  disableRipple
                >
                  <Typography fontWeight={800}>Deposit</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => handleClose1("a", "b", "Withdraw")}
                  disableRipple
                >
                  <Typography fontWeight={800}>Withdraw</Typography>
                </MenuItem>
              </StyledMenu>
            </Box> */}
          {/* </Stack> */}
          {/* // )} */}
        </Stack>
        {/* {value != 0 && (
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 3,
              paddingBottom: 3,
              borderBottom: "1px solid #4231C8",
              flexWrap: "wrap",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Button
                variant="contained"
                href="#outlined-buttons"
                sx={{
                  color: "#ffffff",
                  border: "1px solid #4231C8",
                  textTransform: "capitalize",
                  bgcolor: "#4231C8",
                  width: 100,
                }}
              >
                Search
              </Button>
            </Stack>
            <Stack direction="row" spacing={5}>
              <Button
                sx={{
                  bgcolor: "#FAFAFA",
                  color: "#4231C8",
                  textTransform: "capitalize",
                  fontWeight: 500,
                  padding: "10px 30px 10px 30px",
                }}
              >
                Download CV
              </Button>
              <Button
                sx={{
                  bgcolor: "#FAFAFA",
                  color: "#4231C8",
                  textTransform: "capitalize",
                  fontWeight: 500,
                  padding: "10px 30px 10px 30px",
                }}
              >
                Reset
              </Button>
            </Stack>
          </Stack>
        )} */}
        <div className="orderTableMain">
          <Table >
            <TableHead>
              <TableRow>
                {Object.keys(transactionColumn2[0]).map((key) => (
                  <TableCell className="tableHeadeTxtTh" key={key}>{transactionColumn2[0][key]}</TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
        </div>


        {/* <Container>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              mt: 4,
              overflowX: "auto",
              gap: 2,
            }}
          >
            <Typography variant="body1" sx={{ color: "#999999" }}>
              Side
            </Typography>
            <Typography variant="body1" sx={{ color: "#999999" }}>
              Market
            </Typography>
            <Typography variant="body1" sx={{ color: "#999999" }}>
              Type
            </Typography>
            <Typography variant="body1" sx={{ color: "#999999" }}>
              Status
            </Typography>
            <Typography variant="body1" sx={{ color: "#999999" }}>
              Price
            </Typography>
            <Typography variant="body1" sx={{ color: "#999999" }}>
              Quantity
            </Typography>
            <Typography variant="body1" sx={{ color: "#999999" }}>
              Field
            </Typography>
            <Typography variant="body1" sx={{ color: "#999999" }}>
              Total
            </Typography>
            <Typography variant="body1" sx={{ color: "#999999" }}>
              Date (UTC +5)
            </Typography>
          </Stack>
        </Container> */}
        <Stack direction="row" justifyContent="center" mt={5}>
          <img src={noMtch} alt="s" width="200px" className="notFoundImg" />
        </Stack>
      </Container>
    </Layout>
  );
};

export default AllOrders;
