import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import ecips from "../../../assets/exlipPattern.png";
import mobile from "../../../assets/mobile.svg";
import Layout from "../../layouts/Layout";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Menu, MenuItem, ListItemIcon } from "@mui/material";
import { DarkModeContext } from "../../../ThemeContext";
const languages = [
  { code: "en", label: "Withdrawal Fees" },
  { code: "es", label: "Withdrawal Fees" },
  // Add more languages as needed
];
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 6,
  width: 19,
  color: "#1D2F6F",
  height: 19,
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#1D2F6F",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 19,
    height: 19,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

const ContactUs = () => {
  const { darkMode } = useContext(DarkModeContext)
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("Withdrawal Fees");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (languageCode) => {
    setSelectedLanguage(languageCode);
    handleClose();
    // Add your logic for changing the language here
  };
  const [check, setcheck] = useState(false);
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      color: darkMode && "#e1e1e1",
      border: darkMode ? "1px solid #888" : "1px solid #1D2F6F",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  }));
  return (
    <Layout>
      <Stack className="contactUsCont" sx={{ bgcolor: darkMode ? "#282828" : "#FAFAFA", pb: 8 }}>
        <Container className="manageContainer">
          <Grid container spacing={2} xs={12}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack
                direction="column"
                className="contactForm"
                sx={{
                  paddingTop: { xs: 15, md: 0 },
                  width: { xs: "100%", md: "70%" },
                  mt: { md: 10, sm: 0, xm: 0 },
                  mb: 2,
                }}
              >
                <Typography
                  style={{ color: darkMode && "#e1e1e1" }}
                  className="supportTitle supportTitleContact" pb={5}
                >
                  Contact Us
                </Typography>
                <Box
                  className="formBox"
                  sx={{
                    border: "1px solid #99999933",
                    width: "100%",
                    padding: 4,
                    borderRadius: "10px",
                  }}
                >
                  <FormControl fullWidth variant="standard" sx={{ mt: 1 }}>
                    <InputLabel
                      sx={{
                        "&.Mui-focused": {
                          color: darkMode ? "#fff" : "#232323", // Maintain original color on focus
                        },
                      }}
                      style={{ color: darkMode && "#e1e1e1" }}
                      shrink
                      htmlFor="text"
                      className="labelTxt"
                    >
                      Name
                    </InputLabel>
                    <BootstrapInput className="formInput" defaultValue="" id="name" />
                  </FormControl>
                  <FormControl fullWidth variant="standard" sx={{ mt: 3 }}>
                    <InputLabel
                      sx={{
                        "&.Mui-focused": {
                          color: darkMode ? "#fff" : "#232323", // Maintain original color on focus
                        },
                      }}
                      style={{ color: darkMode && "#e1e1e1" }}
                      shrink
                      htmlFor="email"
                      className="labelTxt"
                    >
                      Email
                    </InputLabel>
                    <BootstrapInput className="formInput" defaultValue="" id="email" />
                  </FormControl>
                  <FormControl fullWidth variant="standard" sx={{ mt: 5 }}>
                    <InputLabel
                      style={{ color: darkMode && "#e1e1e1" }}
                      shrink
                      htmlFor="email"
                      className="labelTxt"
                      sx={{ mt: "-25px" }}
                    >
                      What Issue are you facing?
                    </InputLabel>
                    <Button
                      style={{ border: darkMode ? "1px solid #888" : "1px solid #000", color: darkMode ? "#e1e1e1" : "#323232" }}
                      aria-controls="language-menu"
                      aria-haspopup="true"
                      variant="outlined"
                      onClick={handleClick}
                      endIcon={<KeyboardArrowDownIcon />}
                      className="feeDrop"
                    >
                      {selectedLanguage}
                    </Button>
                    <Menu
                      id="language-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {languages.map((language) => (
                        <MenuItem
                          key={language.label}
                          onClick={() => handleLanguageSelect(language.label)}
                          sx={{ width: 200 }}
                        >
                          <Typography className="selectMenu" variant="body1" sx={{ ml: 2, color: darkMode && "#e1e1e1" }}>
                            <b>{language.label}</b>
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </FormControl>
                  <FormControl
                    height={40}
                    fullWidth variant="standard" sx={{ mt: 5 }}
                  >
                    <InputLabel
                      style={{ color: darkMode && "#e1e1e1" }}
                      shrink
                      htmlFor="email"
                      className="labelTxt"
                      sx={{ mt: "-23px" }}
                    >
                      Message
                    </InputLabel>

                    <TextField
                      sx={{
                        outline: 'none',
                        '& .MuiInputBase-input': { // Target the input element specifically
                          color: darkMode ? "#fff" : "#000",
                        },
                        // '&:focus': {
                        //   border: darkMode ? "1px solid #888" : "1px solid #1D2F6F",
                        //   outline: 'none',
                        //   boxShadow: 'none'
                        // },
                        // border: darkMode ? "1px solid #888" : "1px solid #1D2F6F",
                        '& fieldset': { // Target the fieldset for focus styling
                          borderRadius: '4px', // Optional: Add a subtle rounded corner
                          border: 'none', // Remove default border on focus
                          border: darkMode ? "1px solid #888" : "1px solid #1D2F6F",
                          boxShadow: '0px 0px 0px 0px rgba(136, 136, 136), 0px 0px 0px rgba(255, 255, 255, 1)',
                        },
                      }}
                      className="textInputText"
                      width="100%"
                      multiline
                      variant="outlined"
                      rows={8}
                      maxRows={15}
                    />
                  </FormControl>
                  <Stack direction="row" justifyContent="start">
                    <Button
                      className="btnText btnTextContact" id="btnTextContact"
                      sx={{
                        width: 190,
                        mt: 6,
                        height: 50,
                        textTransform: "capitalize",
                        bgcolor: "#4231c8",
                        "&:hover": { bgcolor: "#4231c8" },
                      }}
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Stack>
    </Layout >
  );
};

export default ContactUs;
