import React from "react";
import Layout from "../../layouts/Layout";
import { Container, Stack, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Container className="manageContainer">
        <Stack className="termMainSection" direction="column" mt={10} mb={10} gap={2}>
          <Typography variant="h3" color="#4231C8" className="supportTitle">
            Privacy Policy
          </Typography>

          {/* <Typography className="acceptP" variant="h6" color="#4231C8">
            1. ACCEPTANCE
          </Typography> */}
          <Typography variant="body2" color="#151515" fontWeight={400}>
          At ALT-X, we prioritize the protection of your privacy and the
security of your personal information. This Privacy Policy aims to
provide you with comprehensive information on how we collect,
use, disclose, and safeguard your personal information when you
interact with our website and utilize our services.
          </Typography>
          <Typography variant="h6" color="#4231C8" fontWeight={800}>
          Information We Collect 
          </Typography>
          <Typography mb={2}>We collect various types of personal information from you to
            facilitate the provision of our services and to ensure compliance
            with legal and regulatory requirements. This may include: </Typography>
          
            <ul className="privacyPoliceDescUl">
              <li className="privacyPoliceDesc"><span>Identification Information:</span> Your name, contact details, and
                photographic identification.</li>
                <li className="privacyPoliceDesc"><span>Financial Information:</span> Details from your identification
                documents (e.g., driver's license, passport), including number,
                dates of issue and expiration, photographic identification, and
                address.</li>
                <li className="privacyPoliceDesc"><span>Transactional Information:</span> Details of your trades and
                transaction history.</li>
                <li className="privacyPoliceDesc"><span>Technical Information:</span> Your IP address, browser type,
                operating system information, and browsing activity on our website.</li>

                            </ul>
                            <Typography mb={2}>We collect this information when you register for an account, use
                our services, or interact with our website.</Typography>
                          
                          <Typography variant="h6" color="#4231C8" fontWeight={800}>
                          Use of Information
                          </Typography>
                          <Typography mb={2}>We use the personal information we collect for various purposes,
                including but not limited to:</Typography>
                <ul className="privacyPoliceDescUl">
                <li className="privacyPoliceDesc"><span>Service Provision:</span> Providing and maintaining our services,
                including verifying your identity and processing transactions.</li>
                <li className="privacyPoliceDesc"><span>Communication:</span> Communicating with you, responding to
                inquiries, and providing customer support.</li>

                <li className="privacyPoliceDesc"><span>Improvement:</span> : Improving our services, website functionality,
                and user experience.</li>

                <li className="privacyPoliceDesc"><span>Compliance:</span> Ensuring compliance with legal and regulatory
                obligations, such as anti-money laundering and know-yourcustomer requirements.</li>

                <li className="privacyPoliceDesc"><span>Marketing:</span> Sending you promotional materials and offers, if
                you have opted in to receive them.</li>

                            </ul>
                            <Typography variant="h6" color="#4231C8" fontWeight={800}>
                          Cookies
                          </Typography>
                          <Typography mb={2}>We may use cookies and similar tracking technologies to
                collect information about your browsing activities on our
                website. You can adjust your browser settings to disable
                cookies, but this may affect your ability to access certain
                features of our website.</Typography>
                <Typography variant="h6" color="#4231C8" fontWeight={800}>
                Disclosure of Personal Information
                          </Typography>
                          <Typography mb={2}>At ALT-X, we handle your Personal Information with the utmost care
                and respect for your privacy. We utilize the Personal Information
                you provide for the purposes specified at the time of collection, as
                well as those outlined in this Privacy Policy, and as permitted by
                law. Your information may be shared with our affiliates, agents,
                representatives, trusted service providers, and contractors for
                limited purposes, with your explicit consent or during the provision
                of requested services. Additionally, in the event of a merger,
                divestiture, or corporate reorganization, your Personal Information
                may be shared with financial institutions, insurance companies, or
                other entities involved, with notification provided to you as
                applicable to exercise your rights. In certain circumstances, we may
                also disclose Users' Personal Information to law enforcement or
                regulatory agencies as required by law. While legal restrictions may
                prevent us from informing you of such disclosures, we ensure that
                any third party receiving or accessing Personal Information is bound
                by contractual obligations to protect and use the information solely
                for the intended purposes. These third parties are required to
                adhere to the same security and confidentiality policies as ALT-X and assume corresponding responsibilities. Additionally, any
                legitimate exercise of your rights with ALT-X will be communicated
                to and enforced by third parties with access to your Personal
                Information.</Typography>
                <Typography mb={2}>Rest assured, ALT-X remains vigilant in ensuring that all third
                parties are fully aware of our obligations under this Privacy Policy,
                and we establish contractual agreements to uphold the same level
                of protection for your Personal Information as mandated by
                applicable data protection laws.</Typography>
                <Typography variant="h6" color="#4231C8" fontWeight={800}>
                Your Privacy Rights
                          </Typography>
                          <Typography mb={2}>ALT-X prioritizes transparency and empowering users with control
                over their personal information. As a user, you hold certain rights
                concerning your data, and we are committed to upholding them.
                You can access your Personal Information held by ALT-X and
                request corrections or updates to any inaccuracies. Simply reach
                out via our email or utilize your account profile page (if applicable).
                Upon your written request, we'll provide details about the Personal
                Information we hold, its usage, and disclosure. We'll also furnish
                you with a copy of your retained Personal Information. Please note,
                a minimal charge may apply for additional copies to cover
                administrative costs. If you wish to delete or destroy both your
                Account and Personal Information, email us. We’ll promptly act on
                your request, unless such action contradicts our legal or regulatory
                obligations. You have the right to request the transfer of your
                Personal Information to another controller of your choosing. We'll
                facilitate this transfer upon request. For security purposes, we may
                need to verify your identity before assisting with any rights
                exercises. This could entail providing identification documentation
                or alternative verification methods. ALT-X may utilize automated
                processing and profiling to mitigate fraud, money laundering, and
                service abuse risks.</Typography>
                <Typography variant="h6" color="#4231C8" fontWeight={800}>
                            Security Measures
                          </Typography>
                          <Typography mb={2}>We take the security of your Personal Information seriously.
Rigorous technical and organizational security measures are in
place to safeguard your data from loss, misuse, alteration, or
destruction. Access to your information is restricted to authorized
personnel only, who are bound by strict confidentiality obligations. In
the event of a personal data breach or security lapse, we'll promptly
notify you in accordance with relevant laws and regulations.</Typography>
<Typography variant="h6" color="#4231C8" fontWeight={800}>
                Third-Party Links
                          </Typography>
                          <Typography mb={2}>While navigating ALT-X, you may encounter links to external sites
and resources provided by third parties. Please note that our
Privacy Policy applies solely to our platform. Visiting these thirdparty sites requires leaving our site, and we want to remind you that
ALT-X does not govern these external sites or their content. By
accessing them, you acknowledge that we hold no responsibility or
liability for any content, policies, promotions, products, services, or
actions of these third-party sites. We encourage you to thoroughly
review the policies, rules, terms, and regulations, including privacy
policies, of any site you visit.</Typography>
<Typography variant="h6" color="#4231C8" fontWeight={800}>
Retention of Personal Information
                          </Typography>
                          <Typography mb={2}>Your information is securely stored within ALT-X servers. Our
employees, whether located within or outside the European Union,
adhere to stringent data security principles. We retain your Personal
Information only for the duration necessary to fulfill the purposes
outlined in our Privacy Policy and comply with legal and regulatory
requirements. In accordance with Anti-Money Laundering, Tax, and
Company legal obligations, we retain Accounts and Personal
Information for a minimum of ten years after an account closure.
Any data stored solely for regulatory purposes is protected from
unnecessary processing and retained solely for providing
information or access to relevant authorities.</Typography>
<Typography variant="h6" color="#4231C8" fontWeight={800}>
Disposal of Personal Information
                          </Typography>
                          <Typography mb={2}>Upon fulfilling our service obligations or legal requirements, we
anonymize or dispose of your Personal Information in accordance
with industry and security standards. If certain records cannot be
directly removed, such as in archived backups, we maintain a log of
Personal Information to be removed if backup data is ever restored.</Typography>
<Typography variant="h6" color="#4231C8" fontWeight={800}>
Marketing
                          </Typography>
                          <Typography mb={2}>We seek your consent for marketing purposes, both during
registration and post-registration. You can manage your marketing
preferences within your account profile or by contacting us at any
time. Your right to prevent such processing is respected, and you
can opt out of marketing communications by unchecking marketing
preferences checkboxes or reaching out to us via email.</Typography>
<Typography variant="h6" color="#4231C8" fontWeight={800}>
Data Protection Officer
                          </Typography>
                          <Typography mb={2}>Our Data Protection Officer ensures ALT-X's adherence to our
privacy policy and serves as the main contact for our Data
Protection Supervisory Authority. For any privacy-related inquiries or
complaints, you can contact our Data Protection Officer.</Typography>

<Typography variant="h6" color="#4231C8" fontWeight={800}>
Data Protection Supervisory Authority
                          </Typography>
                          <Typography mb={2}>If you believe ALT-X is not adhering to the terms of our Privacy
Policy or wish to file a complaint, you can contact the appropriate
Data Protection Supervisory Authority.</Typography>
        </Stack>
      </Container>
    </Layout>
  );
};

export default PrivacyPolicy;
