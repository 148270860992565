import React, { useContext } from 'react'
import { DarkModeContext } from '../../../../../ThemeContext'


const MissingDeposits = () => {
  const { darkMode } = useContext(DarkModeContext)
  return (
    <>
      <h2 style={{ color: darkMode && "#e1e1e1" }} className="guideHeading">Missing Deposits and Incorrect Token or Address Information</h2>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        At ALT-X Exchange, we understand the importance of ensuring
        smooth and efficient transactions for our users. However,
        sometimes issues may arise, such as missing deposits or deposits
        made with incorrect token or address information. Here's how to
        handle such situations:
      </p>
      <h3 style={{ color: darkMode ? "#e1e1e1" : "#4231c8", fontSize: 20, fontWeight: "600", marginBottom: 20 }} className="guideSubHeading">
        Missing Deposits:
      </h3>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        <span style={{ fontWeight: "600", color: "#1155CC"}}>Check Blockchain Confirmations: </span>
        If you've initiated a deposit but
        haven't received the funds in your ALT-X Exchange account, first
        check the blockchain explorer for confirmations. Depending on the
        blockchain network congestion, it may take some time for
        transactions to be confirmed.
      </p>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        <span style={{ fontWeight: "600", color: "#1155CC"}}>Verify Transaction Details: </span>
        Ensure that you've entered the correct
        deposit address and amount when initiating the transaction. Even a
        minor error in the address can result in funds being sent to the
        wrong destination.
      </p>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        <span style={{ fontWeight: "600", color: "#1155CC"}}>Contact Customer Support: </span>
        If you've waited for a reasonable
        amount of time and still haven't received your deposit, reach out to
        ALT-X Exchange's customer support team. Provide them with
        details such as the transaction ID, deposit amount, and deposit
        address for assistance in resolving the issue.
      </p>
      <h3 style={{ color: darkMode ? "#e1e1e1" : "#4231c8", fontSize: 20, fontWeight: "600", marginBottom: 20 }} className="guideSubHeading">
        Deposits with Incorrect Token or Address Information:
      </h3>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        <span style={{ fontWeight: "600", color: "#1155CC"}}>Double-Check Transaction Details: </span>
        Before initiating a deposit,
        double-check that you're sending the correct token to the
        corresponding wallet address on ALT-X Exchange. Mixing up tokens
        or addresses can lead to irreversible loss of funds.
      </p>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        <span style={{ fontWeight: "600", color: "#1155CC"}}>Contact Customer Support Immediately: </span>
        If you've accidentally
        sent funds to the wrong token address or an incorrect address
        altogether, contact ALT-X Exchange's customer support
        immediately. Include all relevant transaction details and explain the
        situation clearly.
      </p>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        <span style={{ fontWeight: "600", color: "#1155CC"}}>Recovery Options: </span>
        Depending on the nature of the error and the
        blockchain protocol, there may be recovery options available.
        However, it's crucial to act swiftly and seek assistance from ALT-X
        Exchange's support team as soon as possible.
      </p>
      <h3 style={{ color: darkMode ? "#e1e1e1" : "#4231c8", fontSize: 20, fontWeight: "600", marginBottom: 20 }} className="guideSubHeading">
        Preventive Measures:
      </h3>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        <span style={{ fontWeight: "600", color: "#1155CC"}}>Double-Check Before Depositing: </span>
        Take a moment to review all
        transaction details, including token type and destination address,
        before confirming any deposits.
      </p>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        <span style={{ fontWeight: "600", color: "#1155CC"}}>Use Test Transactions: </span>
        When depositing a significant amount or
        transacting with a new token, consider sending a small test
        transaction first to ensure everything is functioning correctly.
      </p>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        <span style={{ fontWeight: "600", color: "#1155CC"}}>Stay Informed: </span>
        Stay updated on any announcements or guidelines
        provided by ALT-X Exchange regarding deposit procedures and
        security measures.
      </p>
    </>
  )
}

export default MissingDeposits