import React, { useContext } from "react";
import img from "../../assets/icon@4x 3.svg";
import logo from "../../assets/logo1@4x 1.svg";
import group11 from "../../assets/social1.svg";
import group12 from "../../assets/social2.svg";
import group13 from "../../assets/social3.svg";
import group14 from "../../assets/social4.svg";
import group15 from "../../assets/social5.svg";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { DarkModeContext } from "../../ThemeContext"
const Footer = () => {
  function scrollToTop() {
    window.scrollTo(0, 0);
  }
  scrollToTop();
  const { darkMode } = useContext(DarkModeContext)
  return (
    <Stack sx={{ position: "relative", backgroundColor: darkMode ? "#282828" : "#FCFCFC" }} direction="column">
      <Grid container spacing={2} xs={12}>
        <Grid item xs={12} md={3} className="footerLogoDiv">
          <Stack direction="column" alignItems="center">
            <img src={logo} className="footerLogo" alt="s" />
            <div className="socialIconDiv">
              <Stack direction="row" spacing={1}>
                <img src={group11} className="socialIcon" alt="s" />
                <img src={group12} className="socialIcon" alt="s" />
                <img src={group13} className="socialIcon" alt="s" />
                <img src={group14} className="socialIcon" alt="s" />
                <img src={group15} className="socialIcon" alt="s" />
              </Stack>
            </div>
          </Stack>
        </Grid>
        <Grid item container xs={12} md={8} spacing={4} sx={{ margin: 1 }}>
          <Grid item xs={6} md={4} className="footerLink">
            <Stack direction="column" alignItems="start" spacing={2}>
              <Typography
                variant="body1"
                sx={{ color: darkMode ? "#fff" : "#4231C8" }}
                className="footerHeader"
              >
                About
              </Typography>
              <Link to="/fee" className="footerHref">
                <Typography variant="body1">Trading Fee</Typography>
              </Link>
              <Link to="/deposit-withdrawals" className="footerHref">
                <Typography variant="body1">
                  Deposit & Withdrawal Fees
                </Typography>
              </Link>
              <Link to="/listing" className="footerHref">
                <Typography variant="body1">Apply for Listing</Typography>
              </Link>

              <Link to="/term-conditions" className="footerHref">
                <Typography variant="body1">Terms and Conditions</Typography>
              </Link>
              <Link to="/privacy-policy" className="footerHref">
                <Typography variant="body1">Privacy Policy</Typography>
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={6} md={4}>
            <Stack direction="column" alignItems="start" spacing={2}>
              <Typography
                variant="body1"
                sx={{ color: darkMode ? "#fff" : "#4231C8" }}
                className="footerHeader"
              >
                Support
              </Typography>
              <Link to="/annoucements" className="footerHref">
                <Typography variant="body1">Announcements</Typography>
              </Link>
              <Link to="/faqs" className="footerHref">
                <Typography variant="body1">FAQ</Typography>
              </Link>
              <Link to="/contact-us" className="footerHref">
                <Typography variant="body1">Contact us</Typography>
              </Link>
              {/* <Link to="/deposit-withdrawals" className="footerHref">
                <Typography variant="body1">Deposit & Withdrawals</Typography>
              </Link> */}
              <Link to="/official-varification" className="footerHref">
                <Typography variant="body1">Official Verification</Typography>
              </Link>
              <Link to="/documentation" className="footerHref">
                <Typography variant="body1">API Documentation</Typography>
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={6} md={2} className="footerLink footerLinkLast">
            <Stack direction="column" alignItems="start" spacing={2}>
              <Typography
                variant="body1"
                sx={{ color: darkMode ? "#fff" : "#4231C8" }}
                className="footerHeader"
              >
                Products
              </Typography>
              <Link to="/exchange" className="footerHref">
                <Typography variant="body1">Exchange</Typography>
              </Link>
            </Stack>
          </Grid>
        </Grid>

        <Grid item xs={6} md={3}></Grid>
      </Grid>
      <Box>
        <Typography
        sx={{color : darkMode ? "#999" : "#323232"}}
          variant="body1"
          className="footerCopyRight"
        >
          Copyright © 2023 Alt-X Company Ltd. All rights reserved.
        </Typography>
      </Box>

      <img src={img} alt="bh" className="backImg" />
    </Stack>
  );
};

export default Footer;
