import React, {useContext} from 'react'
import { DarkModeContext } from '../../../../../ThemeContext'

const WithdrawalFee = () => {
  const {darkMode } = useContext(DarkModeContext)
  return (
    <>
      <h2 style={{color : darkMode && "#e1e1e1"}}className="guideHeading">ALT-X Exchange's Withdrawal Fee</h2>
      <p style={{color : darkMode && "#e1e1e1"}}className="guidePara">
        At ALT-X Exchange, we strive to maintain transparent and
        competitive fee structures to provide our users with a clear
        understanding of the costs associated with withdrawing funds from
        their accounts. Here's an overview of our withdrawal fee structure:
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        1. Fixed Withdrawal Fees:
      </h3>
      <p style={{color : darkMode && "#e1e1e1"}}className="guidePara">
        ALT-X Exchange applies fixed withdrawal fees for each
        cryptocurrency or token supported on our platform. These fees are
        predetermined and remain constant regardless of the withdrawal
        amount.
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        2. Variable Withdrawal Fees:
      </h3>
      <p style={{color : darkMode && "#e1e1e1"}}className="guidePara">
        In addition to fixed withdrawal fees, ALT-X Exchange may also
        apply variable withdrawal fees based on factors such as blockchain
        network congestion and transaction processing speeds. These fees
        can fluctuate in response to changes in network conditions.
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        3. Minimum Withdrawal Amounts:
      </h3>
      <p style={{color : darkMode && "#e1e1e1"}}className="guidePara">
        To optimize transaction efficiency and minimize fees, ALT-X
        Exchange may impose minimum withdrawal amounts for certain
        cryptocurrencies or tokens. Users should ensure their withdrawal
        amount meets or exceeds the minimum threshold to avoid any
        potential issues.
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        4. Fee Display:
      </h3>
      <p style={{color : darkMode && "#e1e1e1"}}className="guidePara">
        ALT-X Exchange displays withdrawal fees prominently on our
        platform, allowing users to review and assess the associated costs
        before initiating a withdrawal. Users can easily access fee
        information within their account settings or during the withdrawal
        process.
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        5. Fee Deduction:
      </h3>
      <p style={{color : darkMode && "#e1e1e1"}}className="guidePara">
        Withdrawal fees are typically deducted from the total withdrawal
        amount before processing. Users should consider these fees when
        planning their withdrawals to ensure the desired amount reaches its
        intended destination.
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        6. Fee Updates:
      </h3>
      <p style={{color : darkMode && "#e1e1e1"}}className="guidePara">
        ALT-X Exchange reserves the right to adjust withdrawal fees in
        response to changes in market conditions, network fees, or
        operational requirements. Any updates to the withdrawal fee
        structure will be communicated to users through our official
        channels and platform notifications.
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        7. Fee Transparency:
      </h3>
      <p style={{color : darkMode && "#e1e1e1"}}className="guidePara">
        We are committed to maintaining transparency regarding
        withdrawal fees and providing users with access to accurate and
        up-to-date fee information. Users can rely on ALT-X Exchange to
        provide a clear breakdown of withdrawal fees and associated costs.
      </p>
      <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        8. Customer Support:
      </h3>
      <p style={{color : darkMode && "#e1e1e1"}}className="guidePara">
        If users have any questions or concerns regarding withdrawal fees
        or encounter any discrepancies, our dedicated customer support
        team is available to assist. Users can reach out to our support team
        for prompt and personalized assistance.
      </p>
    </>
  )
}

export default WithdrawalFee