import React, { useState } from "react";
import Layout from "../layouts/Layout";
import {
  Box,
  Container,
  FormControl,
  InputBase,
  InputLabel,
  Stack,
  Typography,
  alpha,
} from "@mui/material";
import styled from "@emotion/styled";
import { Button, Menu, MenuItem, ListItemIcon } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language"; // Import the language icon of your choice
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import pcoin from "../../assets/bnb.png";
import eth from "../../assets/eth.png";
import fantom from "../../assets/fantom.png";
import cronos from "../../assets/cronos.png";
import polygon from "../../assets/polygon.png";
import swap from "../../assets/swap.svg";
import swapLine from "../../assets/Line 58.png";
import g11 from "../../assets/g11.png";
import g12 from "../../assets/g12.png";
import g13 from "../../assets/g13.png";
import g14 from "../../assets/g14.png";
import g15 from "../../assets/g15.png";
const languages = [
  { code: "en", label: "Ethereum", icon: eth },
  // { code: "es", label: "P20 Coin", icon: pcoin },
  { code: "fr", label: "Polygon", icon: polygon },
  { code: "fr", label: "Fantom", icon: fantom },
  { code: "fr", label: "Cronos", icon: cronos },
  // Add more languages as needed
];
const languages1 = [
  { code: "en", label: "Ethereum", icon: eth },
  // { code: "es", label: "P20 Coin", icon: pcoin },
  { code: "fr", label: "Polygon", icon: polygon },
  { code: "fr", label: "Fantom", icon: fantom },
  { code: "fr", label: "Cronos", icon: cronos },
  // Add more languages as needed
];
const Swap = () => {
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("Select");
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [selectedLanguage1, setSelectedLanguage1] = useState("Select");
  const [secondVal, setSecondVal] = useState(0);
  const [firstVal, setFirstVal] = useState(0);



  
  const mySwap=()=>{
    var l1=selectedLanguage;
    var an1=anchorEl;
    setAnchorEl(anchorEl2);
    setAnchorEl2(an1);
    setSelectedLanguage(selectedLanguage1);
    setSelectedLanguage1(l1);
  }

  const handleClick = (event) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (languageCode) => {
    setSelectedLanguage(languageCode);
    handleClose();
    // Add your logic for changing the language here
  };
  const handleClick1 = (event) => {
    event.preventDefault()
    setAnchorEl2(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl2(null);
  };

  const handleLanguageSelect1 = (languageCode) => {
    setSelectedLanguage1(languageCode);
    handleClose1();
    // Add your logic for changing the language here
  };
  return (
    <Layout>
      <Container className="swapContainer" sx={{ mt: 10 }}>
        <Stack>
          <Typography
            variant="h4"
            className="mainHeadingBlue"
            sx={{
               fontWeight: 800,
                color: "#4231C8" ,
                textAlign : 'center'

              }}
          >
            Dex Swap
          </Typography>
          <Typography
            className="mainParaBlue w-100"
            variant="body1"
            sx={{
              color: "#4231C8",
              textAlign: "center",
              mb: 2,
              width: { xs: "100%", md: "60%" },
            }}
          >
            Decentralized exchange from ALT-X lets you make swaps with ease!  
          </Typography>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pt: 1,
            pb: 3,
          }}
        >
          <Box
            className="cardradius"
            sx={{
              boxShadow: "0px 6px 16.399999618530273px 0px #4231C81A",
              width: { xs: "100#", sm: "80%", md: "70%" },
              p: 4,
            }}
          >
            {/* <Typography
              variant="h6"
              className="mainParaBlue01"
              sx={{
                color: "#4231C8",
                borderBottom: "1px solid #999999",
                pb: 1,
              }}
            >
              <b>Defi</b>
            </Typography> */}
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",

                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end", 
                }}
              >
                <Typography className="normalParaBlack">From Token</Typography>
                <Button
                  aria-controls="language-menu"
                  aria-haspopup="true"
                  variant="outlined"
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                  className="tokenSelect"
                  sx={{
                    color: "#4231C8",
                    textTransform: "capitalize",
                    border: "none",
                    bgcolor: "#FAFAFA",
                    fontSize : "16px"
                  }}
                >
                  {selectedLanguage}
                </Button>
                <Menu
                  id="language-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {languages.map((language) => (
                    <MenuItem
                      key={language.label}
                      onClick={() => handleLanguageSelect(language.label)}
                      sx={{width:200}}
                    >
                      <img src={language.icon} alt="s" />
                      <Typography variant="body1" sx={{ ml: 2 }}>
                        <b>{language.label}</b>
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Stack>
              
              <FormControl fullWidth variant="standard" sx={{ mt: 0 }}>
                <InputLabel
                  className=""
                  shrink
                  htmlFor="number"
                  sx={{
                    color: "#000000",
                    fontSize: "20px",
                  }}
                 
                >
                  You spend
                </InputLabel>
                <BootstrapInput className="swapInputs" defaultValue="" id="email"  value={firstVal}
                  onChange={(e)=>{setFirstVal(e.target.result)}} />
              </FormControl>
              
            </Stack>
            <Typography className="paraInfoTxt mt-2">Balance: 0.12345</Typography>

            <Stack sx={{display: "flex",
                flexDirection: "row",justifyContent:"center",mt:2}}>
                  <img src={swapLine} alt="s" className="cursorPointer swapLineImg" />
                    <img src={swap} alt="s" className="cursorPointer" onClick={()=>mySwap()}/>
                    <img src={swapLine} alt="s" className="cursorPointer swapLineImg"/>
            </Stack>


            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",

                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end", 
                }}
              >
                <Typography className="normalParaBlack">To Token</Typography>
                <Button
                  aria-controls="language-menu"
                  aria-haspopup="true"
                  variant="outlined"
                  onClick={handleClick1}
                  endIcon={<KeyboardArrowDownIcon />}
                  className="tokenSelect"
                  sx={{
                    color: "#4231C8",
                    textTransform: "capitalize",
                    border: "none",
                    bgcolor: "#FAFAFA",
                    fontSize : "16px"
                  }}
                >
                  {selectedLanguage1}
                </Button>
                <Menu
                  id="language-menu"
                  anchorEl={anchorEl2}
                  keepMounted
                  open={Boolean(anchorEl2)}
                  onClose={handleClose1}
                >
                  {languages1.map((language) => (
                    <MenuItem
                      key={language.label}
                      onClick={() => handleLanguageSelect1(language.label)}
                      sx={{width:200}}
                    >
                      <img src={language.icon} alt="s" />
                      <Typography variant="body1" sx={{ ml: 2 }}>
                        <b>{language.label}</b>
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Stack>
              
              <FormControl fullWidth variant="standard" sx={{ mt: 2 }}>
                <InputLabel
                  className=""
                  shrink
                  htmlFor="number"
                  sx={{
                    color: "#000000",
                    fontSize: "20px",
                  }}
                  
                >
                  You Receive
                </InputLabel>
                <BootstrapInput defaultValue="" id="email" value={secondVal}
                  onChange={(e)=>{setSecondVal(e.target.result)}} className="swapInputs"/>
              </FormControl>
              
            </Stack>

            <Typography className="paraInfoTxt mt-2 textRight">≈$0.10</Typography>
            <div className="d-flex justify-content-center">
            <Button
              variant="contained"
              href="#outlined-buttons"
              className="btnText btnTextSwap mt-4"
              sx={{
                color: "#ffffff",
                textTransform: "capitalize",
                width: 110,
                backgroundColor : '#4231C8',
               
              }}
            >
              SWAP
        </Button>
            </div>
          </Box>
          <Stack sx={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center", gap : "10px", width: { xs: "100#", sm: "30%", md: "22%" },mt:16}}>
        <img src={g11} className="socialIcon2" alt="s" />
              <img src={g12} className="socialIcon2" alt="s" />
              <img src={g13} className="socialIcon2" alt="s" />
              <img src={g14} className="socialIcon2" alt="s" />
              <img src={g15} className="socialIcon2" alt="s" />
        </Stack>
        </Stack>
        
      </Container>
    </Layout>
  );
};

export default Swap;
