import React, { useRef, useEffect, useState, useContext } from "react";
import Layout from "../../layouts/Layout";
import SearchIcon from "../../../assets/searchIcon.svg";
import SearchIcon3 from "../../../assets/searchIcon3.svg";
import FaqData from "./FaqData";
import { useNavigate, useLocation } from "react-router-dom";

import {
  Container,
} from "@mui/material";
import { DarkModeContext } from "../../../ThemeContext";
const Faqs = () => {
  const location = useLocation();
  const { title, urlName, tab } = location.state || {};
  const [activeSection, setActiveSection] = useState(tab || `faqDiv${1}`);
  const faqSideContentRef = useRef(null);
  const faqSectionsRef = useRef(Array(11).fill(null));
  const navigate = useNavigate();
  const name = title;
  const labelTxt = urlName;
  const [activeAccordion, setActiveAccordion] = useState(0);
  console.log("activeAccordion :", activeAccordion)

  const handleAccordionClick = (index) => {
    console.log("index : ", index)
    setActiveAccordion(index === activeAccordion ? -1 : index);
  };

  console.log(activeSection, 'active section is there')
  const handleSidebarClick = (index) => {
    const newActiveSection = `faqDiv${index + 1}`;
    setActiveSection(newActiveSection);
    const sectionRef = faqSectionsRef.current[index];
    if (sectionRef) {
      faqSideContentRef.current.scrollTo({
        top: sectionRef.offsetTop - 220,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollRef = faqSideContentRef.current;
      if (scrollRef) {
        const scrollPosition = scrollRef.scrollTop;

        for (let i = faqSectionsRef.current.length - 1; i >= 0; i--) {
          const sectionRef = faqSectionsRef.current[i];
          if (sectionRef) {
            const sectionTop = sectionRef.offsetTop - 300;
            const sectionBottom = sectionTop + sectionRef.clientHeight;

            if (
              scrollPosition >= sectionTop &&
              scrollPosition < sectionBottom
            ) {
              const newActiveSection = `faqDiv${i + 1}`;
              if (activeSection !== newActiveSection) {
                setActiveSection(newActiveSection);
                break;
              }
            }
          }
        }
      }
    };

    const scrollRef = faqSideContentRef.current;

    if (scrollRef) {
      scrollRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollRef) {
        scrollRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [activeSection]);

  const sidebarHeadings = [
    "Getting started at ALT-X Exchange",
    "Account and security",
    "Wallet",
  ];
  const { darkMode } = useContext(DarkModeContext)
  return (
    <Layout>
      <Container className="mainContentFaq manageContainer">
        <div className="mainContentDiv">
          <h4 style={{ color: darkMode ? "#e1e1e1" : "#4231c8" }} className="supportTitle orderTitle">FAQs</h4>
          <div className="faqMainContentSection">
            <div className="faqSideBarMainDiv">
              <div className="searchDiv">
                <input type="text" name="" id="" style={{ backgroundColor: darkMode && "#282828", borderColor: darkMode && "#646464" }} />
                <img src={darkMode ? SearchIcon3 : SearchIcon} alt="" className="searchIconImg" />
              </div>

              <div className="sidebarOptionDiv">
                {sidebarHeadings.map((heading, index) => (
                  <div
                    key={`faqDiv${index + 1}`}
                    style={{
                      color: activeSection === ("faqDiv" + (index + 1)) ?
                        (darkMode ? "#e1e1e1" : "#4231c8")
                        :
                        (darkMode ? "#999" : "#7b7b7b")
                    }}
                    className={`sideContentLi ${activeSection === `faqDiv${index + 1}`
                      ? "sideContentActiveLi"
                      : ""
                      }`}
                    onClick={() => handleSidebarClick(index)}
                  >
                    <h6>{heading}</h6>
                  </div>
                ))}
              </div>
            </div>

            <div
              className="faqSideContent faqSideContentOne"
              ref={faqSideContentRef}
            >
              {sidebarHeadings.map((heading, index) => (
                <div
                  key={`faqDiv${index + 1}`}
                  className={`faqDiv00 faqDiv${index + 1}`}
                  ref={(ref) => (faqSectionsRef.current[index] = ref)}
                >
                  <div className="faqHeadingDiv01">
                    <h5 style={{ color: darkMode && "#E1E1E1" }} className="faqHeading01">{heading}</h5>
                  </div>
                  {FaqData[heading]?.map((link, linkIndex) => (
                    <a
                      key={`faqLink${linkIndex + 1}`}
                      className={`faqLink01 ${darkMode ? "faqLink01HoverDark" : "faqLink01HoverLight"}`}
                      onClick={() =>
                        navigate("/faq/detail", {
                          state: { name: heading, labelTxt: link.label, sidebarName:"faqDiv" + (index + 1)},
                        })
                      }
                    >
                      <li style={{ color: darkMode && "#E1E1E1" }}>{link.label}</li>
                    </a>
                  ))}
                </div>
              ))}
            </div>
          </div>

          <div className="accordionMainSection">
            <div class="accordion" id="accordionExample">
              {Object.keys(FaqData).map((heading, index) => (
                <div class="accordion-item" key={index}>
                  <h2 class="accordion-header">
                    <button
                      class={`accordion-button accordionButton ${index === activeAccordion ? "" : "collapsed"
                        }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index + 1}`}
                      aria-expanded={index === activeAccordion ? "true" : "false"}
                      aria-controls={`collapse${index + 1}`}
                      onClick={() => handleAccordionClick(index)}
                    >
                      {heading}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index + 1}`}
                    class={`accordion-collapse collapse ${index === activeAccordion ? "show" : ""
                      }`}
                  >
                    <div class="accordion-body accordionBody">
                      {FaqData[heading]?.map((link, linkIndex) => (
                        <a
                          key={`faqLink${linkIndex + 1}`}
                          className="faqLink01"
                          onClick={() =>
                            navigate("/faq/detail", {
                              state: { name: heading, labelTxt: link.label },
                            })
                          }
                        >
                          <ul>
                            <li style={{ color: "red", backgroundColor: "greenyellow" }}>{link.label}</li>
                          </ul>
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Faqs;