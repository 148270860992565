import React, { useContext, useEffect, useState } from "react";
import arrow from "../../../assets/VectorArrow1.svg";
import { column6 } from "../../constants/Data";
import { DarkModeContext } from "../../../ThemeContext";
export default function RightSide({ upperDataObj }) {
  const [buyOrderBook, setBuyOrderBook] = useState([])
  const [sellOrderBook, setsellOrderBook] = useState([])
  const fetchMarketData = async () => {
    try {
      const response = await fetch(`https://exchangeic.exchange/api/v1/lib/order-book?symbol=${upperDataObj.symbol}`, {

        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      setBuyOrderBook(result?.data?.buys)
      setsellOrderBook(result?.data?.sells)
      console.log('fetchMarketData in order book', result); // Process your result here
    } catch (error) {
      console.log('Error fetching market data:', error);
    }
  };


  useEffect(() => {
    fetchMarketData();
  }, [upperDataObj]);

  const { darkMode } = useContext(DarkModeContext)
  return (
    <>
      <div className="bitCoinPriceDiv">
        <div className="priceTxTDiv priceTxTDivLow">
          <p style={{ color: darkMode ? "" : "#232323" }}>High</p>
          <h6 style={{ color: "#2bb060" }}>{upperDataObj.high}</h6>
        </div>
        <div className="priceTxTDiv priceTxTDivLow">
          <p style={{ color: darkMode ? "" : "#232323" }}>Low</p>
          <h6 style={{ color: "#2bb060" }}>{upperDataObj.low}</h6>
        </div>
      </div>
      <div
        style={{
          backgroundColor: darkMode ? "#282828" : "white",
          border: darkMode ? "1px solid #4b4b4b80" : "1px solid #b6b6b680"
        }}
        className="exchange-right-container">
        <div className="balanceTableDiv mt-0">
          <h6 style={{ color: darkMode ? "" : "#30258B", paddingTop: 8, paddingLeft: 12 }} className="balanceTxt px-2">Order book</h6>
          <div style={{ backgroundColor: darkMode ? "" : "#E9ECF1" }} className="row row-style mx-0">
            {column6?.map((item) => {
              return (
                <div className="col-4 d-flex flex-column align-items-center">
                  {item}
                </div>
              );
            })}
          </div>
          <div className="orderBookTr">
            {buyOrderBook?.map((item, index) => (
              <div key={index} className="row mx-0 orderBookRow">
                <div className="col-4">
                  <p className="priceP highPrice">{item.price}</p>
                </div>
                <div className="col-4">
                  <p className="priceP">{item.amount}</p>
                </div>
                <div className="col-4">
                  <p className="priceP">{item.total}</p>
                </div>
              </div>
            ))}
            <div className="">
              <div className="row mx-0">
                <div className="col-12 abovePrice">
                  <h6>{upperDataObj.lastPrice}</h6>
                  <img src={arrow} alt="" />
                </div>
              </div>
            </div>
            {sellOrderBook?.map((item, index) => (
              <div key={index} className="row mx-0 orderBookRow">
                <div className="col-4">
                  <p className="priceP lowPrice">{item.price}</p>
                </div>
                <div className="col-4">
                  <p className="priceP">{item.amount}</p>
                </div>
                <div className="col-4">
                  <p className="priceP">{item.total}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
