import React, { useContext } from 'react'
import { DarkModeContext } from '../../../../../ThemeContext'

const WithdrawalIssue = () => {
  const { darkMode } = useContext(DarkModeContext)
  return (
    <>
      <h2 style={{ color: darkMode && "#e1e1e1" }} className="guideHeading">How to Solve Withdrawal Issues</h2>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        At ALT-X Exchange, we understand that encountering issues with
        withdrawals can be frustrating. Whether it's a delay in processing,
        an error message, or a missing transaction, rest assured that we're
        here to help. Below are steps to effectively address and resolve
        withdrawal problems:
      </p>
     <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        1. Double-Check Transaction Details:
      </h3>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        Start by reviewing the withdrawal request details, including the
        destination address and withdrawal amount. Ensure that all
        information is accurate and matches the intended recipient's wallet.
      </p>
     <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        2. Verify Blockchain Confirmations:
      </h3>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        If your withdrawal is pending or delayed, check the blockchain
        explorer for confirmations. Depending on network congestion,
        transactions may take some time to be processed and confirmed.
      </p>
     <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        3. Contact Customer Support:
      </h3>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        If you've waited for an extended period and your withdrawal hasn't
        been processed, reach out to ALT-X Exchange's customer support
        team. Provide them with details such as the transaction ID,
        withdrawal amount, and destination address for assistance.
      </p>
     <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        4. Check for Error Messages:
      </h3>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        If you received an error message during the withdrawal process,
        take note of the error code or description. This information can help
        our support team diagnose and resolve the issue more efficiently.
      </p>
     <h3 style={{ color: "#1155CC" }} className="guideSubHeading">
        5. Stay Informed:
      </h3>
      <p style={{ color: darkMode && "#e1e1e1" }} className="guidePara">
        Stay updated on any announcements, maintenance schedules, or
        platform updates from ALT-X Exchange. Timely information can
        provide insights into potential issues affecting withdrawals and their
        resolution timelines.
      </p>
    </>
  )
}

export default WithdrawalIssue