import React, { useState, useEffect, useContext } from "react";
import chartPng from "../../../assets/chart.png";
import { column5, exchangeSymbolTabsHistory } from "../../constants/Data";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { DarkModeContext } from "../../../ThemeContext";
import { getToken } from "../../../services/LocalStorageService"
import { useNavigate } from "react-router-dom";


export default function CenterSide({ upperDataObj, state }) {
  console.log("state", state)
  const [symbolTabs, setSymbolTabs] = useState(exchangeSymbolTabsHistory);
  const handleTabClick = (index) => {
    const updatedTabs = symbolTabs.map((tab, i) => ({
      ...tab,
      selected: i === index,
    }));

    setSymbolTabs(updatedTabs);
  };

  // const fetchMarketData = async () => {
  //   try {
  //       const response = await fetch('http://127.0.0.1:8000/api/v1/lib/market-data/', {
  //       method: 'GET',
  //       headers: {
  //           'Accept': 'application/json'
  //       }
  //       });
  //       if (!response.ok) {
  //       throw new Error(`Error! status: ${response.status}`);
  //       }
  //       const result = await response.json();
  //       // setName(result?.data?.tickers)
  //       console.log('fetchMarketData',result); // Process your result here
  //   } catch (error) {
  //       console.log('Error fetching market data:', error);
  //   }
  //   };


  //   useEffect(() => {
  //   fetchMarketData();
  //   }, []);
  const chartKey = `${upperDataObj.to}_${upperDataObj.from}`;
  const { darkMode } = useContext(DarkModeContext)
  const access_token = getToken();
  console.log(access_token, 'tokens')
  let notLoggedIn;
  if (!access_token?.access_token) {
    notLoggedIn = true
  } else {
    notLoggedIn = false
  }
  const navigate = useNavigate()
  return (
    <>
      <div className="bitCoinPriceDiv">
        <button
          style={{ background: 'none', border: 'none' }}
          onClick={() => navigate(`/chart/${state ? state.crypto : upperDataObj.to}`, { state: { state, upperDataObj, chartKey } })}
          className="bitCoinHeaderDiv">
          {state ? (
            <>
              <h2 style={{ color: darkMode ? "" : "#232323" }}>{state?.crypto}</h2>
              <p style={{ color: darkMode ? "" : "#232323" }}>{state?.symbol}</p>
            </>
          ) : (
            <>
              <h2 style={{ color: darkMode ? "" : "#232323" }}>{upperDataObj.name}</h2>
              <p style={{ color: darkMode ? "" : "#232323" }}>{upperDataObj.pair}</p>
            </>
          )}
        </button>
        <div className="priceTxTDiv">
          <p style={{ color: darkMode ? "" : "#232323" }}>Last Price</p>
          <h6 style={{ color: darkMode ? "" : "#232323" }}>{upperDataObj.lastPrice}</h6>
        </div>
        <div className="priceTxTDiv">
          <p style={{ color: darkMode ? "" : "#232323" }}>24hr Cange</p>
          <h6 style={{ color: darkMode ? "" : "#232323" }}>{upperDataObj.change24HR} {upperDataObj.to_coin}</h6>
        </div>
      </div>

      {/* <div className="exchange-center-container"> */}
      {
        upperDataObj?.to ? <AdvancedRealTimeChart
          key={darkMode ? 'dark' : 'light'}
          Interval="240"
          theme={darkMode ? "dark" : "light"}
          width="100%"
          height="540"
          allow_symbol_change={false}
          hide_legend={true}
          symbol={(state?.symbol) ? (state?.symbol) : `${upperDataObj.to}${upperDataObj.from}`}
        >

        </AdvancedRealTimeChart> : null
      }
      {/* <img src={chartPng} alt="" className="chartPng" /> */}
      {/* </div> */}
      {/* table  */}
      <div
        style={{
          backgroundColor: darkMode ? "#282828" : "#fff",
          border: darkMode ? "1px solid #4b4b4b80" : "1px solid #b6b6b680"
        }}
        className="exchange-center-container px-1">
        <div className="d-flex flex-row justify-content-start align-items-center symbol-tab mt-0">
          {symbolTabs?.map((item, index) => (
            <div
              key={index}
              style={{ color: item?.selected && !darkMode && "#000" }}
              className={`${item?.selected
                ? "active-symbol-item"
                : "symbol-item symbol-item-history"
                }`}
              onClick={() => handleTabClick(index)}
            >
              {item?.name}
            </div>
          ))}
        </div>
        <div style={{ backgroundColor: darkMode ? "" : "#ECEEF4", color: darkMode ? "" : "#7B7B7B" }} className="row-style marketTableRow">
          {column5?.map((item) => {
            return (
              <div className="d-flex flex-column align-items-center">
                {item}
              </div>
            );
          })}
        </div>
        {notLoggedIn ?
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 210 }}>
            <button
              onClick={() => navigate('/login')}
              style={{ backgroundColor: darkMode ? "#282828" : "#fff", border: "none", height: 40, color: "#999", fontSize: 14 }}
            >
              LogIn / Sign Up
            </button>
          </div> : <div>

          </div>
        }
      </div>
    </>
  );
}
