import React from "react";

export const CustomTable = ({
  columns,
  show = true,
  address = false,
  data,
}) => {
  return (
    <div class="table-responsive-xl">
      <table class="table">
        <thead>
          <tr>
            {columns?.map((item) => {
              return (
                <th scope="col" className="table-col-text">
                  <p>{item}</p>
                </th>
              );
            })}
            {/* {show && !address ? <th scope="col" /> : null} */}
          </tr>
        </thead>
        <tbody>
          {address ? (
            data?.map((item) => {
              return (
                <tr>
                  <td scope="row" className="table-content-text">
                    <p>{item?.nickName}</p>
                  </td>
                  <td className="table-content-text"><p>{item?.platForm}</p></td>
                  <td className="table-content-text"><p>{item?.Withdrawal}</p></td>
                  <td className="table-content-text"><p>{item?.Destination}</p></td>
                </tr>
              );
            })
          ) : show ? (
            <tr>
              <td scope="row" className="table-content-text">
                <p>Opera V105.0.0.0</p>
              </td>
              <td className="table-content-text"><p>Pakistan</p></td>
              <td className="table-content-text"><p>2023-12-21 14:26:36</p></td>
              <td className="table-content-text"><p>72.255.7.202</p></td>
              <td className="table-content-text">
                <img src={require("../../../assets/closeIcon.svg").default}  className="crossimg"/>
              </td>
            </tr>
          ) : (
            <>
              <tr>
                <td scope="row" className="table-content-text">
                  2023-12-28 13:32:43
                </td>
                <td className="table-content-text">72.255.7.202</td>
                <td className="table-content-text">Sign In</td>
                <td className="table-content-text">Success</td>
              </tr>
              <tr>
                <td scope="row" className="table-content-text">
                  2023-12-28 13:32:43
                </td>
                <td className="table-content-text">72.255.7.202</td>
                <td className="table-content-text">Sign In</td>
                <td className="table-content-text">Success</td>
              </tr>
              <tr>
                <td scope="row" className="table-content-text">
                  2023-12-28 13:32:43
                </td>
                <td className="table-content-text">72.255.7.202</td>
                <td className="table-content-text">Sign In</td>
                <td className="table-content-text">Success</td>
              </tr>
              <tr>
                <td scope="row" className="table-content-text">
                  2023-12-28 13:32:43
                </td>
                <td className="table-content-text">72.255.7.202</td>
                <td className="table-content-text">Sign In</td>
                <td className="table-content-text">Success</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};
