import React, { useState, useContext } from 'react';
import { Button, Menu, MenuItem, ListItemIcon } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language'; // Import the language icon of your choice
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DarkModeContext } from "../../ThemeContext";

const languages = [
  { "code": "en", "label": "English", "icon": "🇺🇸" },
  { "code": "es", "label": "Spanish", "icon": "🇪🇸" },
  { "code": "zh", "label": "Chinese", "icon": "🇨🇳" },
  { "code": "hi", "label": "Hindi", "icon": "🇮🇳" },
  { "code": "ar", "label": "Arabic", "icon": "🇸🇦" },
  { "code": "fr", "label": "French", "icon": "🇫🇷" },
  { "code": "ru", "label": "Russian", "icon": "🇷🇺" },
  { "code": "pt", "label": "Portuguese", "icon": "🇵🇹" },
  { "code": "bn", "label": "Bengali", "icon": "🇧🇩" },
  { "code": "ur", "label": "Urdu", "icon": "🇵🇰" },
  { "code": "id", "label": "Indonesian", "icon": "🇮🇩" },
  { "code": "de", "label": "German", "icon": "🇩🇪" },
  { "code": "ja", "label": "Japanese", "icon": "🇯🇵" },
  { "code": "pa", "label": "Punjabi", "icon": "🇮🇳" },
  { "code": "ms", "label": "Malay", "icon": "🇲🇾" },
  { "code": "sw", "label": "Swahili", "icon": "🇰🇪" },
  { "code": "ko", "label": "Korean", "icon": "🇰🇷" },
  { "code": "tr", "label": "Turkish", "icon": "🇹🇷" },
  { "code": "it", "label": "Italian", "icon": "🇮🇹" },
  { "code": "nl", "label": "Dutch", "icon": "🇳🇱" },
  { "code": "vi", "label": "Vietnamese", "icon": "🇻🇳" },
  { "code": "th", "label": "Thai", "icon": "🇹🇭" },
  { "code": "pl", "label": "Polish", "icon": "🇵🇱" },
  { "code": "he", "label": "Hebrew", "icon": "🇮🇱" },
  { "code": "ro", "label": "Romanian", "icon": "🇷🇴" }
]
  ;
const LanguageSelect = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { darkMode } = useContext(DarkModeContext)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (languageCode) => {
    setSelectedLanguage(languageCode);
    handleClose();
    // Add your logic for changing the language here
  };
  return (
    <div>
      <Button
        aria-controls="language-menu"
        aria-haspopup="true"
        variant="outlined"
        onClick={() => setIsModalOpen(true)}
        startIcon={<LanguageIcon />}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ color: "#4231C8", borderRadius: 1, textTransform: "capitalize", border: "1px solid #4231C8", fontSize: 13, height: 32, width: 84 }}
      >
        {selectedLanguage}
      </Button>
      {isModalOpen && (
        <div style={{ position: "absolute", top: 0, left: -22 }}>
          <div style={{ width: "100vw", height: "100vh", backgroundColor: "#00000080", display: 'flex', justifyContent: "center", alignItems: "center" }}>

            <div
              style={{
                backgroundColor: "white",
                width: "60%",
                // height: "60vh",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                gap: 10,
                padding: 20,
                position: "relative",
                paddingTop: 40,
                borderRadius: 4
              }}>
              <button
                style={{ position: "absolute", right: 10, top: 10, background: "none", border: "none" }}
                onClick={() => setIsModalOpen(false)}>
                <h5>x</h5>
              </button>
              {languages.map((language) => (
                <button
                  style={{ display: "flex", gap: 20, width: "20%", minWidth: 200, alignItems: "center",background: "none", border: "none", padding: 10}}
                  onClick={() => { setSelectedLanguage(language.code); setIsModalOpen(false) }}>
                  <h5>{language.icon}</h5>
                  <h6>{language.label} ({language.code})</h6>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
      {/* <Menu
      id="language-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {languages.map((language) => (
        <MenuItem key={language.code} onClick={() => handleLanguageSelect(language.code)}>
          <ListItemIcon>{language.icon}</ListItemIcon>
          {language.label}
        </MenuItem>
      ))}
    </Menu> */}
    </div>
  )
}

export default LanguageSelect