import React, { useState, useEffect, useContext } from "react";
import {
  column3,
  exchangeSymbolTabs,
  tableData,
  column4,
} from "../../constants/Data";
import { DarkModeContext } from "../../../ThemeContext";


export const ExchangeLeftComponent = ({ upperDataObj, setUpperDataObj }) => {
  const [symbolTabs, setSymbolTabs] = useState(exchangeSymbolTabs);
  const { darkMode } = useContext(DarkModeContext)
  const [searchValue, setSearchValue] = useState("")

  const handleTabClick = (index) => {
    const updatedTabs = symbolTabs.map((tab, i) => ({
      ...tab,
      selected: i === index,
    }));

    setSymbolTabs(updatedTabs);
  };

  const [tableData, setTableData] = useState([])
  const [originalTableData, setOriginalTableData] = useState([])

  const fetchMarketData = async () => {
    try {

      const response = await fetch('https://exchangeic.exchange/api/v1/lib/market-data/', {

        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      setTableData(result?.data?.tickers)
      setOriginalTableData(result?.data?.tickers)

      setUpperDataObj(prevState => {
        return {
          ...prevState,
          name: result?.data?.tickers[0]?.to_coin,
          pair: `${result?.data?.tickers[0]?.to_coin}/${result?.data?.tickers[0]?.from_coin}`,
          lastPrice: result?.data?.tickers[0]?.last_price,
          change24HR: result?.data?.tickers[0]?.fluctuation,
          high: result?.data?.tickers[0]?.high_24h,
          low: result?.data?.tickers[0]?.low_24h,
          volume: result?.data?.tickers[0]?.base_volume_24h,
          amount: result?.data?.tickers[0]?.quote_volume_24h,
          from: result?.data?.tickers[0]?.from_coin,
          to: result?.data?.tickers[0]?.to_coin,
          symbol: result?.data?.tickers[0]?.symbol,
        }
      })
      console.log('fetchMarketData', result); // Process your result here
    } catch (error) {
      console.log('Error fetching market data:', error);
    }
  };


  useEffect(() => {
    fetchMarketData();
  }, []);


  const handleCryptoChange = (
    symbol,
    toCoin,
    fromCoin,
    last_price,
    fluctuation,
    high_24h,
    low_24h,
    base_volume_24h,
    quote_volume_24h
  ) => {
    console.log('clicked on side Currency', symbol, toCoin, fromCoin)
    setUpperDataObj(prevState => {
      return {
        ...prevState,
        name: toCoin,
        pair: `${toCoin}/${fromCoin}`,
        lastPrice: last_price,
        change24HR: fluctuation,
        high: high_24h,
        low: low_24h,
        volume: base_volume_24h,
        amount: quote_volume_24h,
        from: fromCoin,
        to: toCoin,
        symbol: symbol,
      }
    })
  }
  const handleSearch = () => {
    const filteredData = originalTableData.filter(item => item.to_coin.toLowerCase().includes(searchValue.toLowerCase()) || item.from_coin.toLowerCase().includes(searchValue.toLowerCase()));
    setTableData(filteredData)
  }
  return (
    <div
      style={{
        backgroundColor: darkMode ? "#282828" : "#fff",
        height: "102%",
        paddingTop: 32,
        marginTop: -10,
        borderRight: "0.5px solid #6565654d"
      }}>
      {/* <div className="d-flex flex-row justify-content-end cursorPointer">
        <div className="layout-btn">Layout</div>
      </div> */}

      <div style={{ backgroundColor: darkMode ? "" : "#E9ECF1" }} className="searchmain">
        <div style={{ borderRadius: 0, marginTop: -10, }} className="search-input-container">
          <input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            style={{
              backgroundColor: darkMode ? "" : "#E9ECF1",
              color: darkMode ? "" : "#000"
            }}
            placeholder="Symbol or Name" />
          <button
            onClick={handleSearch}
            style={{ background: "none", border: 'none' }}>
            <img src={darkMode ? require("../../../assets/searchIcon3.svg").default : require("../../../assets/searchIcon2.svg").default} />
          </button>
        </div>
      </div>

      <div className="d-flex flex-row justify-content-start align-items-center symbol-tab">
        {/* {symbolTabs?.map((item, index) => (
          <div
            key={index}
            className={`${
              item?.selected ? "active-symbol-item" : "symbol-item"
            }`}
            onClick={() => handleTabClick(index)}
          >
            {item?.name}
          </div>
        ))} */}
      </div>

      <div className="scrollSet scrollLeftSet">
        <div style={{ backgroundColor: darkMode ? "" : "#E9ECF1" }} className="row row-style tableHeadingRow">
          {column3?.map((item) => {
            return (
              <div className="col-3 d-flex flex-column align-items-center tableHeadCol">
                {item}
              </div>
            );
          })}
        </div>
        {/* table date  */}
        <div className="tableScroll" style={{ cursor: "pointer" }}>
          {tableData?.map((item, index) => (
            <div key={index} className="row tableDataRow" onClick={() => {
              handleCryptoChange(
                item.symbol,
                item.to_coin,
                item.from_coin,
                item.last_price,
                item.fluctuation,
                item.high_24h,
                item.low_24h,
                item.base_volume_24h,
                item.quote_volume_24h,
              )
            }}>
              <div className="col-3 d-flex align-content-center">
                <div className="bitcoinDiv bitcoinDivImg">
                  {/* <img src={item.image} alt="" /> */}
                  <div>
                    <h6 style={{ color: darkMode ? "" : "#000" }}>{item.to_coin}</h6>
                    <p >{item.to_coin}/{item.from_coin}</p>
                  </div>
                </div>
              </div>
              <div className="col-3 d-flex align-content-center">
                <div className="bitcoinDiv">
                  <p style={{ color: darkMode ? "" : "#000" }} className="">{item.last_price}</p>
                </div>
              </div>
              <div className="col-3 d-flex align-content-center">
                <div className="bitcoinDiv">
                  <p className={item.fluctuation.startsWith('-') ? 'highPrice' : 'lowPrice'}>
                    {item.fluctuation}
                  </p>
                </div>
              </div>
              <div className="col-3 d-flex align-content-center">
                <div className="bitcoinDiv">
                  <p style={{ color: darkMode ? "" : "#000" }} className="">{item.base_volume_24h}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="balanceTableDiv">
          <h6 className="balanceTxt px-1">Balance</h6>
          <div className="row row-style mx-0">
            {column4?.map((item) => {
              return (
                <div className="col-3 d-flex flex-column align-items-center tableHeadCol">
                  {item}
                </div>
              );
            })}
          </div>
        </div> */}
      </div>
    </div>
  );
};
