import {
  Box,
  Button,
  Container,
  InputBase,
  Pagination,
  Stack,
  Tab,
  Tabs,
  Typography,
  alpha,
} from "@mui/material";
import React from "react";
import Layout from "../layouts/Layout";
import doc1 from "../../assets/doc1.svg";
import doc2 from "../../assets/doc2.svg";
import doc3 from "../../assets/doc3.svg";
import doc4 from "../../assets/doc4.svg";
import doc5 from "../../assets/doc5.svg";
import doc6 from "../../assets/doc6.svg";
import doc7 from "../../assets/doc7.svg";
import doc8 from "../../assets/doc8.svg";
import doc10 from "../../assets/doc10.svg";
import ErrorCodeTable from "../layouts/ErrorCodeTable";
const Anoucements = () => {
  const [value, setValue] = React.useState(0);

  return (
    <Layout>
      <Container className="manageContainer">
        <Stack className="" direction={{ md: "row", xs: "column" }} sx={{ mt: 9, mb: 12 }}>
          <Stack className="docsSideSet"
            Item
            width={{ xs: "100%", md: "30%", borderRight: "2px solid #D9D9D9" }}
          >
            <Container className="paddingSet">
              <Typography
              className="docsHeading"
                pt={2}
              >
                DOCUMENTATION
              </Typography>
              {/* <Search>
                <SearchIconWrapper>
                  <SearchIcon color="#00AB2C" />
                </SearchIconWrapper>
                <StyledInputBase inputProps={{ "aria-label": "search" }} />
              </Search> */}
              <Stack direction="column" className="sideBarRow" mt={1} gap={2}>
                <Typography
                  variant="body2"
                  onClick={() => setValue(0)}
                  fontSize="15px"
                  className="pointer"
                  sx={{
                    color: "#999999",
                    ...(value === 0 && {
                      color: "#4231C8",
                      fontWeight: 600,
                    }),
                  }}
                >
                  Getting Started with Alt-X API
                </Typography>

                <Typography
                  variant="body2"
                  className="pointer"
                  onClick={() => setValue(1)}
                  fontSize="15px"
                  sx={{
                    color: "#999999",
                    ...(value === 1 && {
                      color: "#4231c8",
                      fontWeight: 700,
                    }),
                  }}
                >
                  How to use WebSocket API
                </Typography>
                <Typography
                  variant="body2"
                  className="pointer"
                  onClick={() => setValue(3)}
                  fontSize="15px"
                  sx={{
                    color: "#999999",
                    ...(value === 3 && {
                      color: "#4231c8",
                      fontWeight: 700,
                    }),
                  }}
                >
                  Authorization
                </Typography>

                <Typography
                  variant="body2"
                  className="pointer"
                  onClick={() => setValue(4)}
                  fontSize="15px"
                  sx={{
                    color: "#999999",
                    ...(value === 4 && {
                      color: "#4231c8",
                      fontWeight: 700,
                    }),
                  }}
                >
                  Error Codes
                </Typography>
                <Typography
                  variant="body2"
                  className="pointer"
                  onClick={() => setValue(5)}
                  fontSize="15px"
                  sx={{
                    color: "#999999",
                    ...(value === 5 && {
                      color: "#4231c8",
                      fontWeight: 700,
                    }),
                  }}
                >
                  Rate Limits
                </Typography>
                <Typography
                  variant="body2"
                  className="pointer"
                  onClick={() => setValue(6)}
                  fontSize="15px"
                  sx={{
                    color: "#999999",
                    ...(value === 6 && {
                      color: "#4231c8",
                      fontWeight: 700,
                    }),
                  }}
                >
                  Managing API
                </Typography>
              </Stack>
            </Container>
          </Stack>
          <Stack className="apiDataSet" Item width={{ md: "70%", xs: "100%" }} mt={2}>
            {value == 6 && (
              <Container>
                <Typography variant="h3" className="supportTitle" color="#4231c8" fontWeight={700} mt={4}>
                  Managing API
                </Typography>
                <Typography
                  variant="h6"
                  className="apiDataSubTitle"
                  color="#4231c8"
                  fontSize="22px"
                  fontWeight={400}
                  mt={4}
                  mb={4}
                >
                  By registering a withdrawal address in the API, you can limit
                  the function of each API key.
                </Typography>
                <Typography
                  variant="h6"
                  className="apiDataSubTitle"
                  color="#4231c8"
                  fontSize="18px"
                  fontWeight={600}
                  mt={2}
                >
                  Set API Key Permissions
                </Typography>
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={1}
                  mb={2}
                >
                  You can go to API management page by clicking API setting tab
                  in mypage. <br></br>You can generate an API key from the API Management
                  page.<br></br> 
                  After entering the API key alias you want to use, press
                  Generate button and authenticate with tfa to generate new API
                  key.
                </Typography>
                <img src={doc10} alt="s"width="100%"/>
                <Typography
                  variant="h6"
                  className="apiDataSubTitle"
                  color="#4231c8"
                  fontSize="24px"
                  fontWeight={600}
                  mt={4}
                  mb={2}
                >
                  Set API Key Permissions
                </Typography>
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={1}
                >
                  The first generated API key is Read Only<br></br>
                  Alt-X API supports read, trading, and withdrawal
                  allowance.<br></br>
                  If you want to change the properties of the generated API key,
                  click the Edit button to check all the properties you want and
                  save.
                </Typography>
                <Typography
                  variant="h6"
                  className="apiDataSubTitle"
                  color="#4231c8"
                  fontSize="24px"
                  fontWeight={600}
                  mt={6}
                  mb={2}
                >
                  Register Withdrawal Address for you API
                </Typography>
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={1}
                >
                  If you select withdrawal, you can only withdraw to the address
                  stored on the manage withdrawal address tab on you API page.
                  <br></br><br></br>
                  If you want to register a withdrawal address, click on the tab and fill in the information.(tfa is required for registration)
                </Typography>
                
              </Container>
            )}
            {value == 5 && (
              <Container>
                <Typography variant="h3" className="supportTitle" color="#4231c8" fontWeight={700}>
                  Rate Limits
                </Typography>
                <Typography
                  variant="h6"
                  className="apiDataSubTitle"
                  color="#4231c8"
                  fontWeight={600}
                  mt={2}
                  mb={3}
                >
                  Ratelimit Group 1
                </Typography>

                <ul className="doc1">
                  <li>Applied to Alt-X account</li>
                  <li>Rate: 10 request/sec with 10 request burst</li>
                  <li>Applicable APIs:</li>
                  <ul className="doc1">
                    <li>Placing new orders</li>
                    <li>Withdrawals</li>
                    <li>Transferring to Alt-X</li>
                    <li>Staking/Unstaking</li>
                  </ul>
                </ul>
                <Typography
                  variant="h6"
                  className="apiDataSubTitle"
                  color="#4231c8"
                  fontWeight={600}
                  mt={4}
                  mb={3}
                >
                  Ratelimit Group 2
                </Typography>
                <ul className="doc1">
                  <li>Applied to Alt-X account</li>
                  <li>RRate: 20 request/sec with 100 request burst</li>
                  <li>Applicable APIs:</li>
                  <ul className="doc1">
                    <li>Cancelling Orders</li>
                  </ul>
                </ul>
                <Typography
                  variant="h6"
                  className="apiDataSubTitle"
                  color="#4231c8"
                  fontWeight={600}
                  mt={2}
                  mb={3}
                >
                  Ratelimit Group 3
                </Typography>
                <ul className="doc1">
                  <li>Applied to IP address</li>
                  <li>Rate: 20 request/sec with 200 request burst</li>
                  <li>Applicable APIs:</li>
                  <ul className="doc1">
                    <li>All APIs</li>
                  </ul>
                </ul>
              </Container>
            )}
            {value == 4 && (
              <Container>
                <Typography variant="h3" className="supportTitle" color="#4231c8" fontWeight={700}>
                  Error Codes
                </Typography>
                <Typography
                  variant="h6"
                  className="apiDataSubTitle"
                  color="#4231c8"
                  fontSize="22px"
                  fontWeight={400}
                  mt={0}
                >
                  Exchange
                </Typography>
                <Typography
                  variant="h6"
                  className="apiDataSubTitle"
                  color="#4231c8"
                  fontSize="18px"
                  fontWeight={400}
                  mt={4}
                >
                  Error format
                </Typography>
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={1}
                >
                  For 4XX Errors, the returned data contains the reason. Each
                  object has "detail" which are representing what you sent
                  wrong.
                </Typography>
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontSize="18px"
                  fontWeight={400}
                  mt={4}
                  mb={2}
                >
                  For example,
                </Typography>
                <img src={doc8} alt="s" width="100%" />
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontSize="18px"
                  fontWeight={400}
                  mt={3}
                >
                  means 'Market is closed.'
                </Typography>
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontSize="18px"
                  fontWeight={400}
                  mt={1}
                >
                  Every API specification has reason data sheet below, so you
                  can check what the problem it is.
                </Typography>
                <ErrorCodeTable />
              </Container>
            )}
            {value == 3 && (
              <Container>
                <Typography variant="h3" className="supportTitle" color="#4231c8" fontWeight={700}>
                  Authorization
                </Typography>

                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={4}
                >
                  In order to access your account through our HTTP or WebSocket
                  API, you need to specify your OAuth Access Token with the
                  request.<br></br> Here's a rough guide for obtaining Access
                  Tokens. Please refer to the OAuth 2.0 standard for the full
                  details
                  <ul className="doc2">
                    <li>
                      Create an OAuth Client ID for your application: Login to
                      Alt-X website with your ID, and click the "API
                      Settings" button in My Page. Click the "Create new key"
                      button and copy the "Client ID" and "Client Secret Key".
                      Keep it secret. Anyone who have this key have the full
                      access to your account.
                    </li>
                    <li>
                      Get an OAuth Access Token using POST /token API with the
                      Client ID/Secret from Step 1(Example 1). Put this Access
                      Token to the "Authorization" HTTP header to access your
                      accounts(Example 2). Access Tokens expire after a few
                      minutes, so you need to fetch a new Access Token before it
                      expires. Expiration time can be found in the "expires_in"
                      field of the POST /token API.
                    </li>
                  </ul>
                  To access Alt-X accounts: Create your OAuth Client ID from{" "}
                  <a className="underline" href="https://www.Alt-X.com/">
                    https://www.Alt-X.com/
                  </a>
                  .API endpoints are same for both Alt-X :{" "}
                  <a className="underline" href="https://api.Alt-X.com/">
                    https://api.Alt-X.com/
                  </a>{" "}
                  and{" "}
                  <a
                    className="underline"
                    href="https://accounts.Alt-X.com/token"
                  ></a>
                </Typography>
                <Box sx={{ borderLeft: "8px solid #4231c8", mt: 3, p: 2 }}>
                  <Typography
                    variant="body1"
                    className="apiDataDesc"
                    color="#151515"
                    fontWeight={400}
                    mt={1}
                  >
                    Authorization APIs have different addresses than other HTTP
                    APIs.
                  </Typography>
                  <Typography
                    variant="body1"
                    className="apiDataDesc"
                    color="#151515"
                    fontWeight={400}
                    mt={1}
                  >
                    Default endpoint: https://accounts.altexchanger.com/token
                  </Typography>
                </Box>
                <Typography
                  variant="h6"
                  className="apiDataSubTitle"
                  fontSize="18px"
                  fontWeight={400}
                  mt={4}
                >
                  Example 1. Retrieving an Access Token from Client ID and
                  Client Secret.
                </Typography>
                <img src={doc6} alt="s" width="100%" />
                <Typography
                  variant="h6"
                  className="apiDataSubTitle"
                  fontSize="18px"
                  fontWeight={400}
                  mt={4}
                >
                  Example 2. Using the "balance" API with Access Token to fetch
                  your account balances.
                </Typography>

                <img src={doc7} alt="s" width="100%" />
              </Container>
            )}
            {value == 1 && (
              <Container>
                <Typography variant="h3" className="supportTitle" color="#4231c8" fontWeight={700}>
                  How to use WebSocket API
                </Typography>

                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={4}
                >
                  Alt-X WebSocket API communicates in JSON string.Each
                  single request messege should contain single valid JSON
                  string.
                </Typography>
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={1}
                  mb={2}
                >
                  WebSocket API entry point is
                  wss://api.altx.com/api/exchange/v1/ws
                </Typography>
                <img src={doc1} alt="s" width="100%" />
                <Typography
                  variant="h6"
                  className="apiDataSubTitle"
                  color="#4231c8"
                  fontSize="24px"
                  fontWeight={600}
                  mt={4}
                >
                  Channels
                </Typography>
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={1}
                >
                  Alt-X WebSocket API provides channels to subscribe.
                </Typography>
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={1}
                >
                  You may subscribe multiple channels simultaneously which are
                  listed below.
                </Typography>
                <ul className="doc1">
                  <li>marketdata</li>
                  <li>exchange_rate</li>
                  <li>open_order (Authorization required)</li>
                  <li>trade_history (Authorization required)</li>
                  <li>order_history (Authorization required)</li>
                  <li>balance (Authorization required)</li>
                </ul>
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={1}
                >
                  Use "type":"subscribe" or "type":"unsubscribe" message to
                  subscribe or unsubscribe respectively and put channel name to
                  subscribe with channel field.
                </Typography>
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={1}
                >
                  Multiple requests in same channel updates the existing channel
                  subscription. (In case of marketdata, refer Marketdata
                  section) Additional fields for each channel should be
                  contained in the message which can be found in API Reference.
                </Typography>
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={1}
                  mb={2}
                >
                  Additional fields for each channel should be contained in the
                  message which can be found in API Reference.
                </Typography>
                <img src={doc2} alt="s" width="100%" />
                <Typography
                  variant="h6"
                  className="apiDataSubTitle"
                  color="#4231c8"
                  fontSize="24px"
                  fontWeight={600}
                  mt={4}
                >
                  Authorization
                </Typography>
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={1}
                  mb={2}
                >
                  In order to subscribe channels which require authorization,
                  the connection should be authorized with
                  "type":"authorization" message. Specify access_token acquired
                  by Authorization API in token field. A single authorization
                  could be used subscribing authorization required channels and
                  remains until the end of the connection.<br></br> Refer API
                  Reference to more information.
                </Typography>
                <img src={doc3} alt="s" width="100%" />
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={1}
                >
                  Refer How to use Websocket API and WebSocket API Reference to
                  find out usage and detailed information.{" "}
                </Typography>
                <img src={doc4} alt="s" width="100%" />
                <ul className="doc1">
                  <li>market_id: market ID to subscribe or unsubscribe</li>
                  <li>
                    interval: Unit time to synchronize market information (ms)
                    <ul className="doc1">
                      <li>Available units: 100, 400</li>
                    </ul>
                  </li>

                  <li>
                    filter: Information you want to receive from the market
                    <ul className="doc1">
                      <li>ticker</li>
                      <li>recent_trades</li>
                      <li>order_books</li>
                      <ul className="doc1">
                        <li>
                          order_books_l0 It does not apply aggregate view,
                          aggregate view is provided in 4 step
                        </li>
                        <li>order_books_l1</li>
                        <li>order_books_l2</li>
                        <li>order_books_l3</li>
                        <li>order_books_l4</li>
                      </ul>
                    </ul>
                  </li>
                </ul>
                <img src={doc5} alt="s" width="100%" />
              </Container>
            )}
            {value == 0 && (
              <Container>
                <Typography variant="h3" className="supportTitle" color="#4231c8" fontWeight={600} style={{marginBottom : 20, marginTop : 36}}>
                  
                  Getting Started with Alt-X API
                </Typography>
                <Typography
                  variant="h6"
                  className="apiDataSubTitle"
                  color="#4231c8"
                  fontSize="24px"
                  fontWeight={500}
                  mt={0}
                >
                  This page will help you get started with Alt-X API.
                </Typography>
                <Typography
                  variant="h6"
                  className="apiDataSubTitle"
                  color="#4231c8"
                  fontSize="18px"
                  fontWeight={400}
                  mt={4}
                >
                  Welcome to Alt-X API document.
                </Typography>
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={1}
                >
                  This document describes how to use Alt-X HTTP/WebSocket
                  API to integrate data such as real-time market status, order,
                  cancel, or user history.
                </Typography>
                <Typography
                  variant="h6"
                  className="apiDataSubTitle"
                  color="#4231c8"
                  fontSize="24px"
                  fontWeight={600}
                  mt={5}
                >
                  HTTP API
                </Typography>
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={1}
                >
                  Entry point of Alt-X HTTP API is https://api.altexchanger.com/api/exchange/v1/ and should be requested in type of
                  Content-Type: application/json.
                </Typography>
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={1}
                >
                  uthorization requiring APIs should contain authorization.
                </Typography>
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={1}
                >
                  Refer HTTP API Reference to find out HTTP APIs we provide.
                </Typography>
                <Typography
                  variant="h6"
                  className="apiDataSubTitle"
                  color="#4231c8"
                  fontSize="24px"
                  fontWeight={600}
                  mt={5}
                >
                  WebSocket API
                </Typography>
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={1}
                >
                  You can fetch real-time market and user data with our
                  WebSocket API.{" "}
                </Typography>
                <Typography
                  variant="body1"
                  className="apiDataDesc"
                  color="#151515"
                  fontWeight={400}
                  mt={1}
                >
                  Refer How to use Websocket API and WebSocket API Reference to
                  find out usage and detailed information.{" "}
                </Typography>
              </Container>
            )}
          </Stack>
        </Stack>
      </Container>
    </Layout>
  );
};

export default Anoucements;
