import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { DarkModeContext } from '../../ThemeContext'
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { useParams } from 'react-router-dom';


const Chart = () => {
    let location = useLocation()
    const { darkMode } = useContext(DarkModeContext)
    let { state, upperDataObj, chartKey } = location?.state;
    const { coin } = useParams();
    console.log("coin name : ", coin)
    console.log("state : ", state)
    return (
        <div style={{ backgroundColor: darkMode ? "#1E1E1E" : "#E9ECF1", height: "100vh", padding: 20, paddingBottom: 80 }}>
            {/* <div className="bitCoinPriceDiv">
                {state ? (
                    <>
                        <h2 style={{ color: darkMode ? "#ddd" : "#232323" }}>{state?.crypto}</h2>
                        <p style={{ color: darkMode ? "#ddd" : "#232323" }}>{state?.symbol}</p>
                    </>
                ) : (
                    <>
                        <h2 style={{ color: darkMode ? "#ddd" : "#232323" }}>{upperDataObj.name}</h2>
                        <p style={{ color: darkMode ? "#ddd" : "#232323" }}>{upperDataObj.pair}</p>
                    </>
                )}
                <div className="priceTxTDiv">
                    <p style={{ color: darkMode ? "" : "#232323" }}>Last Price</p>
                    <h6 style={{ color: darkMode ? "" : "#232323" }}>{upperDataObj.lastPrice}</h6>
                </div>
                <div className="priceTxTDiv">
                    <p style={{ color: darkMode ? "" : "#232323" }}>24hr Cange</p>
                    <h6 style={{ color: darkMode ? "" : "#232323" }}>{upperDataObj.change24HR} {upperDataObj.to_coin}</h6>
                </div>
            </div> */}

            {/* <div className="exchange-center-container"> */}
            {
                upperDataObj?.to ? <AdvancedRealTimeChart
                    key={chartKey} Interval="240" theme={darkMode ? "dark" : "light"} width="100%" height="100%" 
                    allow_symbol_change={false}
                    hide_legend={true}
                    symbol={(state?.symbol) ? (state?.symbol) : `${upperDataObj.to}${upperDataObj.from}`}
                >

                </AdvancedRealTimeChart> : null
            }
        </div>
    )
}

export default Chart