import React, { useState } from 'react';
import { Button, Menu, MenuItem, ListItemIcon } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language'; // Import the language icon of your choice
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const languages = [
  { "code": "English", "label": "English", "icon": "🇺🇸" },
  { "code": "Spanish", "label": "Spanish", "icon": "🇪🇸" },
  { "code": "Chinese", "label": "Chinese", "icon": "🇨🇳" },
  { "code": "Hindi", "label": "Hindi", "icon": "🇮🇳" },
  { "code": "Arabic", "label": "Arabic", "icon": "🇸🇦" },
  { "code": "French", "label": "French", "icon": "🇫🇷" },
]
  ;
const Language = () => {
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [showOptions, setShowOptions] = useState(false)

  return (
    <div style={{ position: "relative" }}>
      <Button
        aria-controls="language-menu"
        aria-haspopup="true"
        variant="outlined"
        onClick={() => setShowOptions(!showOptions)}
        startIcon={<LanguageIcon />}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ color: "#4231C8", textTransform: "capitalize", }}
        className="drop-btn"
      >
        {selectedLanguage}
      </Button>
      {showOptions && (
        <div style={{
          position: "absolute",
          zIndex : 2,
          padding: 12,
          backgroundColor: "white",
          boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)'
        }}>
          {languages.map((language) => (
            <button
              onClick={() => {setSelectedLanguage(language.code); setShowOptions(false)}}
              style={{ display: "flex", gap: 20, justifyContent: "space-between", marginBottom: 10, background : "none", border : "none" }}>
              <div>{language.icon}</div>
              <div style={{ textAlign: "left", width: 80 }}>{language.label}</div>
            </button>
          ))}
        </div>
      )}
      {/* <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map((language) => (
          <MenuItem key={language.code} onClick={() => handleLanguageSelect(language.code)}>
            <ListItemIcon>{language.icon}</ListItemIcon>
            {language.label}
          </MenuItem>
        ))}
      </Menu> */}
    </div>
  )
}

export default Language