import React, { useContext, useState } from 'react'
import {
    Stack,
    Typography,
    Tabs,
    Tab
} from '@mui/material';
import { DarkModeContext } from '../ThemeContext';
const SortMarket = () => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const { darkMode } = useContext(DarkModeContext)
    return (
        <Stack
            direction="column"
        >
            <Typography
                sx={{
                    color: darkMode && "#e1e1e1"
                }}
                variant="h5"
                className="titleHeading"
            >
                Markets
            </Typography>

            <Tabs
                value={value}
                onChange={handleChange}
                sx={{
                    paddingBottom: "3px",
                    "& .MuiTabs-indicator": {
                        backgroundColor: "#fff",
                        paddingBottom: "3px",
                    },
                    "&.Mui-selected": {
                        color: "#4231C8",
                    },
                }}
            >
                <Tab
                    label="Popular"
                    className="popuText"
                    sx={{
                        "&.Mui-selected": {
                            color: "#4231C8",
                        },
                        color: "#999999",
                        fontSize: "16px",
                        textTransform: "capitalize",
                    }}
                />
                <Tab
                    label="Gainers"
                    className="popuText"
                    sx={{
                        "&.Mui-selected": {
                            color: "#4231C8",
                        },
                        color: "#999999",
                        fontSize: "16px",
                        textTransform: "capitalize",
                    }}
                />
                <Tab
                    label="New"
                    className="popuText"
                    sx={{
                        color: "#999999",
                        "&.Mui-selected": {
                            color: "#4231C8",
                        },
                        fontSize: "16px",
                        textTransform: "capitalize",
                    }}
                />
            </Tabs>
        </Stack>
    )
}

export default SortMarket