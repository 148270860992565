import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import { storeUserProfileData, getUserProfileData } from "../../services/LocalStorageService";
import Divider from "@mui/material/Divider";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { removeUserProfileData } from "../../services/LocalStorageService";

import profile from "../../assets/profile.svg";
import p1 from "../../assets/p1.svg";
import p2 from "../../assets/p2.svg";
import p3 from "../../assets/p3.svg";
import p4 from "../../assets/p4.svg";
import p5 from "../../assets/p5.svg";
import p6 from "../../assets/p6.svg";
import { Box } from "@mui/material";
import { removeToken } from "../../services/LocalStorageService";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));


const ProfileSelect = ({ access_token }) => {
  const navigate = useNavigate()
  console.log(access_token, 'access_token in profile btn', localStorage, 'localstorage')
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userProfileData = getUserProfileData();
  const [showMenu, setShowMenu] = React.useState(false)
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logOutUser = () => {
    console.log('clicked on Logout')
    removeUserProfileData()
    removeToken()
    navigate('/login')
  }
  return (
    <div style={{ marginLeft: "auto", position: "relative" }} className="walletDrop">
      <div
        onMouseEnter={() => setShowMenu(true)}
        onMouseLeave={() => setShowMenu(false)}
        style={{
          display: "flex",
          gap: 4,
          backgroundColor: "#4231c8",
          border: "none",
          fontSize: 14,
          marginTop: 7,
          cursor: "pointer",
          color: "white",
          borderRadius: "50px",
          marginRight: "16px",
          marginTop: "auto",
          marginBottom: "auto",
          // color: pathname === "/wallet" ? (darkMode ? "#fff" : "#4231C8") : (darkMode ? "#999" : "#151515"),
        }}
        className="menuItem"
      >
        <img src={profile} alt="s" style={{ marginRight: 8 }} />
        <div style={{ fontSize: 14, fontWeight: "bold" }}>Profile</div>
        <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 28 28" data-testid="KeyboardArrowDownIcon"><path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6z"></path></svg>
      </div>
      {showMenu && (
        <div
          onMouseEnter={() => setShowMenu(true)}
          onMouseLeave={() => setShowMenu(false)}
          style={{
            width: 300,
            backgroundColor: "white",
            paddingTop: 4,
            paddingBottom: 4,
            position: "absolute",
            boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: "6px",
            position: "absolute",
            right: 14,
          }}>
          <Link to="/profile">
            <div style={{ display: "flex", gap: 4 }} className="menuItem">
              <img src={p1} alt="icon" className="profileDropIcons" />
              <div style={{ textTransform: "lowercase", height: 24 }}> {userProfileData.email}</div>
              <div style={{width : 24, height : 24}}>
                <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="KeyboardArrowDownIcon"
                  style={{ transform: "rotate(-90deg)" }}>
                  <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6z">
                  </path>
                </svg>
              </div>
            </div>
          </Link>
          <Link to="/profile">
            <div style={{ display: "flex", gap: 4 }} className="menuItem">
              <img src={p2} alt="icon" className="profileDropIcons" />
              <div>Verification</div>
            </div>
          </Link>
          <Link to="/profile">
            <div style={{ display: "flex", gap: 4 }} className="menuItem">
              <img src={p3} alt="icon" className="profileDropIcons" />
              <div>Security</div>
            </div>
          </Link>
          <Link to="/api">
            <div style={{ display: "flex", gap: 4 }} className="menuItem">
              <img src={p4} alt="icon" className="profileDropIcons" />
              <div>API Management</div>
            </div>
          </Link>
          <Link to="/profile">
            <div style={{ display: "flex", gap: 4 }} className="menuItem">
              <img src={p5} alt="icon" className="profileDropIcons" />
              <div>Settings</div>
            </div>
          </Link>
          <button onClick={logOutUser} style={{ display: "flex", gap: 4, background: 'none', border: 'none' }} className="menuItem">
            <img src={p6} alt="icon" className="profileDropIcons" />
            <div>Logout</div>
          </button>

        </div>
      )}
    </div>
  );
};

export default ProfileSelect;
