import React, { useState } from "react";
import ImageUpload from "./ImageUpload";
import copy from 'clipboard-copy';
import { storeUserProfileData,getUserProfileData } from "../../../services/LocalStorageService";

const UserProfile = (props) => {
  const defaultUid = '1021 5287 8602';
  const uid = props.uid || defaultUid;
  const userProfileData = getUserProfileData();
  const [copied, setCopied] = useState(false)

  const handleCopyClick = () => {
    copy(uid);
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 3000)
    // alert('UID copied to clipboard!');
  };

  return (
    <div className="profile-view">
      <p className="profile-title" style={{paddingTop: 24, paddingBottom: 24}}>Profile</p>
      <div className="profile-div">
        <div style={{paddingLeft : 20}} className="row mx-0">
          <div className="col-xl-2 col-lg-2 col-md-3 d-flex flex-column uploadImg">
            <ImageUpload />
          </div>
          <div style={{paddingLeft: 30}} className="col-xl-6 col-lg-5 col-md-9  d-flex flex-column middle-col gapCol">
            <span className="user-email">{userProfileData.email}</span>
            <span style={{paddingTop: 20, paddingBottom : 20}} className="last-login">
              <span> Last login:</span> 2023-12-27 16:44:20 (UTC+5)
            </span>
            <span className="user-verify">Status: <span> Verification required</span></span>
          </div>
          <div className="col-xl-4 col-lg-5 col-md-12  d-flex flex-column align-items-lg-end middle-col gapCol gapCol2">
          <span className="user-id">
          <p>
            <span>UID:</span> {uid}
          </p>
          <img
            src={copied ? require("../../../assets/tick.png") : require("../../../assets/copy.svg").default}
            className="copyImage"
            alt="Copy UID"
            onClick={handleCopyClick}
            style={{ cursor: 'pointer', height : 20 }}
          />
    </span>
            <span className="user-ip"><p><span>IP:</span> 72.255.7.202</p></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
