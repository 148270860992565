const storeToken = (value) => {
  if (value) {
    console.log("Store Token", value);
    const { access, refresh } = value;
    localStorage.setItem('access_token', access);
    localStorage.setItem('refresh_token', refresh);
  }
};

const getToken = () => {
  let access_token = localStorage.getItem('access_token');
  let refresh_token = localStorage.getItem('refresh_token');
  return { access_token, refresh_token };
};

const removeToken = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
};

const storeUserProfileData = (userData) => {
  if (userData) {
    localStorage.setItem('user_profile_data', JSON.stringify(userData));
  }
};

const getUserProfileData = () => {
  const userDataString = localStorage.getItem('user_profile_data');
  return JSON.parse(userDataString);
};

const removeUserProfileData = () => {
  localStorage.removeItem('user_profile_data');
};

export { storeToken, getToken, removeToken, storeUserProfileData, getUserProfileData, removeUserProfileData };