import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useContext } from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import ecips from "../../../assets/exlipPattern.png";
import mobile from "../../../assets/mobile.svg";
import Layout from "../../layouts/Layout";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { DarkModeContext } from "../../../ThemeContext";
import { DarkMode, Password } from "@mui/icons-material";
import url from "../../../config";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';



const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 6,
  width: 19,
  color: "#4231C8",
  height: 19,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px #4231C8"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto #4231C8",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#4231C8",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 19,
    height: 19,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}
const ResetPassword = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const email = location.state.email;

  const [password, Setpassword] = useState(null);
  const [resetError, setResetError] = useState('')
  const [resetSuccess, setResetSuccess] = useState('')
  const [check, setcheck] = useState(false);
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",

      border: "1px solid #4231C8",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));


  const passwordHandler = (value) => {
    Setpassword(value)
  }
  const resetPasswordHandler = async () => {
    try {
      const requestData = {
        email: email, // Replace with the actual email
        new_password: password // Replace with the new password
      };

      const response = await axios.post(`${url}api/v1/user-auth/reset-password/ `, requestData, {


      });

      if (response.status == 200 || 201) {
        const accSussesMsg = "Password Reset Succesful !"
        // Swal.fire({
        //   icon: 'success',
        //   title: 'Success',
        //   text: accSussesMsg, // Change to your data property
        // }).then(() => {
        //   navigate('/login');
        // })
        setResetSuccess("Password Reset Succesful !")
        setTimeout(() => {
          setResetSuccess("")
          navigate('/login');
        }, 5000)
      }


      // Handle success response as needed
    } catch (error) {
      console.error('Error resetting password:', error);
      // Handle error as needed
    }


  }
  const { darkMode } = useContext(DarkModeContext)
  return (
    <Layout>
      <Stack sx={{ bgcolor: darkMode ? "#282828" : "#FAFAFA", pb: 10 }}>
        <Container className="manageContainer">
          <Grid container spacing={2} xs={12}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack
                direction="column"
                sx={{
                  paddingTop: { xs: 15, md: 0 },
                  width: { xs: "100%", md: "50%" },
                  mt: { md: 25, sm: 0, xm: 0 },
                  mb: 10,
                }}
              >
                <Link to="/login" className="backDiv">
                  <Stack direction="row" alignItems="center" mb={1}>
                    <KeyboardArrowLeftIcon style={{ color: darkMode ? "#e1e1e1" : "#4231c8" }} className="backArrow" />
                    <Typography style={{ color: darkMode ? "#e1e1e1" : "#4231c8" }} variant="body2" className="backBtn">Back to login</Typography>
                  </Stack>
                </Link>

                <Typography
                  variant="h3"
                  sx={{
                    color: darkMode ? "#e1e1e1" : "#4231C8",
                    fontWeight: 600,
                  }}
                  className="supportTitle"
                >
                  Reset Your Password
                </Typography>
                <div className="formInputDivs my-4">
                  <label htmlFor="email" style={{ color: darkMode && "#e1e1e1" }} className="labelTxt mb-2">
                    New Passsword
                  </label>
                  <input
                    className="form-control textInput formInputs"
                    id="password"
                    onChange={(e) => { passwordHandler(e.target.value) }}
                    value={password}
                  />
                   {resetSuccess && <Typography color="green">{resetSuccess}</Typography>}
                </div>
                <Button

                  sx={{
                    height: 50,
                    width: 420,
                    textTransform: "capitalize",
                    bgcolor: "#4231C8",
                    "&:hover": { bgcolor: "#4231C8" },
                  }}
                  variant="contained"
                  className="formBtn"
                  onClick={resetPasswordHandler}
                >
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Stack>
    </Layout>
  );
};

export default ResetPassword;
