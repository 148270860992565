import { Grid } from "@mui/material";
import React from "react";

export const Tabs = ({ apiTabs, tabSelection }) => {
  return (
    <Grid container spacing={{ xs: 2, md: 3 }} >
      <Grid item xs={12}
        sm={12}
        md={8}
        lg={8}
        xl={8}  display={"flex"} flexDirection={"row"}>
        {apiTabs?.map((item, index) => {
          return (
            <div
              className={
                item?.selected ? `active-api-tab-style` : `api-tab-style`
              }
              onClick={tabSelection(index)}
            >
              {item?.name}
            </div>
          );
        })}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <div className="view-ppi-btn cursorPointer">view aPI Document</div>
      </Grid>
    </Grid>
  );
};
