import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import avatar from "../../assets/avatarWhite.png"
import { Button, Stack, Typography } from "@mui/material";
import red from "../../assets/red.png"
import blue from "../../assets/blue.png"
import white from "../../assets/white.png"
// Coins Images
import BTC from "../../assets/coins/BTC.png"
import BPLC from "../../assets/coins/BPLC.png"
import SHIB from "../../assets/coins/SHIB.png"
import RWN from "../../assets/coins/RWN.png"
import ETH from "../../assets/coins/ETH.png"
import LPNT from "../../assets/coins/LPNT.png"
import DOGE from "../../assets/coins/DOGE.png"
import BNB from "../../assets/coins/BNB.png"
import BIZA from "../../assets/coins/BIZA.png"
import MINU from "../../assets/coins/MINU.png"
import { DarkModeContext } from "../../ThemeContext";
import { tableCellClasses } from "@mui/material/TableCell";


const TableLayout = () => {

  const navigate = useNavigate();
  function createData(name, curency, price, volume, change, trade, crypto, icon, symbol) {
    return { name, curency, price, volume, change, trade, crypto, icon, symbol };
  }

  const rows = [
    createData("BTC", "/USDT", 16.6543, "65.54K", -0.43, "Trade", "Bitcoin", BTC, "BTCUSD"),
    createData("BPLC", "/USDT", 16.6543, "65.54K", +0.43, "Trade", "BlackPearl", BPLC, "BPLCUSDT"),
    createData("SHIB", "/USDT", 16.6543, "65.54K", -0.43, "Trade", "Shiba Inu", SHIB, "SHIBUSD"),
    createData("RWN", "/USDT", 16.6543, "65.54K", +0.43, "Trade", "Rowan Coin", RWN, "RWNUSDT"),
    createData("ETH", "/USDT", 16.6543, "65.54K", -0.43, "Trade", "Ethereum", ETH, "ETHUSDT"),
    createData("LPNT", "/USDT", 16.6543, "65.54K", -0.43, "Trade", "Luxurious Pro Network Token", LPNT, "LPNTUSDT"),
    createData("DOGE", "/USDT", 16.6543, "65.54K", -0.43, "Trade", "Dogecoin", DOGE, "DOGEUSDT"),
    createData("BNB", "/USDT", 16.6543, "65.54K", -0.43, "Trade", "Binance Coin", BNB, "BNBUSDT"),
    createData("BIZA", "/USDT", 16.6543, "65.54K", -0.43, "Trade", "BizAuto", BIZA, "BIZAUSDT"),
    createData("MINU", "/USDT", 16.6543, "65.54K", -0.43, "Trade", "Minu", MINU, "MINUUSDT"),
  ];
  const { darkMode } = useContext(DarkModeContext)
  return (
    <TableContainer
      style={{ backgroundColor: darkMode ? "#1E1E1E" : "" }}
      className="tableRowBodyWalletCont" component={Paper} sx={{ marginTop: 2, boxShadow: "none" }} >
      <Table sx={{
        minWidth: 650,
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none"
        }
      }} aria-label="simple table" className="tablediv">
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: "#999999" }} className="marketRow marketRowCol1">Market</TableCell>
            <TableCell sx={{ color: "#999999" }} className="marketRow" align="left">Last Price</TableCell>
            <TableCell sx={{ color: "#999999" }} className="marketRow" align="left">Volume</TableCell>
            <TableCell sx={{ color: "#999999" }} className="marketRow" align="left">24hr Change</TableCell>
            <TableCell sx={{ color: "#999999" }} className="marketRow" align="left">Chart</TableCell>
            <TableCell sx={{ color: "#999999" }} className="marketRow" align="left"></TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            // linkProps = {
            //   pathname : "/exchange",
            //   search
            // }
            return (
              <TableRow
                key={row.name}
                sx={{
                  borderBottom: "none !important",
                  "&:last-child": {
                    borderBottom: "none !important",
                  },
                  "&:first-child": {
                    borderTop: "none !important",
                  },
                }}
                className="tableRowBody"
              >
                <TableCell component="th" scope="row" className="marketRowCol1Td">
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <img src={row.icon} alt="s" style={{ width: 28, height: 28, borderRadius: "50%" }} />
                    <Typography sx={{ fontWeight: "bold", }}>
                      <Typography sx={{ display: "flex", fontWeight: "700", alignItems: "center", color: darkMode ? "#e1e1e1" : "" }}>
                        {row.name}
                        <Typography variant="body2" sx={{ marginLeft: 0.5, fontWeight: "400", color: "#7b7b7b" }}>{row.curency}</Typography>
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#7b7b7b", fontSize: 12 }}>{row.crypto}</Typography></Typography>

                  </Stack>

                </TableCell>
                <TableCell style={{ color: "#999" }} align="left">{row.price}</TableCell>
                <TableCell style={{ color: "#999" }} align="left" ><Typography variant="body2" sx={{ display: "flex", fontWeight: "bold", }}>{row.volume}<Typography variant="body2" sx={{ marginLeft: 1 }}>{row.name}</Typography></Typography></TableCell>
                <TableCell style={{ color: "#999" }} align="left">{Math.sign(row.change) == -1 ? <Typography sx={{ color: "red" }}>{row.change}%</Typography> : <Typography sx={{ color: darkMode ? "green" : "#1D2F6F" }}>+{row.change}%</Typography>}</TableCell>
                <TableCell style={{ color: "#999" }} align="left" sx={{ width: 20 }}><img src={darkMode ? white : blue} width={168} /></TableCell>
                {/* <TableCell style={{ color: "#999" }} align="left" sx={{ width: 20 }}>{Math.sign(row.change) == -1 ? <img src={red} alt="s" /> : <img src={darkMode ? white : blue} alt="s" />}</TableCell> */}
                <TableCell style={{ color: "#999" }} align="left" sx={{ fontWeight: "bold", color: "#4231C8" }} >
                  <div className="marketRowColLast">
                    <Button className="tradeBtn" onClick={() => navigate('/exchange', { state: { symbol: row.symbol, crypto: row.crypto, name: row.name } })}>
                      Trade
                    </Button>
                  </div>
                </TableCell>
              </TableRow>

            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableLayout;
