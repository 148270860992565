import React from "react";

export const CreateApiKey = ({ onClick }) => {
  return (
    <div className="create-key-container">
      <div className="create-btn-div">
        <label className="api-label">API key label</label>
        <div className="create-btn" onClick={onClick}>
          Create new key
        </div>
      </div>
    </div>
  );
};
