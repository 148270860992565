import { Button, Container, Menu, Stack, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  coinsArray,
  despositArray,
  transactionColumn1,
} from "../constants/Data";
import CoinBtn from "../layouts/CoinBtn";
import DatePicker from "../layouts/DatePicker";
import Layout from "../layouts/Layout";
import TransactioHistoryTable from "../layouts/TransactioHistoryTable";
const TransactionHistory = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorE2);
  const [filter, setFilter] = useState("");
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [transactionOriginalHistory, setTransactionOriginalHistory] = useState(
    []
  );

  const [coinFilter, setCoinFilter] = useState("");
  const [depositFilter, setDepositFilter] = useState("");

  const [fname, setfname] = useState("BTC");
  const [lname, setlname] = useState("Bitcoin");
  const handleClose = (a, b, c) => {
    setfname(b);
    setlname(c);
    setAnchorEl(null);
  };

  useEffect(() => {
    function createData(time, type, status, coin, amount) {
      return { time, type, status, coin, amount };
    }

    const rows = [
      createData(
        "2023-12-21 14:26:36",
        "Received",
        "Completed",
        "BITCOIN",
        "+1.12345 BTC"
      ),
      createData(
        "2024-01-1 14:26:36",
        "Received",
        "Completed",
        "BITCOIN",
        "+1.12345 BTC"
      ),
      createData(
        "2024-01-12 14:26:36",
        "Received",
        "Completed",
        "BITCOIN",
        "+1.12345 BTC"
      ),
      createData(
        "2024-01-22 14:26:36",
        "Received",
        "Completed",
        "BITCOIN",
        "+1.12345 BTC"
      ),
      createData(
        "2024-01-29 14:26:36",
        "Received",
        "Completed",
        "BITCOIN",
        "+1.12345 BTC"
      ),
    ];
    setTransactionHistory(rows);
    setTransactionOriginalHistory(rows);
  }, []);

  const handleClose1 = (a, b, c) => {
    setAnchorE2(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick1 = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      width: { xs: "90%", md: "80%" },
      marginRight: "auto",
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const handleFilters = (value) => {
    let filteredData = transactionOriginalHistory?.filter(filterMethod(value));
    setTransactionHistory(filteredData);
  };

  const filterMethod = (search) => {
    return (records) => {
      let find = `${moment(records?.time, ["YYYY-MM-DD hh:mm:ss"]).format(
        "YYYY-MM-DD"
      )} ${records?.type}`;
      return find?.toLowerCase().includes(search.toLowerCase()) || !search;
    };
  };

  return (
    <Layout>
      <Container className="manageContainer" sx={{ mt: 16, mb: 16 }}>
        <Stack>
          <Typography
            variant="h3"
            sx={{ fontWeight: 800, color: "#1D2F6F", mb: 8 }}
          >
            Transaction History
          </Typography>
        </Stack>
        <Stack
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexDirection={"row"}
        >
          <CoinBtn
            name="Coin name"
            list={coinsArray}
            getValue={(value) => {
              if (value === "") {
                setFilter("");
              }
              setCoinFilter(value);
            }}
          />
          <DatePicker
            name="pad"
            getValue={(value) => {
              let date = value?.toString();
              let breakDate = date?.split("/")[1];
              setFilter(moment(breakDate).format("YYYY-MM-DD"));
            }}
          />
          <CoinBtn
            name="Deposit/Withdrawal"
            list={despositArray}
            getValue={(value) => {
              if (value === "") {
                setFilter("");
              }
              setDepositFilter(value);
            }}
          />
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 3,
            paddingBottom: 3,
            borderBottom: "1px solid #1D2F6F",
            flexWrap: "wrap",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Button
              onClick={() =>
                handleFilters(filter || coinFilter || depositFilter)
              }
              variant="contained"
              href="#outlined-buttons"
              sx={{
                color: "#ffffff",
                border: "1px solid #1D2F6F",
                textTransform: "capitalize",
                bgcolor: "#1D2F6F",
                width: 100,
              }}
            >
              Search
            </Button>
          </Stack>
          <Stack direction="row" spacing={5}>
            <Button
              sx={{
                bgcolor: "#FAFAFA",
                color: "#1D2F6F",
                textTransform: "capitalize",
                fontWeight: 500,
                padding: "10px 30px 10px 30px",
              }}
            >
              Download CV
            </Button>
            <Button
              onClick={() => {
                handleFilters("");
                setCoinFilter("");
                setFilter("");
                setDepositFilter("");
              }}
              sx={{
                bgcolor: "#FAFAFA",
                color: "#1D2F6F",
                textTransform: "capitalize",
                fontWeight: 500,
                padding: "10px 30px 10px 30px",
              }}
            >
              Reset
            </Button>
          </Stack>
        </Stack>
        <TransactioHistoryTable
          data={transactionHistory}
          search={filter || coinFilter || depositFilter}
          filterMethod={filterMethod}
          columns={transactionColumn1}
        />
        {transactionHistory.filter(
          filterMethod(filter || coinFilter || depositFilter)
        )?.length === 0 ? (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems={"center"}
            mt={5}
          >
            <img
              src={require("../../assets/noData.svg").default}
              alt="s"
              // width="200px"
            />
            <p className="no-data-found mt-3">No Transactions found</p>
          </Stack>
        ) : null}
      </Container>
    </Layout>
  );
};

export default TransactionHistory;
