import React from "react";
import {
    securitySteps,
    tableCol1,
    tableCol2
} from "../../constants/Data";
import { CustomTable } from "../../layouts/table/CustomTable";

const Security = () => {
  const twoFactorAuthentication = () => {
    return (
      <div style={{padding : 50}} className="security-div">
      <div className="row mx-0">
        <span className="security-sub-title mb-2 title01">
          Two-factor authentication
        </span>
        {securitySteps?.map((item, index) => {
          return (
            <div className={`row mx-0 px-0 ${index === 0 ? "bottomBorder" : ""}`}
            >
              <div style={{paddingTop : 20, paddingBottom: 20}} className="col-lg-9 col-md-8 col-sm-8 col-12 d-flex flex-row keyCol">
                <img src={item?.image.default} className="me-4" />
                <div className="d-flex flex-column">
                  <span className="sec-name">{item?.name}</span>
                  <span className="sec-dec">{item?.desc}</span>
                </div>
              </div>
              <div style={{paddingTop : 20}} className="col-lg-3 col-md-4 col-sm-4 col-12 d-flex flex-column align-items-end justify-content-center enableCol">
                <div className="enable-btn">Enable</div>
              </div>
            </div>
          );
        })}
      </div>
      </div>
    );
  };

  const loginPassword = () => {
    return (
      <div className="security-div mt-4">
      <div className="row mx-0">
        <div className="col-lg-8 col-md-7 col-sm-7 col-12 title01 d-flex flex-column justify-content-center">
          <span className="login-text security-sub-title">Login password</span>
        </div>
        <div className="col-lg-4 col-md-5 col-sm-5 rightPadd col-12 d-flex flex-column justify-content-lg-center align-items-lg-end">
          <div className="light-btn">Change Password</div>
        </div>
      </div>
      </div>
    );
  };

  const deviceManagement = () => {
    return (
      <div className="security-div mt-4">
        <div className="row mx-0">
          <div className="col-lg-8 col-md-7 col-sm-7 col-12 title01 d-flex flex-column justify-content-center">
            <span className="login-text security-sub-title">Device management</span>
          </div>
          <div className="col-lg-4 col-md-5 col-sm-5 col-12 rightPadd d-flex flex-column justify-content-lg-center align-items-lg-end">
            <div className="light-btn">Delete all</div>
          </div>
        </div>
        <div className="row mt-2">
          <CustomTable columns={tableCol1} />
        </div>
      </div>
    );
  };

  const securityLogs = () => {
    return (
      <div className="security-div mt-4">
        <div className="row mx-0">
          <div className="col-lg-12 title01 d-flex flex-column justify-content-center">
            <span className="login-text security-sub-title">Security log</span>
            <span className="log-desc mt-1">
              Suspicious activity detected? <span>Disable account</span>{" "}
            </span>
          </div>
        </div>
        <div className="row mt-2">
          <CustomTable columns={tableCol2} show={false} />
        </div>
      </div>
    );
  };

  return (
    <div className="profile-view">
      <p style={{marginTop : 20, marginBottom: 50}} className="profile-title">Security</p>
      {twoFactorAuthentication()}
      {loginPassword()}
      {deviceManagement()}
      {securityLogs()}
    </div>
  );
};

export default Security;
