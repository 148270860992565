import { Container, Grid, Stack, Typography, Box, Button } from "@mui/material";
import React, { useContext } from "react";
import Layout from "../../layouts/Layout";
import Group01 from "../../../assets/ieo-listing-coingecko.svg";
import Group02 from "../../../assets/ieo-listing-coingecko (1).svg";
import Group03 from "../../../assets/ieo-listing-coingecko (2).svg";
import side from "../../../assets/Group 1135.svg";
import Group3 from "../../../assets/Group3.png";
import Group1 from "../../../assets/Group1.png";
import avatar2 from "../../../assets/avatar2.png";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import group19 from "../../../assets/Group19.png";
import group1130 from "../../../assets/Untitled.svg";
import vector5 from "../../../assets/vector5.png";
import vector3 from "../../../assets/vector3.png";
import arrows from "../../../assets/right-arrows.png";
import lock from "../../../assets/lock2.png";
import globe from "../../../assets/phone-call2.png";
import chart from "../../../assets/chart2.png";
import phone from "../../../assets/phone-call.png";
import admin from "../../../assets/admin2.png";
import wallet from "../../../assets/wallet 2.png";
import { DarkModeContext } from "../../../ThemeContext";
const Listing = () => {
  const positionRef = React.useRef({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef(null);
  const areaRef = React.useRef(null);
  const { darkMode } = useContext(DarkModeContext)

  return (
    <Layout>
      <Stack>
        <Container
          sx={{
            mt: {
              md: 10,
            },
          }}
          className="manageContainer"
        >
          <Stack
            direction="column"
            alignItems="center"
            sx={{ paddingTop: { xs: 10, md: 0 } }}
          >
            <img src={group19} className="group19" alt="s" />
            <Box
              width="100%"
              sx={{
                marginBottom: { md: 15, xs: 8 },
                bgColor: "#000000",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ color: darkMode && "#e1e1e1" }}
                variant="h2"
                className="titleHead01"
              >
                List Your Coin on <span>ALT-X</span>
              </Typography>
              <Typography
                variant="body1"
                className="subTitlP"
                sx={{
                  color: "#999999",
                  textAlign: "center",
                  width: { md: "581px", sm: "70%" },
                }}
              >
                Are you a cryptocurrency project looking to increase <br />
                visibility and accessibility for your token? ALT-X offers you <br />
                the opportunity to list your coin on our platform, providing <br />
                you with a gateway to a global audience of traders and investors
              </Typography>
            </Box>
          </Stack>
        </Container>
        <Container className="manageContainer p-0" sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
          {/* <div className="priceShowSection priceShowSectionListing">
                  <div class="marketPriceDiv">
                    <img src={vector5} alt=""/>
                    <div className="pricteTxtDiv">
                      <h6>500+</h6>
                      <p>Listings</p>
                    </div>
                  </div>
                  <div class="marketPriceDiv">
                    <img src={vector3} alt=""/>
                    <div className="pricteTxtDiv">
                      <h6>1,000,000</h6>
                      <p>Registered Users</p>
                    </div>
                  </div>
                  <div class="marketPriceDiv">
                    <img src={vector4} alt=""/>
                    <div className="pricteTxtDiv">
                      <h6>488,000</h6>
                      <p>Weekly Visitors</p>
                    </div>
                  </div>
                </div> */}
          <Stack
            direction="row"
            justifyContent="center"
            sx={{ borderBottom: "1px solid #4231C8", width: "100%", marginTop: "3rem" }}
          >
            <Typography
              variant="h5"
              className="trustText"
              sx={{
                paddingBottom: 3,
                textAlign: "center",
                color: darkMode && "#e1e1e1"
              }}
            >
              Why List Your Coin on <span className="blue">ALT-X? </span>
            </Typography>
          </Stack>
          <Stack className="cardMainDiv"
            useFlexGap
            flexWrap="wrap"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
              mt: 6,
            }}
          >
            <Stack
              Item
              sx={{
                width: { xs: "85%", md: "18%", sm: "45%" },
                borderRadius: "30px",
                minHeight: { md: "360px", xs: "auto" },
                border: "1.2px solid #ECECEC",
                backgroundColor: darkMode ? "#323232" : "#FFFFFF"
              }}
              spacing={{ xs: 1, sm: 2 }}
              paddingTop={2}
              paddingBottom={2}
              paddingLeft={1}
              paddingRight={1}
              direction="column"
              alignItems="center"
            >
              <Box>

                <img className="eclips3" src={lock} alt="s" />
              </Box>
              <Typography style={{ color: darkMode ? "#e1e1e1" : "#4231c8" }} className="storageHeading">
                Exposure
              </Typography>
              <Typography
                variant="body1"
                className="storageSubHeading"

              >
                Gain exposure to a diverse and engaged
                community of traders and investors on Alt-X, expanding your project's reach and visibility in the crypto market
              </Typography>
            </Stack>
            <Stack
              Item
              sx={{
                width: { xs: "85%", md: "18%", sm: "45%" },
                borderRadius: "30px",
                minHeight: { md: "360px", xs: "auto" },
                border: "1.2px solid #ECECEC",
                backgroundColor: darkMode ? "#323232" : "#FFFFFF"
              }}
              spacing={{ xs: 1, sm: 2 }}
              paddingTop={2}
              paddingBottom={2}
              paddingLeft={1}
              paddingRight={1}
              direction="column"
              alignItems="center"
            >
              <Box>
                <img className="eclips3" src={chart} alt="s" />
              </Box>
              <Typography style={{ color: darkMode ? "#e1e1e1" : "#4231c8" }} className="storageHeading">
                Liquidity
              </Typography>
              <Typography
                variant="body1"
                className="storageSubHeading"
              >
                By listing your coin on Alt-X, you gain access to our liquidity pool, making it easier for traders to buy and sell your token, thus enhancing liquidity for your project
              </Typography>
            </Stack>
            <Stack
              Item
              sx={{
                width: { xs: "85%", md: "18%", sm: "45%" },
                borderRadius: "30px",
                minHeight: { md: "360px", xs: "auto" },
                border: "1.2px solid #ECECEC",
                backgroundColor: darkMode ? "#323232" : "#FFFFFF"
              }}
              spacing={{ xs: 1, sm: 2 }}
              paddingTop={2}
              paddingBottom={2}
              paddingLeft={1}
              paddingRight={1}
              direction="column"
              alignItems="center"
            >
              <Box>
                <img className="eclips3" src={globe} alt="s" />
              </Box>
              <Typography style={{ color: darkMode ? "#e1e1e1" : "#4231c8" }} className="storageHeading">
                Global Reach
              </Typography>
              <Typography
                variant="body1"
                className="storageSubHeading"
              >
                With users from all around the world, Alt-X
                offers you access to a global audience of traders and investors, allowing you to tap into new markets and expand your project's user base
              </Typography>
            </Stack>
            <Stack
              Item
              sx={{
                width: { xs: "85%", md: "18%", sm: "45%" },
                borderRadius: "30px",
                minHeight: { md: "360px", xs: "auto" },
                border: "1.2px solid #ECECEC",
                backgroundColor: darkMode ? "#323232" : "#FFFFFF"
              }}
              spacing={{ xs: 1, sm: 2 }}
              paddingTop={2}
              paddingBottom={2}
              paddingLeft={1}
              paddingRight={1}
              direction="column"
              alignItems="center"
            >
              <Box>
                <img className="eclips3" src={wallet} alt="s" />
              </Box>
              <Typography style={{ color: darkMode ? "#e1e1e1" : "#4231c8" }} className="storageHeading">
                Credibility
              </Typography>
              <Typography
                variant="body1"
                className="storageSubHeading"
              >
                Alt-X is committed to maintaining high standards of security and compliance, providing a trustworthy platform for traders and investors. By listing your coin on Alt-X, you enhance your project's credibility and legitimacy in the eyes of the community.
              </Typography>
            </Stack>
            <Stack
              Item
              sx={{
                width: { xs: "85%", md: "18%", sm: "45%" },
                borderRadius: "30px",
                minHeight: { md: "360px", xs: "auto" },
                border: "1.2px solid #ECECEC",
                backgroundColor: darkMode ? "#323232" : "#FFFFFF"
              }}
              spacing={{ xs: 1, sm: 2 }}
              paddingTop={2}
              paddingBottom={2}
              paddingLeft={1}
              paddingRight={1}
              direction="column"
              alignItems="center"
            >
              <Box>
                <img className="eclips3" src={phone} alt="s" />
              </Box>
              <Typography style={{ color: darkMode ? "#e1e1e1" : "#4231c8" }} className="storageHeading">
                Support
              </Typography>
              <Typography
                variant="body1"
                className="storageSubHeading"
              >
                Our dedicated team of professionals is here to support you throughout the listing process, ensuring a smooth and seamless experience from start to finish. We provide guidance on technical requirements, compliance procedures, and marketing strategies to help maximize the success of your listing
              </Typography>
            </Stack>
          </Stack>
        </Container>

        <Stack direction="row" justifyContent="center" marginTop={4}>
          <Button
            component={Link} to="/wallet/withdraw"
            className="btnText16"
            sx={{
              width: 200,
              height: 40,
              borderRadius: 8,
              textTransform: "capitalize",
              bgcolor: "#4231C8",
              "&:hover": { bgcolor: "#4231C8" },
            }}
            variant="contained"
          >
            Apply for Listings
          </Button>
        </Stack>
        <div style={{ backgroundColor: darkMode && "#282828" }} className="reviewSetion">
          <Container className="manageContainer">
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ borderBottom: "1px solid #4231C8", width: "100%", }}
            >
              <Typography
                variant="h5"
                sx={{
                  paddingBottom: 3,
                  textAlign: "center",
                  color: darkMode && "#e1e1e1"
                }}
                className="trustText"
              >
                How to Get Listed
              </Typography>
            </Stack>
            <div className="reviewCardSection">
              <div style={{ backgroundColor: darkMode && "#323232" }} className="reviewCard">
                <Typography
                  style={{ color: darkMode && "#e1e1e1" }}
                  className="storageHeading"
                >
                  Submit Your Application
                </Typography>
                <Typography
                  variant="body1"
                  className="storageSubHeadingTwo"
                >
                  Fill out our listing application form,
                  providing details about your project, tokenomics, team, and any relevant documentation
                </Typography>
              </div>
              <img src={arrows} className="arrowsImg" />
              <div style={{ backgroundColor: darkMode && "#323232" }} className="reviewCard">
                <Typography
                  style={{ color: darkMode && "#e1e1e1" }}
                  className="storageHeading"
                >
                  Review Process
                </Typography>
                <Typography
                  variant="body1"
                  className="storageSubHeadingTwo"
                >
                  Our team will carefully review your application to ensure that your project meets our listing criteria and aligns with our values of security, transparency, and compliance
                </Typography>
              </div>
              <img src={arrows} className="arrowsImg" />
              <div style={{ backgroundColor: darkMode && "#323232" }} className="reviewCard">
                <Typography
                  style={{ color: darkMode && "#e1e1e1" }}
                  className="storageHeading"
                >
                  LISTING AGREEMENT
                </Typography>
                <Typography
                  variant="body1"
                  className="storageSubHeadingTwo"
                >
                  Upon approval, we'll work with you to
                  finalize the listing agreement and determine the listing fee, if applicable
                </Typography>
              </div>
              <img src={arrows} className="arrowsImg" />
              <div style={{ backgroundColor: darkMode && "#323232" }} className="reviewCard">
                <Typography
                  style={{ color: darkMode && "#e1e1e1" }}
                  className="storageHeading"
                >
                  launch
                </Typography>
                <Typography
                  variant="body1"
                  className="storageSubHeadingTwo"
                >
                  Once everything is in place, we'll announce the listing of your coin on Alt-X, giving you access to our platform and our community of traders and investors.
                </Typography>
              </div>
            </div>
          </Container>
        </div>
        {/* <Container className="manageContainer">
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              className="joinText"
              sx={{ fontWeight: 600,  textAlign: "center", mt: 3 }}
            >
              Join <span className="blue">ALT-X</span> for complete
              transperancy.
            </Typography>
          </Stack>
          <Stack className="ulDDDDDDDDiv"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <ul className="listListing">
              <li><span>NO</span> MM fund requirements</li>
              <li><span>NO</span> Hacking</li>
            </ul>
            <ul className="listListing listListing1">
              <li><span>NO</span> Hidden fees </li>
              <li><span>NO</span> Fee accounts provided</li>
            </ul>
          </Stack>
          <Stack
            sx={{
              marginTop : 3, 
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              className="joinText joinText02"
              sx={{ fontWeight: 600, textAlign: "center", mt: 3 }}
            >
              The <span className="blue">ALT-X</span> community has great
              words to say <span className="blue">About us</span>
            </Typography>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              justifyContent: "space-around",
              mb: 5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div class="tooltipCustom bubble bubble--arrow bubble--arrow-s">
                <Typography variant="body1" color="#999999">
                  That’s the main thing people are controlled by! Thoughts-
                  their perception of themselves!
                </Typography>
              </div>
              <img src={avatar2} alt="s" />
              <Typography
                variant="body1"
                className="vivekTitle"
              >
                Vivek Reddy
              </Typography>
              <Typography variant="body1" className="vivekTitledDesc">
                @Vivek Reddy
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div class="tooltipCustom bubble bubble--arrow bubble--arrow-s">
                <Typography variant="body1" color="#999999">
                  That’s the main thing people are controlled by! Thoughts-
                  their perception of themselves!
                </Typography>
              </div>
              <img src={avatar2} alt="s" />
              <Typography
                variant="body1"
                className="vivekTitle"
              >
                Vivek Reddy
              </Typography>
              <Typography variant="body1" className="vivekTitledDesc">
                @Vivek Reddy
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div class="tooltipCustom bubble bubble--arrow bubble--arrow-s">
                <Typography variant="body1" color="#999999">
                  That’s the main thing people are controlled by! Thoughts-
                  their perception of themselves!
                </Typography>
              </div>
              <img src={avatar2} alt="s" />
              <Typography
                variant="body1"
                className="vivekTitle"
              >
               Vivek Reddy
              </Typography>
              <Typography variant="body1" className="vivekTitledDesc">
                @Vivek Reddy
              </Typography>
            </Box>
          </Stack>
        </Container> */}
        <Box className="exposureTextDiv" sx={{ bgcolor: "#4231C8", color: "#ffffff", mt: 4, padding: "75px 10px" }}>
          <Container className="manageContainer">
            <Stack
              sx={{
                display: "flex",
                gap: 2,
                flexDirection: { md: "row", xs: "column" },
                justifyContent: { md: "space-between" },
                alignItems: { md: "center", xs: "start" },

              }}

            >
              <Typography variant="h5" className="exposureText" sx={{ fontWeight: 600, width: "50%" }}>
                Ready to get started?
              </Typography>
              <div className="topDiv">
                {/* <h1>GLOBAL TOP 20 EXCHANGE</h1>
                <div className="groupImgDiv">
                  <img src={Group01} alt="" />
                  <img src={Group02} alt="" />
                  
                </div>

                <h1 className="mt-4">top 20</h1>
                <div className="groupImgDiv">
                  <img src={Group03} alt="" />
                  
                </div> */}
                <p>Take the next step in your project's journey by listing your coin on
                  Alt-X. Join the ranks of leading cryptocurrency projects and unlock new opportunities for growth and success. Submit your application today and let Alt-X be your partner in the crypto market.</p>
              </div>
            </Stack>
          </Container>
        </Box>
        <Stack direction="row" justifyContent="center" marginTop={8} marginBottom={8}>
          <Button
            component={Link} to="/wallet/withdraw"
            className="btnText16"
            sx={{
              width: 200,
              height: 40,
              borderRadius: 8,
              textTransform: "capitalize",
              bgcolor: "#4231C8",
              "&:hover": { bgcolor: "#4231C8" },
            }}
            variant="contained"
          >
            Apply for Listings
          </Button>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default Listing;
