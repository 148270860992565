import React, { useState } from "react";
import { Typography } from "@mui/material";
import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LanguageIcon from '@mui/icons-material/Language'; // Import the language icon of your choice
import group1307 from "../../../assets/lang.svg";
const languages = [
  { code: "en", label: "UTC+5 Standard" },
  { code: "es", label: "UTC+5 Standard" },
  { code: "fr", label: "UTC+5 Standard" },
  { code: "fr", label: "UTC+5 Standard" },
  { code: "fr", label: "UTC+5 Standard" },
  // Add more languages as needed
];
export default function Timezone() {
  const [selectedLanguage, setSelectedLanguage] = useState("Select");
  const [showOptions, setShowOptions] = useState(false)

  return (
    <div>
      <Button
        aria-controls="language-menu"
        aria-haspopup="true"
        variant="outlined"
        onClick={() => setShowOptions(!showOptions)}
        startIcon={<LanguageIcon className="langIcon" />}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          color: "#4231C8",
          width: 130,
          height: 46,
          textTransform: "capitalize",
          border: "none",
          bgcolor: "#FAFAFA",
        }}
        className="drop-btn"
      >
        {selectedLanguage}
      </Button>
      {showOptions && (
        <div style={{
          position: "absolute",
          padding: 12,
          backgroundColor: "white",
          boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)'
        }}>
          {languages.map((language) => (
            <button
              onClick={() => { setSelectedLanguage(language.code); setShowOptions(false) }}
              style={{ display: "flex", gap: 20, justifyContent: "space-between", marginBottom: 10, background: "none", border: "none" }}>
              <div>{language.label}</div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
